$table__space: rem(10);

.table-wrapper {
  margin: rem(24) 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

table {
  width: 100%;
}

thead {
  color: $body__font-color;
  text-align: left;
  font-size: rem(17);
  font-weight: $font__weight--bold;

  th {
    background-color: $color__accent;
  }
}

tbody {
  tr {
    transition: all 0.1s linear;
    background-color: $color__bright;
    color: $body__font-color;
    font-weight: $font__weight--medium;

    &:nth-child(odd) {
      background-color: $color__accent--light;
    }

    &:hover {
      background-color: $color__accent;
      color: $body__font-color;
    }
  }
}

td,
th {
  min-width: rem(100);
  padding: $table__space;
  border-bottom: 1px solid $color__accent;

  &:last-child {
    border-right: 0;
  }
}
