$filter-search__border-color: $color__accent--medium;
$filter-search__border: 2px solid $filter-search__border-color;

.filter-search {
  margin-bottom: rem(20);

  &:hover,
  .lt-ie10 & {
    .filter-search__input,
    .filter-search__button {
      background-color: $color__accent--light;
    }
  }
}

.filter-search__input {
  font-weight: $font__weight--bold;
  font-size: rem(22);
  padding-top: rem(10);
  padding-bottom: rem(5);
  transition: none;
  border-bottom: $filter-search__border;

  &::placeholder {
    color: $body__font-color;
    opacity: 1;
  }

  &:focus,
  &:focus + .filter-search__button {
    border-bottom-color: $color__primary;
    background-color: $color__accent--light;
  }
}

.filter-search__button {
  height: 100%;
  width: rem(70);
  border-bottom: $filter-search__border;
  padding: rem(15) rem(10) rem(10);
  transition: none;

  &:hover {
    border-bottom: $filter-search__border;
    background-color: $color__accent--medium;
  }

  &:active {
    border-bottom-color: $filter-search__border-color;
  }

  .icon {
    width: rem(50);
    height: rem(50);
  }
}

// Flexbox Fallback
.no-flexbox.no-flexboxtweener {
  .filter-search__input,
  .filter-search__button {
    min-height: rem(80);
  }
}
