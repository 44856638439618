.sticky-nav {
    position: fixed;
    bottom: 0;
    width: calc(100% - 20px);
    background: $color__primary;
    z-index: 20;
    left: rem(10);
    right: rem(10);
    -webkit-transform: translate3d(0,0,3000px);
    transition: transform 1s ease, -webkit-transform 1s ease;
}

.sticky-nav__inner {
  @include outer-container();
  padding: 0 grid-padding(small);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .no-flexbox.no-flexboxtweener & {
    text-align: center;
  }
}

.sticky-nav__link {
  display: block;
  padding: rem(10) 0;
  text-align: center;
  font-size: rem(14);
  transition: color 0.15s linear;
  color: $color__bright;
  flex-grow: 1;

  & span{
    display: block;
    font-size: .7rem;
    font-weight: 200;
  }

  .icon {
    display: block;
    margin: 0 auto;
  }

  &:hover {
    color: $color__dark;
  }

  .no-flexbox.no-flexboxtweener & {
    display: inline-block;
  }
}

.sticky-nav--active {
  color: $color__dark;
}
#sticky_bar_closed {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 30px solid $color__primary;
  border-bottom: 30px solid transparent;
  position: absolute;
  right: 64px;
  cursor: pointer;
}
.breadcrumb--alternate .breadcrumb__item a{
  text-transform: uppercase;
}

.breadcrumb__spacer .icon{
  width: 1.475rem;
  height: 1.475rem;
}
// angle**/
.sticky-nav:before,
.sticky-nav:after {
  content: '';
  display: block;
  height: rem(13);
  width: rem(20);
  position: absolute;
  overflow: hidden;
  background: $color__primary;
  backface-visibility: hidden;
  z-index: -1;
  height: rem(63);
}

.sticky-nav:before {
  bottom: 0;
    -webkit-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    transform: skewX(-8deg);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    left: rem(-5);
}

.sticky-nav:after {
    bottom: 0;
    -webkit-transform: skewX(8deg);
    -ms-transform: skewX(8deg);
    transform: skewX(8deg);
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: skewX(8deg);
    -ms-transform: skewX(8deg);
    transform: skewX(8deg);
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    right: rem(-5);

  }


// end angle small


@include mq(medium) {
.sticky-nav__inner {
  @include outer-container();
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .no-flexbox.no-flexboxtweener & {
    text-align: center;
  }
}

  .sticky-nav__link {
    font-size: rem(19);
    padding: grid-padding(small) 0;
  }

}
@include mq(large) {
  .sticky-nav__inner {
  @include outer-container();
  padding: 0;
  display: block;
  justify-content: space-around;
  flex-wrap: wrap;

  .no-flexbox.no-flexboxtweener & {
    text-align: center;
  }
}
  .sticky-nav {
    position: fixed;
    right: 0;
    left: auto;
    bottom: auto;
    top: rem(87);
    width: rem(64);
    background: $color__primary;
    z-index: 20;
    height: auto;
    display: none;
  }
  // angle**/
  .sticky-nav:before,
  .sticky-nav:after {
    content: '';
    display: block;
    height: rem(13);
    width: 100%;
    position: absolute;
    left: 0;
    overflow: hidden;
    background: $color__primary;
    backface-visibility: hidden;
    z-index: 1;
  }

  .sticky-nav:before {
    top: rem(-10);
    transform: skewY(-8deg);
    transform-origin: right bottom;
  }

  .sticky-nav:after {
      bottom: rem(-10);
      transform: skewY(8deg);
      transform-origin: right top;
    }
  // end angle small
  // #sticky_bar_closed{
  //   display: none;
  // }
}
