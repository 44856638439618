.icon {
  display: inline-block;
  width: rem(30);
  height: rem(30);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  fill: currentColor;
}

.icon--m {
  width: rem(40);
  height: rem(40);
}

.icon--l {
  width: rem(60);
  height: rem(60);
}

// inlinesvg support reset
// ----------------------------------------------------------
.inlinesvg .icon {
  background-repeat: repeat;
  background-size: auto auto;
}
