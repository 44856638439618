.video-player {
  @include clearfix;
  background-color: $color__bright;
  overflow: auto;

  &__inner {
    @include outer-container();
    overflow: hidden;
    padding: 0 rem(4);
  }

  &__header {
    text-align: center;
    margin-bottom: rem(20);
    h2{
      color: $color__bright;
    }
  }
}

@include mq(medium) {
  .video-player {
    &__inner {
      padding: 0;
    }
  }
}

@include mq(large) {
  // .video-player {

  // }
}

@include mq(xlarge) {
  // .video-player {

  // }
}
