$btn__size: rem(30);

// Default Button
// ----------------------------------------
.btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 0 0 rem(18);
  padding: rem(8) rem(12) rem(5);
  background-color: $color__secondary;
  border: rem(1) solid $color__secondary;
  text-decoration: none;
  text-align: center;
  font-size: rem(17);
  font-weight: bold;
  line-height: 2.06;
  color: $color__bright;
  user-select: none;
  appearance: none;
  transition: all 0.1s ease-in-out;
  text-transform: uppercase;

  .icon {
    width: $btn__size;
    height: $btn__size;
    vertical-align: middle;
  }
  &.no_upper_thin{
    text-transform: none !important;
    font-weight: 300;
    line-height: inherit;
    text-align: left;
  }
  &:hover {
    color: $color__bright;
    background: $color__accent--medium;
    border-color: $color__accent--medium;
  }

  &:active,
  &.btn--active {
    transition: none;
    color: $color__secondary;
    background: $color__dark;
    border-color: $color__dark;
  }

  &:focus {
    outline: 0;
  }

}

// Secondary Button
// ----------------------------------------
.btn--secondary {
  & {
    background-color: $color__primary;
    border-color: $color__primary;
  }

  &,
  &:hover {
    color: $color__bright;
  }

  &:hover {
    background-color: $color__accent--medium;
  }

  &:active,
  &.btn--active {
    color: $color__secondary;
    // background-color: $color__secondary;
    // border-color: $color__secondary;
  }
}
.btn--active a{
  color: $color__secondary;
}
// Tertiary Button
// ----------------------------------------
.btn--tertiary {
  &,
  &.btn--active {
    color: $color__secondary;
  }

  &,
  &:hover,
  &:active,
  &.btn--active {
    border-color: $color__accent;
    background-color: transparent;
    color: $color__secondary;
  }

  &:hover {
    color: $body__font-color;
  }

  &:active,
  &.btn--active {
    color: $color__secondary;
  }
}

// Link Button
// ----------------------------------------
.btn--link {
  padding: rem(2) 0 rem(1);
  margin-bottom: 0;

  &,
  &.btn--active {
    color: $color__secondary;
  }

  &,
  &:hover,
  &:active,
  &.btn--active {
    border-color: transparent;
    background-color: transparent;
  }

  &:hover {
    color: $color__dark;
  }

  &:active,
  &.btn--active {
    color: $color__secondary;
  }
}

// Only Icon Style
// ----------------------------------------
.btn--icon {
  width: rem(40);
  height: rem(40);
  padding: rem(3) 0;
}

// Circle Style
// ----------------------------------------
.btn--circle {
  width: rem(40);
  height: rem(40);
  border-radius: 100%;
  padding: rem(3) 0;

  .icon {
    width: $btn__size;
    height: $btn__size;
    vertical-align: top;
  }
}

// Small Style
// ----------------------------------------
.btn--small {
  padding-top: rem(3);
  padding-bottom: rem(2);
}

// Expanded Style
// ----------------------------------------
.btn--expanded {
  width: 100%;
}

// Group Container
// ----------------------------------------
.btn__group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: rem(12) 0 rem(18);

  > .btn {
    position: relative;
    float: left;
    margin-right: rem(1);
    margin-bottom: rem(1);

    &:last-child {
      margin-right: 0;
    }
  }
}

@include mq(medium) {

  // Circle Style
  // ----------------------------------------
  .btn--circle {
    width: rem(76);
    height: rem(76);

    .icon {
      width: $btn__size * 2;
      height: $btn__size * 2;
    }
  }
}
