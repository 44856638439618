.one__container {
  @include outer-container;
}

.one__row {
  @include row;
}

header.one_header{
  background-color: #ffffff;
  .header__logo {
    margin-left: 0;
    img{
      width: 100%;
      max-width: 120px;
    }
  }
  .right_side_header{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .input-addon{
      justify-content: center;
      min-width: calc(100% - 42px);
      max-width: calc(100% - 42px);
      .filter-search__button{
        width: 2.75rem;
        padding: 0.4275rem 00 0 0;
        .icon{
          height: 3rem;
        }
      }
      .filter-search__input{
          max-width: 280px;
          font-weight: 300;
      }
    }
    .square__icon{
      min-width: 36px;
      max-width: 36px;
      text-align: center;
    }
  }
}

.nav-container__bar{
  background-color: #282828;
  min-height: 6.75rem;
  margin-top: 1.5rem;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - 36px));
}

.main-nav__one{

}
.gray_bg{
  background-color: #f7f7f7;
  padding-bottom: 80px;
}
.nav-container__bar{
  .header__nav{
    width: 100%;
    float: none;
    padding: 5px 0;
    ul.main-nav__list{
      justify-content: space-around;
      li.main-nav__item{
        background-color: inherit;
        display: inline-block;
        position: relative;
        -webkit-perspective: none;
        perspective: none;
        min-width: 25%;
        max-width: 25%;
        text-align: center;
        a.main-nav__link{
          text-transform: uppercase;
          &:hover{
            transform: rotate(0deg)
          }
        }
      }
      .main-nav__item:before{
        content: '';
        border-left: 1px solid #f7f7f7;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 35px;
        z-index: 5442213213;
      }
      .main-nav__item:last-child::after{
        content: '';
        border-right: 1px solid #f7f7f7;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 35px;
        z-index: 5442213213;
      }
    }
  }
}

.flex_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
  &.column_reverse{
    flex-direction: column-reverse;
  }
  .cell_1_3{
    min-width: 100%;
    max-width: 100%;
    padding: 15px;
    margin: 0;
  }
  .cell_1_2{
    min-width: 100%;
    max-width: 100%;
    padding: 15px;
    margin: 0;
  }
}
.flex_container{
  .cell_1_3{
    .container_box{
      padding: 0 0 15px 0
    }
    .box_title{
      color: #FFFFFF;
      font-family: "ATC Overlook";
      font-size: 26px;
      font-weight: bold;
      line-height: 31px;
      text-transform: uppercase;
      padding: 20px 0 40px 0;
    }
  }
  .purple_box{
    background-color: #9D79B4;
    margin-bottom: 30px;
  }
  .green_box{
    background-color: #7AB340;
    margin-bottom: 30px;
  }
  .blue_box{
    background-color: #0065B0;
    margin-bottom: 30px;
  }
}
.full_width_box{
  width: 100%;
  background-color: #fff;
  border: 1px solid #C8C8C8;
}

@mixin transition($transition...) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
}

@mixin border-radius($radius:0px) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin transform($transform) {
    -ms-transform: $transform;
    -webkit-transform: $transform;
    -moz-transform: $transform;
    transform: $transform;
}

// Core Style
.accordion {
  width: 100%;
  @include border-radius();
  .item {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    &:hover {
      label.menulabel {
        color: #fff;
      }
    }
    label.menulabel {
      width: 100%;
      background-color: rgba(0,0,0,0.2);
      color: #fff;
      font-family: "ATC Overlook";
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      display: block;
      width: 100%;
      cursor: s-resize;
      position: relative;
      padding: 10px 20px;
      overflow: hidden;

      & .icon{
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -11px;
        font-size: 24px;
        color: #fff;
        @include transition(transform 200ms ease-out);
      }
    }
    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 15px;
      height: 15px;
      border: 0px;
      z-index: 999;
      &[hidden="hidden"] {
        visiblity: hidden;
        top: -100%;
        + label{
          &:after{
            display: block
          }
        }
      }
      &:focus{
        outline: none;
      }
      &:checked {
        ~.acoordion-content {
          padding: 10px 20px;
          height: auto;
        }
        + label.menulabel {
          color: #fff;
          & .icon{
           @include transform(rotate(90deg));
          }
        }
      }
    }
    .acoordion-content {
      padding: 0px 20px;
      height: 0px;
      background-color: rgba(0,0,0,0.2);
      color: #fff;
      font-weight: 300;
      overflow: hidden;
      @include transition(padding 200ms ease-in-out);
      ul.regular_list{
        margin: 0;
        padding: 0px 0 0px 20px;
        li{
          padding: 3px 0 3px 0px;
        }
      }
    }
  }
}


h1.regular_box_title{
  color: #282828;
  font-family: "ATC Overlook";
  font-size: 26px;
  font-weight: bold;
  line-height: 31px;
  text-align: center;
  text-transform: uppercase;
  padding: 25px 0 0 0;
}
.box_category_label{
  color: #E40048;
  font-family: "ATC Overlook";
  font-size: 13px;
  font-weight: 300;
  line-height: 27px;
}
.box_title{
  color: #282828;
  font-family: "ATC Overlook";
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding-bottom: 15px;
}
.box_content{
  color: #282828;
  font-family: "ATC Overlook";
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  padding-bottom: 15px;
}
.box_link{
  color: #E40048;
  font-family: "ATC Overlook";
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
}

.box_image_container img{
  width: 100%;
}
.top_spacer_15{
  margin-top: 15px !important;
}
.btn.load_more_comments{
    margin: 20px auto;
    text-align: center;
    width: 240px;
    display: block !important;;
}
.footer{
  top: -5.045rem;
}



.box_to_load {
  display: none;
}
.box_to_load:nth-of-type(1),
.box_to_load:nth-of-type(2) {
  display: flex;
}

.box_to_load:last-of-type {
  padding-bottom: 2px;
}

.account_details{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & a{
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    position: relative;
    padding: 10px 20px;
    color: #FFFFFF;
    font-family: "ATC Overlook";
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    margin: 0px 0 10px 0;
    & .icon{
      position: absolute;
      right: 10px;
    }
  }
}

/*********************************************************************************************************
**********************************************************************************************************
**********************************************************************************************************/

@include mq(medium){

}
@include mq(large){
  header.one_header{
    background-color: #ffffff;
    .header__logo {
      margin-left: 0;
      img{
        width: 100%;
        max-width: 120px;
      }
    }
    .right_side_header{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .input-addon{
        justify-content: center;
        min-width: calc(100% - 42px);
        max-width: calc(100% - 42px);
        .filter-search__button{
          width: 2.75rem;
          padding: 0.4275rem 00 0 0;
          .icon{
            height: 3rem;
          }
        }
        .filter-search__input{
            max-width: 280px;
            font-weight: 300;
        }
      }
      .square__icon{
        min-width: 36px;
        max-width: 36px;
        text-align: center;
      }
    }
  }

  .nav-container__bar{
    background-color: #282828;
    min-height: 6.75rem;
    margin-top: 1.5rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - 36px));
  }

  .main-nav__one{

  }
  .gray_bg{
    background-color: #f7f7f7;
    padding-bottom: 80px;
  }
  .nav-container__bar{
    .header__nav{
      width: 100%;
      float: none;
      padding: 5px 0;
      ul.main-nav__list{
        justify-content: space-around;
        li.main-nav__item{
          background-color: inherit;
          display: inline-block;
          position: relative;
          -webkit-perspective: none;
          perspective: none;
          min-width: 25%;
          max-width: 25%;
          text-align: center;
          a.main-nav__link{
            text-transform: uppercase;
            &:hover{
              transform: rotate(0deg)
            }
          }
        }
        .main-nav__item:before{
          content: '';
          border-left: 1px solid #f7f7f7;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 35px;
          z-index: 5442213213;
        }
        .main-nav__item:last-child::after{
          content: '';
          border-right: 1px solid #f7f7f7;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 35px;
          z-index: 5442213213;
        }
      }
    }
  }

  .flex_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: row !important;
    .cell_1_3{
      min-width: 32%;
      max-width: 32%;
      padding: 15px;
      margin: 0;
    }
    .cell_1_2{
      min-width: 50%;
      max-width: 50%;
      padding: 15px;
      margin: 0;
    }
  }

  .flex_container{
    .cell_1_3{
      .container_box{
        padding: 0 0 15px 0
      }
      .box_title{
        color: #FFFFFF;
        font-family: "ATC Overlook";
        font-size: 26px;
        font-weight: bold;
        line-height: 31px;
        text-transform: uppercase;
        padding: 20px 0 40px 0;
      }
    }
    .purple_box{
      background-color: #9D79B4;
    }
    .green_box{
      background-color: #7AB340;
    }
    .blue_box{
      background-color: #0065B0;
    }
  }
  .full_width_box{
    width: 100%;
    background-color: #fff;
    border: 1px solid #C8C8C8;
  }


  @mixin transition($transition...) {
    transition: $transition;
    -webkit-transition: $transition;
    -moz-transition: $transition;
  }

  @mixin border-radius($radius:0px) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
  }

  @mixin transform($transform) {
      -ms-transform: $transform;
      -webkit-transform: $transform;
      -moz-transform: $transform;
      transform: $transform;
  }

  // Core Style
  .accordion {
    width: 100%;
    @include border-radius();
    .item {
      width: 100%;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      &:hover {
        label.menulabel {
          color: #fff;
        }
      }
      label.menulabel {
        width: 100%;
        background-color: rgba(0,0,0,0.2);
        color: #fff;
        font-family: "ATC Overlook";
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        display: block;
        width: 100%;
        cursor: s-resize;
        position: relative;
        padding: 10px 20px;
        overflow: hidden;

        & .icon{
          position: absolute;
          top: 50%;
          right: 10px;
          margin-top: -11px;
          font-size: 24px;
          color: #fff;
          @include transition(transform 200ms ease-out);
        }
      }
      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 15px;
        height: 15px;
        border: 0px;
        z-index: 999;
        &[hidden="hidden"] {
          visiblity: hidden;
          top: -100%;
          + label{
            &:after{
              display: block
            }
          }
        }
        &:focus{
          outline: none;
        }
        &:checked {
          ~.acoordion-content {
            padding: 10px 20px;
            height: auto;
          }
          + label.menulabel {
            color: #fff;
            & .icon{
             @include transform(rotate(90deg));
            }
          }
        }
      }
      .acoordion-content {
        padding: 0px 20px;
        height: 0px;
        background-color: rgba(0,0,0,0.2);
        color: #fff;
        font-weight: 300;
        overflow: hidden;
        @include transition(padding 200ms ease-in-out);
        ul.regular_list{
          margin: 0;
          padding: 0px 0 0px 20px;
          li{
            padding: 3px 0 3px 0px;
          }
        }
      }
    }
  }


  h1.regular_box_title{
    color: #282828;
    font-family: "ATC Overlook";
    font-size: 26px;
    font-weight: bold;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;
    padding: 25px 0 0 0;
  }
  .box_category_label{
    color: #E40048;
    font-family: "ATC Overlook";
    font-size: 13px;
    font-weight: 300;
    line-height: 27px;
  }
  .box_title{
    color: #282828;
    font-family: "ATC Overlook";
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    padding-bottom: 15px;
  }
  .box_content{
    color: #282828;
    font-family: "ATC Overlook";
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    padding-bottom: 15px;
  }
  .box_link{
    color: #E40048;
    font-family: "ATC Overlook";
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
  }

  .box_image_container img{
    width: 100%;
  }
  .top_spacer_15{
    margin-top: 15px !important;
  }
  .btn.load_more_comments{
      margin: 20px auto;
      text-align: center;
      width: 240px;
      display: block !important;;
  }
  .footer{
    top: -5.045rem;
  }



  .box_to_load {
    display: none;
  }
  .box_to_load:nth-of-type(1),
  .box_to_load:nth-of-type(2) {
    display: flex;
  }

  .box_to_load:last-of-type {
    padding-bottom: 2px;
  }

  .account_details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & a{
      background-color: rgba(0, 0, 0, 0.2);
      display: block;
      width: 100%;
      position: relative;
      padding: 10px 20px;
      color: #FFFFFF;
      font-family: "ATC Overlook";
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      margin: 0px 0 10px 0;
      & .icon{
        position: absolute;
        right: 10px;
      }
    }
  }
}
@include mq(inter){

}
@include mq(xlarge){

}
