.footer__inner {
  @include clearfix;
  @include outer-container;
  position: relative;
}

.footer__dark {
  background-color: $color__primary;
  color: $color__bright;
  padding-top: rem(25);

  .footer__inner {
    padding: rem(50) grid-padding(small);
  }
}

.footer__bar {
  .footer__inner {
    padding: rem(60) grid-padding(small) rem(10);
  }
}

.footer__scroll-top {
  width: rem(60);
  height: rem(60);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: rem(-30); // half of button circle size

  .icon {
    display: block;
    margin: 0 auto;
    width: rem(20);
    height: rem(20);
  }
}

.footer__nav {
  list-style-type: none;
  color: $color__accent--dark;
  font-size: $small__font-size;
  // text-align: center;
  padding: 0;
  margin-top: 0;

  li {
    display: inline-block;
    padding: 0 rem(5);
    margin-bottom: rem(10);
  }

  a:not(:hover):not(:active) {
    color: $color__accent--dark;
  }

}
@include mq(medium){
    .footer__nav {
  		width: 100%;
  		float: none;
  		padding-right: 0;
  		// text-align: center;
  	}
}

@include mq(large) {
  .footer__dark {
    .footer__inner {
      padding: rem(100) grid-padding(large);
    }
  }

  .footer__scroll-top {
    width: rem(100);
    height: rem(100);
    top: rem(-50); // half of button circle size

    .icon {
      width: rem(50);
      height: rem(50);
    }
  }

  .footer__bar {
    .footer__inner {
      padding: rem(30) grid-padding(large);
    }
  }

  .footer__nav {
    // width: 50%;
    // float: left;
    // padding-right: rem(50);
    // text-align: center;

    &--right {
      text-align: center !important;
      float: none;
    }

    li {
      width: auto;
      margin-bottom: rem(10);
    }
  }

}
@include mq(xlarge){
  .footer__nav {
     width: 50%;
    float: left;
    padding-right: rem(50);
    text-align: left;

    &--right {
      text-align: right!important;
      padding-right: 0;
      padding-left: rem(50);
    }
  }
}
