.js-offcanvas{
    z-index: 0;
}

.header__logo{
    margin-left: 10px;;
}

.header__logo a{
    padding-top: rem(12);
}

.header__logo img{
    max-width: 8rem;
}

.header a{
    outline-color: transparent;
}

.caption{
    position: relative;
    z-index: 2;;
    top: auto;
    width: 100%;
    margin: auto;
    -webkit-transform: translate(0, 0);
      -ms-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
    text-align: left;
    // background: $color__dark;
    & .portfolio-intro__content{
        padding: 0 0.625rem;
    }
}

.clicktrough{
    pointer-events: none;
}

.caption h1{
    position: relative !important;
    // margin-top: 1rem !important;
    color: $color__dark;
}

.hero_btn .icon{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    margin: .37rem auto;
    padding: 0;

    text-align: center;
}
.smallHero{
    padding-top: 10%;
    & img{
        height: auto;
    }
}
.hero .caption a,
.hero .caption a:hover,
.hero .caption a:focus{
    color: $color__bright;
}

// Define colors for select arrow and convert it to a URL encoded value.
$form__select-arrow-color2: str-replace(inspect($color__bright), '#', '%23');
$form__select-arrow-color2--focus: str-replace(inspect($color__accent), '#', '%23');
.meta_select-languages{
    width: 4.5rem;
}
.meta-nav__link{
    padding: rem(10) rem(3);
}
select.meta-select__select{
    font-size: 0.8rem;
    line-height: 1.5;
    width: 100%;
    height: rem(44);
    margin: 0;
    text-transform: uppercase;
    color: $color__bright;
    border: 0;
    border-bottom-width: rem(2);
    border-radius: 0;
    background-color: transparent;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2047%2064%22%3E%3Cpath%20fill%3D%22#{$form__select-arrow-color2}%22%20d%3D%22M23.5%2037.977l-16.318-16.318%202.568-2.591%2013.75%2013.75%2013.75-13.75%202.568%202.591z%22%2F%3E%3C%2Fsvg%3E%0A);
    // background-image: none;
    background-repeat: no-repeat;

    padding: 0 3px 0px 27px;
    background-position-x: 0;
    appearance: none;
    font-weight: 400;
    option{
        font-size: 16px !important;
        text-transform: uppercase;
        color: $color__primary;
        background-color: $color__bright;
        line-height: 1.4;
        padding: 0 rem(7);
    }
    .lt-ie10 &{
        padding-right: rem(12);
            background-image: none;
    }
    &::-ms-expand{
        display: none;
    }
    &:focus::-ms-value{
        color: $color__accent;
    background-color: transparent;
    }
    &:hover{
        cursor: pointer;
            color: $color__accent;
    }
    &:focus{
        color: $color__accent;
    outline: 0;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2047%2064%22%3E%3Cpath%20fill%3D%22#{$form__select-arrow-color2--focus}%22%20d%3D%22M23.5%2037.977l-16.318-16.318%202.568-2.591%2013.75%2013.75%2013.75-13.75%202.568%202.591z%22%2F%3E%3C%2Fsvg%3E%0A);
    }
}
.footer_lang select.meta-select__select{
    color: $color__accent--dark;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2047%2064%22%3E%3Cpath%20fill%3D%22%23aaaaaa%22%20d%3D%22M23.5%2037.977l-16.318-16.318%202.568-2.591%2013.75%2013.75%2013.75-13.75%202.568%202.591z%22%2F%3E%3C%2Fsvg%3E%0A);
}
input{
    -webkit-appearance: none !important;
}
.brand-overview__brand-image{
    width: 72%;
    height: 45%;
}
.brand-overview__brands,
section.teaser-group,
footer.footer,
.footer__inner{
    transform: none;
}
.teaser-group{
    padding-top: rem(60);
    &.space-remover{
        padding-top: .1rem;
    }
}
.teaser__img{
    outline: 1px solid transparent;
}
nav.sub-nav.white_bg{
    background: $color__bright;
}
.footer__bar{
    position: relative;
    z-index: 13;
    background-color: $color__dark;
}
.sub-nav{
    text-align: center;
}
.sub-nav__inner{
    display: inline-block;
}
.sub-nav__link{
    font-size: .9rem;
    display: inline-block;
    margin: 0 .625rem;
    padding: 1rem .65rem .8rem .65rem;
    border-bottom-color: $color__primary;
}
.sub-nav--active{
    color: $color__secondary;
    border-bottom-color: $color__secondary!important;
}
.sub-nav--active:hover{
    border-bottom-color: $color__accent--dark!important;
}
.offcanvas{
    background-color: $color__bright !important;
}
.angle__container{
    position: relative;
}
// angle small**/
.angle-small{
    position: relative;
    z-index: 23;
    overflow: hidden;
    background: $color__dark;
}

//  angle contact
.angle__contact__container{
    position: relative;
    min-height: 10rem;
    margin-bottom: rem(80);
    background-color: $color__bright;
}
.angle-contact{
    position: relative;
    left: 5%;
    width: 95%;
    padding: 2rem 1rem;
    background: $color__bright;
}
.login_form_fixed_position{
    position: fixed;
    z-index: 1000;
    top: 15%;
    left: 5%;
    width: 100%;
    opacity: 1;
}
.form_fixed_position{
    position: fixed;
    z-index: 1000;
    top: 3.5%;
    left: 5%;
    width: 100%;
    opacity: 1;
}

.angle-contact{
    & form{
        width: 85%;
    margin: 0 auto;
    & .btn
        {
            margin-top: 1rem;
    }
    }
}
#close_contact{
    .btn--circle{
        padding: .3875rem 0;
    }
}
// angle search**/
.search_bg{
    padding: 0 0;
    // background: transparent;
}
.angle__search__container{
    position: relative;
    // min-height: 10rem;
    // background-color: $color__dark;
}
.angle-search{
    position: relative;
    left: 0%;
    width: 100%;
    // padding: 4rem 1rem;
    // background: $color__accent;
}

.angle-search.resultats{
    padding: 0.6325rem;
    & .portfolio-intro{
        padding: 0;
    }
}
.portfolio-intro__data.custom_search_info{
    margin-top: 0;
    margin-bottom: 0;
}
h3.custom_search,
h3.custom_search a{
    color: $color__primary;
}
.newOverlay,
#dark_overlay{
    position: fixed;
    z-index: 249;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .85;
    background: rgb(0, 0, 0);
}
.custom_search_info h4{
    margin-top: 2.7rem;
    margin-bottom: rem(25);
    padding-bottom: rem(15);
    border-bottom: 2px solid $color__accent--dark;
}
.secondary_color{
    color: $color__secondary;
}

#close_search{
    z-index: 120;
    right: auto;
    left: rem(-20);
    .btn--circle{
        padding: .3875rem 0;
    }
}
#close_contact{
    position: absolute;
    z-index: 120;
    top: 0;
    left: rem(-20);
}

.search_custom{
    font-size: 1.6rem;
    border-top: 0;
    border-left: 0;
    border-bottom: 1px solid $color__accent--deep;
    &:focus{
        background-color: $color__bright;
    }
}
form {
    position: relative;
    margin: 0 -0.625rem;
}
form .search_custom_icon svg {
    font-size: 32px;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 10px;
    right: 0px;
}

.portfolio-intro__data-big{
    text-align: left;
    color: $color__dark;
}
.search_result_custom{
    font-size: 2rem;
    font-weight: 700;
    color: $color__secondary;
    border: 0;
    border-left-width: rem(2);
    border-left-style: solid;
    &::placeholder{
        color: $color__secondary;
        text-transform: uppercase;
    }
}
.align-right{
    text-align: right;
}
.visibleonprint{
    display: none;
}
.custom_search_header{
    width: 100%;
    margin-left: 0;
    & .icon--l{
        width: 3.75rem !important;
    height: 3.75rem !important;
    }
    & .btn--icon{
        width: auto;
    height: auto;
    }
}
.portfolio-intro__first .custom_search_info .portfolio-intro__data-item{
    width: 100%;
}
.results_header{
    display: inline-block;
    width: calc(100% - 4.35rem);
    padding-right: 0;
    vertical-align: middle;
}
.portfolio-intro__data{
    &.custom_search_info{
        border: 0;
    }
    & .portfolio-intro__data-item{
        border-bottom: rem(2) solid $anchor--color-hover;
    }
}
.resultats .portfolio-intro__content{
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}
// angle simple
.angle-simple{
    position: relative;
    overflow: hidden;
    background: $color__dark;
}
// end angle simple

.hero video.intrinsic__item{
    object-fit: cover;
    object-position: center;
    max-height:67vh;
}
.intrinsic--19x7:has(video){
    height: 100%;
    padding-top: 50%;
    max-height:67vh;
}
.hero .intrinsic__item{
    left: 0%;;
    width: 100%;
}
.hero--full .intrinsic__item{
    left: 0;
    width: 100%;
}
.vectors_container{
    margin-top: 4rem;
}
.search-portfolio{
    border-bottom: rem(1) solid $color__accent--medium;
    background: $color__accent--light;
    &__container{
        width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
    }
    &__custom{
        & .input-addon
        {
            display: -webkit-box;
        display: -webkit-block;
        display: -ms-flexbox;
        display:         block;
    }
        & input[type=search]
        {
            display: block;
                    width: 100%;
        height: rem(51) !important;
        margin-right: rem(10);
        padding: 0 1rem !important;
                    background-color: $color__bright !important;
    }
        & a
        {
            display: block;
                    width: 17rem;
        margin: .8rem auto;
    }
    }
}
.main-nav__link{
    font-size: 1rem;
    padding: rem(20) .535rem;
    white-space: nowrap;
    text-transform: uppercase;
    outline: rem(1) solid transparent;
    &:hover{
        outline: rem(1) solid transparent;
    }
    &.selected{
        outline: rem(1) solid transparent;
    }
}
.homepage .sub-nav__link{
    border-bottom-color: transparent;
}
.homepage .sub-nav__link:hover{
    border-bottom-color: $anchor--color-hover;
}
.brands{
    margin-top: 1.5rem;
}

.brand-overview__item-wrapper{
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;;
    & li.brand-overview__item:last-child{
        margin-right: 0;
    }
    // &:after{
    //     content: "";
    //     flex: auto;
    // }
}
.brand-overview__header{
    background-color: $color__dark;
    & h2{
        width: 80%;
    margin: 0 auto;
    padding-top: rem(25);
    padding-bottom: rem(30);
            text-align: center;
            color: $color__bright;
    border-bottom: rem(1) solid $color__bright;
    }
}
//****************
.blue-box{
    position: relative;
    z-index: 2;
    left: 5%;
    width: 95%;
    margin: 4rem 0 6rem 0;
    padding: 0rem 4rem;
    background-color: $color__primary;
    &:before,
    &:after{
        position: absolute;
    z-index: 101;
    left: 0;
            display: block;
    overflow: hidden;
            width: 100%;
    height: rem(40);
            content: '';
            background: $color__primary;
            backface-visibility: hidden;
    }
    &:before{
        top: rem(-34);
            transform: skewY(-1deg);
    transform-origin: right bottom;
    }
    &:after{
        bottom: rem(-34);
            transform: skewY(1deg);
    transform-origin: right top;
    }
    &__container{
        margin: 0 auto;
            text-align: center;
    .icon--l
        {
            width: 17rem;
        height: 17rem;
                    color: $color__bright;
    }
    }
    & select{
        font-size: 1.7rem;
    line-height: 1;
            height: auto;
    padding-top: rem(6);
    padding-bottom: 0;
            color: #afe0ed;
    border: 0 solid #afe0ed;
    border-bottom-width: .125rem;
    }
    & select:focus{
        color: $color__bright;
    background-image: inherit;
    }
    & .blue_text{
        font-size: 2rem;
    font-weight: bold;
            display: inline-block;
            vertical-align: top;
            color: #afe0ed;
    }
    & .blue_select_container{
        display: inline-block;
            padding-right: rem(5);
    padding-left: rem(5);
            vertical-align: top;
    }
    & h2{
        display: inline-block;
    }
    & .filter-wrapper__container{
        display: inline-block;
    }
}
.flyout__shift{
    -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
}
.flyout{
    z-index: 12;
    background-color: transparent;
}
.breadcrumb{
    margin-top: 1rem;
}
.wordbox_content{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        margin: 0 !important;
    }
    h2{
        font-size: 1.8rem !important;
    }
    h3{
        font-size: 1.6rem !important;
    }
    h4{
        font-size: 1.4rem !important;
    }
}
//*blue box ends here*/
.multiple_buttons{
    display: inline-block;
    margin-bottom: rem(10);
    margin-right: rem(7);
    & .btn .icon{
        float: left;
        width: 1.825rem;
        height: 1.825rem;
        display: none;
    }
    & .btn{
        line-height: 1.06;
        width: auto;
        text-align: left;
        background-color: $color__secondary;
        color: $color__bright;
        &:hover{
            background-color: $color__accent--medium;
        }
    }
    & .icon--file{
        position: absolute;
        left: 0;
    }
    & .icon--link{
        position: absolute;
        left: 0;
    }
    & .icon--download{
        position: relative;
        margin-top: .93rem;
    }
    &.publispot_button .icon--download{
        margin-top: 0;
    }
    & span{
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4;
        float: left;
        padding: 0 rem(15);
        text-transform: capitalize;
    }
    & a:after{
        clear: both;
        content: '';
    }
    & .underline {
        padding: 0.468rem 0;
        margin-top: 0;
        & span{
            font-weight: 300;
            // text-decoration: underline;
            text-transform: none;
        }
    }
}
//////////////////////////////////-
.portfolio-intro__content.onHero{
    float: none;
    width: 100%;
    margin: 0 auto;
    color: $color__dark;
    h1{
        padding-left: 0.6325rem;
    }
    p{
        padding-left: 0.6325rem;
        padding-right: 0.6325rem;
    }
}
.portfolio-intro .caption{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: table;
    width: 100%;
    height: auto;
    margin: auto;
    transform: translate(0, 0);
    text-align: left;
}
// .onHero h1,
// .onHero h2{
//     margin-top: 0 !important;
// }
#play_vid,
#stop_vid{
    position: absolute;
    z-index: 10;
    top: 50%;
    bottom: 50%;
    left: 3.1%;
    transform: translate(0%, -50%);
}
#play_audio,
#stop_audio{
    position: absolute;
    z-index: 80;
    top: 50%;
    bottom: 50%;
    left: 3.1%;
    transform: translate(0%, -50%);
}
.filter-wrapper__col{
    position: relative;
}
.calendar__button{
    position: absolute;
    top: rem(0);
    right: rem(0);
    color: $color__primary;
}
.teaser__tags{
    padding: 0 rem(15) rem(15) rem(15);
    background-color: $color__bright;
    margin: 0 0 40px 0;
}
.teaser p{
    text-align: left !important;
}
.calendar__input{
    font-size: 1.125rem;
    line-height: 1.5;
    width: 100%;
    height: 2.75rem;
    padding: .3625rem 1.875rem .5625rem rem(10);
    color: #282828;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
        appearance: none;
        font-weight: 400;
    &::placeholder{
        font-size: 1.125rem;
        text-transform: uppercase;
        font-weight: 400;
        opacity: 1;
        color: $color__primary;
    }
}
// tabs
.tab__nav{
    max-width: 100%;
    margin: 0;
    padding: 0;
}
.tab__nav-item{
    display: inline-block;
    float: none;
}
.tab__nav-item a{
    margin-bottom: .3125rem;
}
.tab__nav-item a:focus{
    outline: none;
}
.tab__nav-item--active a{
    border-bottom: 0;
}
.tab.js-tab{
    clear: both;
    width: 100%;
}
// .tab__panels{
//     margin-bottom: rem(50);
// }
.tab__panel{
    padding: 1.5rem 0rem;
}
.sc-content.tabs_description{
    width: 97%;
    margin-right: 1.5%;
    margin-left: 1.5%;
}
.answers_text_area [type='radio'] + label,
.answers_text_area [type='checkbox'] + label{
    padding: .5625rem .5625rem .5625rem 2.625rem !important;
}
// product header
.show_description{
    margin-top: rem(20);
    &__actions{
        margin-left: 1.5%;
    }
    &__image{
        width: 100%;
    margin-right: 1.5%;
    margin-bottom: rem(15);
    margin-left: 1.5%;
    }
    &__content{
        width:100%;
    margin-bottom: 1rem;
    padding-right: 2%;
    }
}
.show_description__content_mediaguide{
    width: 99%;
    margin-left: 0;
    padding-right: 2%;
}
h1.show_description__title{
    font-size: 1.575rem;
    margin-top: 0;
    color: $color__dark;
}
.underline{
    margin-top: rem(15);
}
// breadcrumb
.breadcrumb{
    margin: rem(6) 0 !important;
}
.breadcrumb--alternate .breadcrumb__item a,
.breadcrumb--alternate .breadcrumb__spacer{
    color: $color__primary;
}
.button__container{
    margin-right: 2%;
    & .btn{
        line-height: 1.36;
            margin-bottom: 1.125rem;
    }
    & .btn .icon{
        font-size: .8rem;
            width: 1.625rem;
    height: 1.625rem;
    }
    & span{
        font-size: .8rem;
    }
}
.custom_showDescription__btn{
    line-height: .9;
    width: 2rem;
    height: auto;
    padding-top: .1875rem;
    border-left-width: 0;
}
.clear{
    clear: both;
}
.btn--circle{
    padding: .5875rem 0;
}
.custom_checkbox_upload{
    position: absolute;
    top: rem(-3);
    left: 0;
}
.intrinsic__item_auto{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: auto;
    margin: 0 auto;
}
.gomedia_title{
    font-size: .75rem;
    display: block;
    width: 100%;
    // vertical-align: top;
    word-break: break-word;
}
.gomedia_detail{
    font-size: .75rem;
    display: block;
    width: 100%;
    margin-right: 4%;
    padding-left: rem(7);
    // vertical-align: top;
    p{
        font-size: .75rem;
            padding-top: 0;
    }
}
.custom_btn_circle{
    padding: .3875rem 0;
}
.intrinsic--publishing{
    padding-top: 134.69%;
}
.video-player{
    padding-top: 0;
    padding-bottom: 1.3rem;
    background-color: transparent !important;
    clear: both;
}
.twocols{
    .twothirds, .onethird{
        float: none;
    width: 100%;
    h1{
            font-size: 1.8rem;
        margin-top: 10px;
    }
    }
}
.detailpage__nav{
    top: 6.25rem;
}
.container_for_detail_nav{
    position: relative;
    background: $color__dark;
}
.detailpage__nav{
    top: 35%;
    transform: skew(0);
}
.secondary_nav{
    margin-left: 5%;
}
//Oana
.table-wrapper.digital table{
    width: 99.9%;
}
.digital td,
.digital th{
    min-width: .1rem;
}
.digital th{
    min-width: .1rem;
    color: $color__bright;
    background: $color__primary;
}
.digital td{
    border: 1px solid $color__accent;
}
.verticalTd{
    width: 4rem;
    text-align: center;
}
.digital tbody tr:nth-child(odd){
    background-color: $color__bright;
}
.digital tbody tr:hover{
    color: $color__dark;
    background-color: $color__bright;
}
.digital tbody tr.empty_row:nth-child(odd),
.digital tbody tr.empty_row:hover,
.empty_row{
    background-color: transparent;
}
.empty_row td{
    border: 0;
}
.empty_row{
    border-top: 1px solid #e6e6e6;
    background-color: #f0f0f0!important;
}
.center{
    text-align: center;
}
.mediaguide .center.gray,
.mediaguide .verticalTd{
    background: #d9f0f2;
}
.verticalText{
    line-height: 1.5;
    display: inline-block;
    overflow: hidden;
    width: 1.5em;
}
.adquality{
    border-bottom: 1px solid #ffffff;
}
.adquality_container{
    height: 50px;
    vertical-align: middle;
    display: flex;
}
.mediaguide .adquality td{
    font-weight: 400;
}
.adquality_container img{
    min-width: 80px;
    flex: 1;
}
.verticalText *{
    display: inline-block;
    transform: rotate(-90deg);
    transform: translate(0, 100%) rotate(-90deg);
    transform-origin: 0 0;
    white-space: nowrap;
}
.verticalText *:before{
    float: left;
    margin-top: 100%;
    content: '';
}
.specs small{
    color: $color__primary;
}
.specs .col1{
    float: left;
    width: 4rem;
}
.specs .col2{
    float: left;
    width: calc(100% - 4rem);
}
//cata filter search placeholder
&::placeholder{
    transition: opacity .15s linear;
    opacity: 1;
    color: $color__primary;
    text-transform: uppercase;
}
&:focus::placeholder{
    opacity: .1;
    color: $color__primary;
}
.brand-overview--size-size3,
.brand-overview--size-size2{
    & .brand-overview__brand-link{
        padding: rem(10);
    }
}
.brand-overview__brand-link{
    padding: 0 rem(5);
}
//cata add mediaguide class for word cloud
.brand-overview__brand-link.mediaguide{
    font-weight: 400;
    text-align: center;
}
li:nth-child(12n+4) .mediaguide{
    background: #cdcdcd;
}
li:nth-child(12n+5) .mediaguide{
    background: #efefef;
}
li:nth-child(12n+6) .mediaguide{
    background: #f4f4f4;
}
li:nth-child(12n+7) .mediaguide{
    background: #454545;
}
li:nth-child(12n+8) .mediaguide{
    background: #848484;
}
li:nth-child(12n+9) .mediaguide{
    background: #5a5a5a;
}
li:nth-child(12n+10) .mediaguide{
    background: #727272;
}
li:nth-child(12n+11) .mediaguide{
    background: #727272;
}
li:nth-child(12n+12) .mediaguide{
    background: #f4f4f4;
}
li:nth-child(12n+13) .mediaguide{
    background: #474747;
}
li:nth-child(12n+14) .mediaguide{
    background: #141414;
}
li:nth-child(12n+15) .mediaguide{
    background: #1f1f1f;
}
// Append here
//media Guide tabs styles
.footer__bar .footer__inner{
    padding: 3.75rem .625rem 5rem;
}
.footer__nav.footer__nav--right{
    font-size: 0;
    padding-left: 0;
    & li{
        font-size: .875rem;
            margin-bottom: 0;
    padding: 0;
    }
    & .meta-nav__link{
        padding: 0;
    }
    & .meta-nav__link span{
        display: inline-block;
    }
    & .meta-nav__link .icon{
        vertical-align: middle;
    }
    .footer_lang select.meta-select__select{
        height: 1.75rem;
    }
}
.mediaguide .fl{
    float: left;
}
.mediaguide .fr{
    float: right;
}
.mediaguide .cr{
    clear: right;
}
.mediaguide .cl{
    clear: left;
}
.mediaguide .cb{
    clear: both;
}
.mediaguide table thead tr td.reverse,
.mediaguide table tbody tr td.reverse{
    vertical-align: top;
    color: $color__bright;
    background: $color__accent--dark;
}
.mediaguide table.top td{
    vertical-align: top;
}
.mediaguide td,
.mediaguide th{
    font-weight: 300;
    min-width: .001rem;
    padding: rem(3) 0;
}
.mediaguide th{
    font-weight: 700;
    padding: rem(3) rem(4);
}
.mediaguide h3{
    font-size: 1.2rem;
    padding: .23rem .4375rem;
    color: $color__bright;
    background: $color__primary;
}
.mediaguide td p,
.mediaguide th p{
    margin: 0;
}
.mediaguide .indent{
    padding-left: 2rem;
}
.mediaguide h5{
    font-size: 1.05rem;
}
.mediaguide p,
.mediaguide ul li span{
    font-size: 1rem;
}
.mediaguide ul{
    margin-left: rem(13) !important;
}
.mediaguide .nutzung_container{
    width: 75%;
    max-width: 800px;
}
.mediaguide .flex_container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.mediaguide svg text{
    font-family: 'ATC Overlook', dinnel_l;
}
.mediaguide .flex_container .flex_item{
    width: 50%;
    min-width: 1%;
    max-width: 100%;
    margin: 0 auto;;
    padding: 10px 5px;
    flex: 1;
    flex-basis: auto;
}
.mediaguide .flex_container .flex_item img{
    text-align: center;
}
.mediaguide .red,
.mce-content-body .red{
    font-weight: 700;
    color: $color__dark;
}
.mce-content-body .black,
.mediaguide .black{
    font-weight: 300;
    color: #000;
}
.mediaguide .borderbot{
    border-bottom: 1px solid $color__tertiary;
}
.mediaguide .bordertop{
    border-top: 1px solid $color__tertiary;
}
.mediaguide .brw{
    border: 0;
}
.mediaguide td,
.mediaguide th{
    border-bottom: 0;
}

#dashboardContainer{
    width: 100% !important;
}
.gray1{
    color: $color__bright;
    background: #a6a7a7;
}

.gray2{
    color: $color__bright;
    background: #868686;
}

.gray3{
    color: $color__bright;
    background: #484847;
}

.mediaguide .line{
    height: 2px;

    border-bottom: 1px solid #575756;
    background: $color__bright;
}

.mediaguide .tdspace{
    width: .0001rem;
    padding: rem(3) 0;

    background: $color__bright;
}

.mediaguide table tr.redbg{
    background: $color__secondary;
}
.mediaguide table thead tr th{
    background: transparent;
}
.mediaguide table thead tr th small{
    color: $color__accent--light;
}
.mediaguide table thead tr{
    min-width: 2rem;

    color: $color__bright;
    background: $color__primary;
}
.mediaguide table tbody tr:nth-child(odd){
    background: rgba(0, 155, 167, .15);
}
.mediaguide table tbody tr td{
    padding: rem(5) rem(7);
}
.mediaguide table tbody tr:nth-child(even),
.mediaguide table thead tr td.odd{
    background: rgba(0, 155, 167, .08);;
}

.mediaguide table tbody tr td.graph_table table{
    background: transparent;
}
.mediaguide table tbody tr td.graph_table table tbody tr:nth-child(even),
.mediaguide table tbody tr td.graph_table table tbody tr:nth-child(odd){
    background: transparent;
}
.mediaguide table thead tr td{
    background: $color__bright;
}


.mediaguide table tbody tr td.whitebg{
    border: 0;
    background: $color__bright;
}

.mediaguide table{
    overflow-x: auto;

    width: 99.9%;

    border-collapse: collapse;

    background: $color__bright;
}
.mediaguide table ul li{
    font-size: 1rem;
}
.mediaguide table.nomarginr{
    background: transparent;
}
.mediaguide table thead tr td{
    font-weight: 700;

    padding: rem(5) rem(7);
}
.mediaguide table tfoot tr td{
    padding: rem(5) rem(7);

    color: $color__bright;
    background: $color__secondary;
}
.mediaguide table thead tr td.reverse,
.mediaguide table tbody tr td.reverse{
    color: $color__bright;
    background: $color__secondary;
}

.mediaguide table tr td.double{
    line-height: 200%;
}

.mediaguide span.note{
    color: $color__secondary;
}

.brw{
    border-right: 1px solid $color__bright;
}

.mediaguide .subnote{
    font-size: 84%;
    font-weight: 300;
    line-height: 110%;

    margin-top: rem(4);
    margin-bottom: rem(10);

    color: #77787b;
}
.mediaguide .subnote p{
    font-size: 100%;
    font-weight: 300;
    line-height: 110%;

    color: #77787b;
}
.mediaguide .footnote{
    font-weight: 300;

    float: left;
    clear: both;

    width: rem(30);
    padding-right: rem(5);

    text-align: right;
}

.mediaguide.brg{
    border-right: 1px solid #979797;
}

.mediaguide.btg{
    border-top: 1px solid #979797;
}

.mediaguide.bbg{
    border-bottom: 1px solid #979797;
}

.mediaguide .w50{
    width: 33%;
}

.mediaguide .w110{
    width: 33%;
}

.mediaguide .w140{
    width: 33%;
}

.mediaguide .table-wrapper{
    overflow-y: hidden;
}
.large_table > table{
    min-width: 700px;
}
.large_table table tr td{
    min-width: 60px;
}
.table-wrapper table.floating_tables,
.table-wrapper table.advert_space_table,
.table-wrapper table.advert_section_table,
.table-wrapper table.custom_table{
    min-width: auto;
}
.mediaguide .bulletlist{
    float: left;
    clear: both;

    width: rem(15);
    margin-top: rem(2);
    padding: 0 rem(3) 0 0;
}

.mediaguide .list{
    float: left;

    width: 95%;
    margin: rem(6) 0 0 0;

    text-align: justify;
}

.media_guide_webcategory{
    margin-top: 0;
    padding: 0;

    list-style: none;
}

.special_head_table{
    /* min-width: 2rem; */
    font-weight: 700 !important;

    text-align: right;

    color: $color__bright;
    background: $color__primary;
}

.filter-wrapper__col{
    width: 98%;
}

.pagination__container{
    display: block;
    min-width: 1.8rem;
    text-align: center;
    & .previous_item,
    & .next_item,
    & .pagination_numbers{
        font-size: 0;
        display: inline-block;
        width: 100%;
        margin: 1rem auto;
        vertical-align: top;
    }
    & .previous_item,
    & .next_item{
        & .btn--icon{
            width: 1.5rem;
            height: 1.5rem;
        }
        & .btn--small{
            line-height: 1.5;
            padding: 0;
            & .icon{
                width: 1.5rem;
                height: 1.5rem;
                text-align: center;
            }
        }
    }
    & .pagination_numbers{
        padding: 0;
        list-style: none;
        & li.pag_number{
            display: inline-block;
            margin: 0 .3rem;
            & a{
                padding: 0 12px;
                display: block;
            }
            & .threedots{
                font-size: 1.1rem;
                font-weight: 400;
                line-height: 2.75rem;
                height: .1rem;
                color: $color__primary;
            }
            & .btn--icon{
                width: auto;
                height: 2.75rem;
            }
            & .btn--small{
                line-height: 2.75rem;
                display: block;
                min-width: 2.75rem;
                height: 2.75rem;
                margin: 0;
                padding: 0;
                font-weight: 400;
            }
            & .btn--secondary a{
                font-size: 1em;
                font-weight: 400;
                display: block;
                padding: .125rem 5px 0 5px;
                color: $color__bright;
            }
            & .icon{
                width: 1.5rem;
                height: 2.75rem;
                padding-top: rem(2);;
                text-align: center;
            }
        }
    }
}

small{
    font-weight: 300;
    color: $color__accent--dark;
}

.mediadaten_container a{
    font-size: 1.1875rem;
    font-weight: 400;
}

.mediadaten_container{
    padding-top: rem(12);
}

.simple_table{
    .table-wrapper table{
        width: 100%;
    }
    td,
    th{
        min-width: auto;
    }
    td{
        font-weight: 300;
        border-bottom: 0;
        & p{
                font-size: 1rem;
                margin-bottom: 0;
        }
    }
    tbody tr:nth-child(odd){
        background-color: $color__bright;
    }
    tbody tr:hover{
        color: $color__dark;
        background-color: $color__bright;
    }
}
.hyphenate{
    hyphens: auto;
}
.wrap_image{
    float: left;
    // display: inline-block;

    margin-right: 10px;
}
.specials_full td:first-child{
    width: 35%;
}
.specials_full td{
    text-align: left;
}
.go4media{
    .table-wrapper table{
        width: 100%;
        & td:first-child{
            min-width: 4.25rem;
        }
    }
    & .half_width{
        & td {
            width: 50%;
        }
    }
    .table-wrapper table.floating_tables{
        width: 33%;
        td{
            white-space: nowrap;
        }
    }
    td,
    th{
        min-width: auto;
    }
    td{
        // border-left: 1px solid $color__accent;
        font-weight: 300;
        &.footnote {
            width: rem(25);
        }
        & p{
            font-size: 1rem;
            margin-bottom: 0;
        }
    }
    .verticalTd{
        width: 4rem;
        text-align: center;
    }
    tbody tr:nth-child(odd){
        background-color: $color__bright;
    }
    tbody tr:hover{
        color: $color__dark;
        background-color: $color__bright;
    }
    tbody tr.empty_row:nth-child(odd),
    tbody tr.empty_row:hover,
    .empty_row{
        background-color: transparent;
    }
    .empty_row td{
        border: 0;
    }
    .center{
        text-align: center;
    }
    .verticalText{
        line-height: 1.5;

        display: inline-block;
        overflow: hidden;

        width: 1.5em;
    }
    .verticalText *{
        display: inline-block;

        transform: rotate(-90deg);
        transform: translate(0, 100%) rotate(-90deg);
        transform-origin: 0 0;
        white-space: nowrap;
    }
    .verticalText *:before{
        float: left;
        margin-top: 100%;
        content: '';
    }
    .specs small{
        color: $color__primary;
    }
    .specs .col1{
        float: left;
        width: 4rem;
    }
    .specs .col2{
        float: left;
        width: calc(100% - 4rem);
    }
    .medienradar{
        overflow-x: auto;
        margin-top: rem(50);
    }
    .radar{
        font-weight: 300;
        width: 100%;
        min-width: rem(580);
    }
    .radar p{
        margin: 0;
    }
    .radar_row{
        position: relative;
        width: 100%;
        padding: 2px 0;
        border-top: 1px solid $color__accent;
    }
    .radar_row_last{
        border-bottom: 1px solid $color__accent;
    }
    .radar_heading_left{
        position: absolute;
        left: 0;
    }
    .radar_heading_center{
        width: 100%;
        text-align: center;
    }
    .radar_inner{
        position: relative;
    }
    .radar_inner,
    .radar_middle{
        width: 39rem;
    }
    .radar_outside,
    .radar_middle{
        margin: 0 auto;
    }
    .radar_inner_side{
        font-weight: 700;
        line-height: rem(60);
        display: block;
        float: left;
        width: 5.125rem;
        margin: 0 rem(6);
    }
    .radar_middle{
        width: rem(402);
    }
    .radar_center{
        float: left;
        width: rem(429);
    }
    .radar_outside{
        width: rem(180);
    }
    .radar_outside p{
        line-height: rem(60);
        width: rem(180);
        height: rem(60);
        text-align: center;
    }
    .radar_middle p,
    .radar_center p{
        line-height: rem(65);

        float: left;

        height: rem(65);
        margin: 0 rem(1);

        text-align: center;
    }
    .radar_middle p{
        width: rem(130);
    }
    .radar_center p{
        width: rem(140);
    }
    .radar_info{
        position: absolute;
        top: 0;
        right: 0;
    }
    .clearfix:before,
    .clearfix:after{
        display: table;
        content: '';
    }
    .clearfix:after{
        clear: both;
    }
    .clearfix{
        zoom: 1;
    }
    .clear_both{
        clear: both;
    }
    .graph_table table td{
        min-width: rem(100);

        border: 0;
    }
    .graph_table table td:first-child{
        border-right: 1px solid $color__accent;
    }
    & .caption_images_container{
        display: inline-block;

        margin: 0 rem(15);
        & .caption_title{
                margin-bottom: rem(10);
        }
    }
}
.portfolio-rating{
    font-size: 1.0625rem;
    font-weight: bold;
    line-height: 1.06;

    margin: 0;
    padding: .5rem .0rem 0rem;

    text-transform: uppercase;

    color: $color__primary;
}

.full-length{
    padding: 0;
}

.filter-search__button{
    width: 2.575rem;
    padding-right: 0;
    padding-left: 0;
}

.filter-search__input{
    padding-right: 0;
    padding-left: .25rem;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;
}

h2.no-results{
    font-weight: 400;

    width: 100%;
    margin-bottom: 0;
    padding-bottom: rem(20);

    text-align: center;

    color: $color__secondary;
}

.tags_group{
    padding-top: 1rem;
}

.block_teasers_container{
    display: block;
}

.tags_subheader{
    text-align: left;
    padding-left: 0.625rem;;
}

.monthly_spots{
    & h1{
        margin: rem(15) 0 0 0;
    padding: 0;
    }
    & h2{
        font-weight: 400;

        width: 100%;

        text-align: center;
    }
    & h4{
        font-weight: 400;

        margin: 0;
    padding: 0;
    }
    & .rank__container{
        margin-bottom: rem(25);
    padding-bottom: rem(10);

        border-bottom: 2px solid $color__accent--deep;
    }
}

.awards_container,
.container_filters{
    float: none;

    width: 100%;
    margin-top: rem(12);
}

.awards_container{
    position: relative;

    min-height: 344px;

    background-color: $color__accent;
    & .award{
        display: inline-block;

        width: 30%;
    margin: 0 1%;

        vertical-align: top;
    & h4
        {
            margin-top: rem(9);
    }
    }
    & label{
        display: inline-block;

        padding: 0;

        vertical-align: middle;
    }
    & input[type=checkbox]{
        display: inline-block;

        width: 1rem;
    height: 1rem;
    margin: 0;

        vertical-align: middle;

        border: 1px solid $color__secondary;
    }
    & .metal_cont{
        display: block;
    }
    & .dates{
        position: relative;

        display: inline-block;

        width: 95%;
    margin: 1%;

        vertical-align: top;
    & .form--horizontal
        {
            position: relative;

            margin: 1rem 0 1.5rem;
    }
        & label
        {
            display: block;
    }
        & input
        {
            display: block;

            width: 100%;
    }
    }
    & select{
        overflow: hidden;

        width: 100%;
    height: 6.675rem;
    padding-right: 0;

        background-image: none;
    &#from_year
        {
            height: 2.875rem;
        padding: .0rem 0rem;
    }
        &#to_year
        {
            height: 2.875rem;
        padding: .0rem 0rem;
    }
        & option
        {
            width: 100%;
    }
    }
}

.awards_toggler{
    position: absolute;
    z-index: -1;
    top: 0;

    width: 100%;

    opacity: 0;
}

@keyframes awardsIn{
    from{opacity: 0;}
    to{opacity: 1;}
}

@keyframes awardsOut{
    from{z-index: 1;opacity: 1;}
    to{opacity: 0;}
}

.awards_toggler.awards_come{
    z-index: 1;
    animation: awardsIn .5s ease-out;
    opacity: 1;
}

.awards_toggler.awards_leave{
    z-index: -1;

    animation: awardsOut .5s ease-out;

    opacity: 0;
}

.filter-wrapper__row.publispot{
    position: relative;

    overflow: hidden;

    min-height: 145px;
}

.publispot_button.button_ontop,
.filter-wrapper__col.publispot_button{
    width: 100%;
    margin-top: rem(12);
    margin-right: 0;
    margin-bottom: rem(35);
}

.filter-wrapper__col.publispot_button > .btn{
    width: 100%;
    margin-bottom: 0;
    padding: 0rem;

    text-align: center;
}

.filter-wrapper__col.publispot_button > .btn .icon{
    width: 4rem;
    height: 4rem;
}

.filter-wrapper__col.publispot_button > .btn span{
    display: inline-block;

    padding-top: rem(15);
}

.picture{
    margin: 0;
    padding: 0;

    list-style: none;
}

.slider_container{
    margin: 0 auto;
}

.lSSlideOuter .lSPager.lSGallery{
    text-align: center;
}

.lSSlideOuter .lSPager.lSGallery li{
    display: inline-block;
    float: none;

    width: rem(24) !important;
}

.usingCss{
    margin-top: rem(20);
}

.usingCss ul li img{
    display: block;
    // width: 90%;

    max-width: 90%;
    margin: 0 auto;
}

.content_page_left{
    float: none;

    width: 100%;
    padding-bottom: 10px;
}

.content_page_right{
    float: none;

    width: 100%;
    padding-bottom: 10px;
}

.brands_filters{
    padding-top: rem(18);
}

.brands_filters .sub-nav--active{
    color: $color__dark !important;
    border: rem(1) solid $color__primary;
    border-bottom-color: $color__primary !important;
    background: $color__primary;
}

.brands_filters .btn:active,
.brands_filters .btn.btn--active{
    // border: rem(1) solid $color__accent--deep;
    // border-bottom-color: $color__accent--deep !important;
    // background: $color__accent--deep;
    color: $color__secondary;
}

.brands_filters .btn:focus,
.brands_filters .btn:active,
.brands_filters .btn:visited{
    color: $color__dark;
}

.sendtosearch_container{
    width: 100%;

    text-align: center;
}

.filter_container_content #sendtosearch{
    margin: rem(12) auto;

    text-align: center;
}
.multiple_buttons_agb{
    display: block;
    & .btn .icon{
        display: inline-block;

        width: 1.625rem;
    height: 1.625rem;

        vertical-align: middle;
    }
    & .btn{
        line-height: 1.06;

        display: inline-block;

        text-align: left;
    }
    & .underline{
        width: calc(100% - 40px);
    }
    & span{
        font-size: .7rem;

        display: inline-block;

        width: calc(100% - 40px);
    }
}

.logic img{
    width: 100%;
}

.other_media_container{
    width: 100%;
}

.other_media{
    position: relative;

    display: inline-block;

    width: 100%;
    margin-bottom: 1.25rem;

    text-align: left;
    vertical-align: top;
}

.other_media--small{
    width: 98%;
    margin: rem(15) 1%;
}

// sponsoring - werbung containers
.categories_wrapper{
    position: relative;
    z-index: 13;

    display: flex;

    margin-top: rem(20);
    padding: 0 !important;

    text-align: justify;

    flex-wrap: wrap;
    justify-content: space-between;
    &:after{
        display: inline-block;

        width: 100%;

        content: '';
    }
}
.toolbox{
    margin-top: 40px;
    & label[for=stay_box]{
        color: $color__primary;
    }
    & .btn{
        background-color: $color__bright;
        border: 0.0625rem solid $color__primary;
        color: $color__primary;
    }
    & input[type="checkbox"] + label i{
        left: 0;
    }
}
.toolbox_right{
    width: 87%;
    margin-left: 5%;
    & h3{
        font-size: 2.8rem;
    }
    & p{
        font-size: 1.5rem;
    }
}

.categories{
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: rem(20);
    transition: transform 600ms ease;
    text-align: left;
    vertical-align: top;
    border: 0;
    flex: 0 1 auto;

    // transform: perspective(1000px);
    // backface-visibility: hidden;
    // border: 1px solid $color__accent--light;
    // background-color: $color__bright;

    &:focus{
        color: $color__accent;
        border-color: $color__accent;
    }
    &:hover{
        color: $color__accent;
        // transform: perspective(1000px) rotateY(-5deg);
    }
    & h2{
        margin: rem(10) rem(10) 0 rem(10);
    }
    p{
        font-size: rem(16);
        margin-bottom: 0;
        @include word-break;
    }
    & .categories_title{
        margin: rem(15) rem(10) rem(5) rem(0);
        color: $color__primary;
    }
    & .underline{
        margin-top: 0;
        & span{
            border-color: $color__primary;
            color: $color__bright;
        }
    }
    & .btn{
        text-align: left;
        color: $color__primary;
        &:hover{
            color: $color__secondary;
        }
    }
}

.categories--dark{
    padding-bottom: 1rem;
    background-color: transparent;
    .categories__label{
        color: $color__accent--medium;
    }
    .categories__content{
        color: $color__accent;
    }
    & .multiple_buttons .icon--download{
        margin-top: 0;
    }
}

@include mq(medium){
    .categories_wrapper{
        padding: 0;
    }
}

@include mq(large){
    .categories_wrapper{
        padding: 0;
    }
    .categories{
        float: left;

        width: 49%;
    }
}
//********
.danke{
    font-size: 1.15rem;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    color: #fff;
    text-align: center;
}
.blink{
    animation: blink 0.4s linear 3;
}
@keyframes blink{
    0%{opacity: 1;}
    50%{opacity: .5;}
    100%{opacity: 0;}
}
//**************
.spots_video{
    margin-top: 0;
}

.spots_video .video-player__inner{
    padding: rem(20) 0;
}

.programatic_gray_icon_cont{
    font-size: 0;
    line-height: 0;

    display: inline-block;

    width: 98%;
    margin: rem(10) 1% 0 1%;
    margin-bottom: rem(15);

    vertical-align: top;
}

.programatic_gray_icon{
    display: inline-block;

    width: 30%;
    margin-right: 2%;

    text-align: center;
    vertical-align: top;
}

.audience-profiles-text{
    display: inline-block;

    width: 67%;
    & h5{
        margin-top: 0;
    }
}

.website_logos_cont{
    width: 100%;

    text-align: center;
}

.website_logos{
    display: inline-block;

    width: 21.7%;
    margin: 0 1%;
    margin-bottom: rem(15);
}

.ringier_table td{
    font-weight: 300;
}

.ringier_table th{
    color: $color__bright;
    background-color: #da2c8b;
}

.contact_container{
    width: 98%;
    min-width: 15rem;
    margin: 0 1%;
}

.contact_container{
    & textarea::placeholder{
        color: $color__primary;
        text-transform: uppercase;
    }
    & select{
        height: 3.15rem;
    }
    & select{
        color: $color__accent--medium;
    }
    & select:focus{
        color: $color__primary;
    }
}

.show_description__image.program_logo{
    position: relative;

    float: left;

    width: 15%;
    padding: 2%;

    background: $color__accent;
}

.template_secondary .content__container{
    margin: 0;
    padding: 0;
}

.show_description__content_mediaguide,
.show_description__content.program_content{
    float: left;

    width: 74%;
}

.inside_info_full{
    width: 100%;
}

.inside_info_half{
    width: 100%;
}

.alert{
    display: block;

    margin: 0;
    padding: 0;

    list-style: none;
}

.alert:focus{
    display: none;

    cursor: pointer;
}

.alert-danger{
    font-size: 1rem;

    padding: rem(10) rem(15);

    color: $color__bright;
    background: $color__alert;
}

.alert-danger:before{
    font-weight: 400;

    margin-right: rem(10);

    content: '✖';

    color: $color__bright;
}

.alert-success{
    font-size: 1rem;

    padding: rem(2) rem(15);

    color: $color__bright;
    background: $color__primary;
}

.alert-success.large{
    padding: rem(15) rem(15);
}

.table-wrapper{
    margin-top: 0;
}

.show_tab_details{
    table{
        & tr
        {
            background-color: transparent;
        &:nth-child(odd)
            {
                background: transparent;
            &::hover
                {
                    background: transparent;
            }
            }
        }
        & th
        {
            text-align: left;
    }
        & td
        {
            text-align: left;
    }
    }
}

.content__container.fourOfour{
    position: relative;

    text-align: center;
    & h2.red{
        font-weight: 400;

        color: $color__secondary;
    }
    & h1.container_primary{
        font-size: 8rem;

        max-width: rem(300);
        margin: 0 auto;
        padding: 2.5rem .5rem;

        text-align: center;

        color: $color__bright;
        background: $color__primary;
    }
    & p{
        font-size: 1rem;

        max-width: rem(300);
        margin: 2rem auto;

        text-align: left;

        color: $color__dark;
    }
}

#spot_video{
    position: relative;
}

.loader_wrapper{
    position: relative;
    z-index: 88;

    width: 100%;
    height: 100%;
    min-height: rem(150);
}

#peopleContainer{
    position: relative;

    background: $color__secondary;
    & .inside_info{
        float: none;
        width: 100%;
        margin-bottom: rem(15);
        padding: rem(10);
        &__image{
            float: left;
            width: 40%;
            height: auto;
            margin: 0rem 3% 0 0;
        }
        & .name_actions{
            float: left;

            width: 56.5%;
            & .inside_info__name{
                width: 100%;
                margin: 0;
                color: $color__primary;
            }
            & .inside_info__actions{
                width: 100%;
                margin: 0;
                & a{
                    color: $color__primary;
                }
                & .btn.btn--link{
                    font-size: .9rem;
                }
            }
        }
    }
    & .no_results_people{
        font-size: 1rem;
        font-weight: 300;

        padding: rem(10);

        color: $color__bright;
        &::before{
            font-weight: bold;
            padding-right: rem(17);
            content: 'X';
        }
    }
}

h3.teaser__title,
h3.spot__title{
    font-size: 1.125rem;
}

.bg-very_strong{
    display: block;

    height: 25px;

    color: $color__bright;
    background: $color__secondary;
}

.bg-strong{
    display: block;

    height: 25px;

    color: $color__bright;
    background: #ea4579;
}

.bg-above_average{
    display: block;

    height: 25px;

    color: $color__bright;
    background: #f384a7;
}

.bg-average{
    display: block;

    height: 25px;

    color: $color__bright;
    background: #bcbec0;
}

.bg-below_average{
    display: block;

    height: 25px;

    color: $color__bright;
    background: #939598;
}

.bg-weak{
    display: block;

    height: 25px;

    color: $color__bright;
    background: #6d6e71;
}

.bg-very_weak{
    display: block;

    height: 25px;

    color: $color__bright;
    background: #000;
}

.floating_tables{
    float: left;

    width: 33%;
}

td.graph_table{
    height: 25px;
    margin: 0;
    padding: 0;
}

.graph_table table{
    height: 100%;

    border-collapse: collapse;

    border-bottom: 0;
}

.graph_table table td{
    padding: 0;

    border-bottom: 0;
}

.graph_table span{
    height: 15px;

    background-color: $color__secondary;
}

.graph_table table td:first-child{
    border-right: 1px solid $color__accent;
}

.go4media{
    & .advert_space_table th:first-child,
    .advert_space_table td:first-child{
        text-align: left;
    }
    & .advert_space_table th:nth-child(2),
    .advert_space_table td:nth-child(2){
        text-align: left;
    }
    & .advert_space_table th,
    .advert_space_table td{
        text-align: right;
    }
    & .advert_section_table th:first-child,
    .advert_section_table td:first-child{
        text-align: left;
    }
    & .advert_section_table th,
    .advert_section_table td{
        text-align: right;
    }
    & .floating_tables th,
    .floating_tables td{
        text-align: left;
    }
    & .custom_table{
        margin-bottom: rem(10);
    }
}

.content--one-organigram{
    padding-top: rem(30);

    background: $color__accent--medium;
}

.shows_menu_in_program_content{
    margin: 0;
    padding: 0;

    list-style: none;

    text-align: left;
}

.shows_menu_in_program_content li{
    font-weight: 400;

    display: block;
}

.shows_menu_in_program_content li a{
    font-size: 1.1875rem;
    font-weight: 400;

    text-transform: capitalize;
}

.shows_menu_in_program_content li:after{
    font-size: .7rem;
    line-height: 1.3;

    display: block;

    margin: 0 rem(7);
    padding: 0 rem(7);

    content: '';
    // vertical-align: middle;

    color: $color__dark;
}

.shows_menu_in_program_content li:last-child:after{
    display: none;

    content: '';
}

#account_items{
    & a{
        color: $color__dark;
        & .dash_items{
            display: inline-block;
            text-align: center;
            vertical-align: middle;
        }
    }
    & .icon{
        display: inline-block;
        width: 4rem;
        height: 4rem;;
        text-align: center;
    }
    & span{
        font-size: 1rem;
        font-weight: 700;
        display: block;
        padding-top: .5rem;
        text-align: center;
    }
    & .brand-overview__item.digital_class{
        width: 48%;
        min-height: 12rem;
        // margin-left: auto;
        // margin-right: auto;
        // &:nth-child(2n){
        //     margin-right: 0;
        // }
    }
}

#account_items2{
    & a{
        color: $color__dark;
        & .dash_items{
            display: inline-block;
            text-align: center;
            vertical-align: middle;
        }
    }
    & .icon{
        display: inline-block;
        width: 4rem;
        height: 4rem;;
        text-align: center;
    }
    & span{
        font-size: 1rem;
        font-weight: 700;
        display: block;
        padding-top: .5rem;
        text-align: center;
    }
    & .brand-overview__item.digital_class{
        width: 48%;
        min-height: 12rem;
        // margin-left: auto;
        // margin-right: auto;
        // &:nth-child(2n){
        //     margin-right: 0;
        // }
    }

}
h3.dashboard_options{
    padding: 1.25rem 1.25rem 0 1.25rem;
    clear: both;
}

input:-webkit-autofill{
    background-color: #f00 !important;
}

.forgott_pass{
    margin-left: rem(7);
}

#formEdit,
#signupform{
    margin: rem(15) auto;
}

.input-addon2{
    display: block;
    float: none;

    width: 97%;
    margin: 0 1%;
    & input,
    textarea{
        line-height: 1.2;
    }
    &_plz{
        float: left;

        width: 27%;
    margin: 0 1%;
    }
    &_ort{
        float: left;

        width: 69%;
    margin: 0 1%;
    }
    & input#answer{
        margin-bottom: rem(10);
        &::placeholder{
            color: $color__primary;
            text-transform: uppercase;
        }
    }
}

.member_hello{
    font-size: 1rem;
    font-weight: 300;
}

form:after{
    clear: both;

    content: '';
}

.dark_bg{
    color: $color__bright;
    background: $color__dark;
}

#staff_members{
    & a:hover{
        color: $color__secondary;
        border-bottom-color: $color__secondary;
    }
    & h3{
        padding-top: 2rem;
        padding-bottom: 0;
        text-align: left;
        color: $color__primary;
    }
    & h4{
        font-weight: 300;
        width: 100%;
        margin-top: 0;
        margin-bottom: rem(25);
        padding-top: rem(15);
        color: $color__primary;
        border-bottom: 1px solid $color__primary;

    }
    & .contact-container{
        float: none;
        width: 98%;
        margin: 0 1%;
    }
    & .members-wrapper__col{
        float: left;

        width: 98%;
        margin-right: 1.9356%;
    }
    & .filter-select__select{
        font-size: 1.125rem;
        height: 2.513em;
        // color: $color__accent--medium;
        &.selected{
                color: $color__secondary;
        }
        &:hover{
            color: $color__primary;
        }
        &:focus{
            color: $color__primary;
        }
        & option{
            background-color: $color__bright;
        }
    }
    & .filter-select__label{
        color: $color__accent--medium !important;
    }
    & input{
        // padding-left: 0;
        color: $color__secondary;
    }
    & input.btn{
        color: $color__bright;
        height: 2.73rem;
        line-height: normal;
        :visited{
            color: $color__bright;
        }
    }
    & .filter-wrapper__toggle-btn:hover{
        color: $color__secondary;
    }
}

.teaser__top-buttons{
    position: absolute;
    top: 0;
    right: 0;
    & .btn{
        font-size: .7rem;
    line-height: 1.2;

        width: 1.2rem;
    height: 1.2rem;
    margin-bottom: 0;
    margin-left: 1px;
    padding: .1875rem 0;
    }
    & .btn .icon{
        width: .9rem;
    height: .9rem;
    }
}
.member__top-buttons{
    position: absolute;
    top: 0;
    right: 0;
    & .btn{
        width: 2.1rem;
    height: 2.1rem;
    &--link
        {
            color: $color__bright;
        background-color: $color__secondary;
        &:hover
            {
                color: $color__bright !important;
        }
        }
        & .icon
        {
            width: 1.525rem;
        height: 1.525rem;
    }
    }
}
#search_results_members{
    position: relative;
    z-index: 20;

    min-height: rem(150);
}

.inside-info_container{
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 1.9325%;
}

.filter-wrapper__row.wizardSearch,
.filter-wrapper__col.wizard{
    width: 100%;
    & input.select2-search__field{
        &::placeholder{
            padding-top: 4px;
            color: $color__primary;
            text-transform: uppercase;
    }
    }
}

.comeContact{

    animation: comeContact .5s ease-out;
    position: fixed;
    width: 100%;
    opacity: 1;
    z-index: 50;
    top: 79px;
    left: 0;
    border: 1px solid #707070;
}

@keyframes comeContact{
    from{
        top: 0;
    // left: 105%;

        opacity: 0;
    }
    to{
        top: 79px;
    // left: 5%;

        opacity: 1;
    }
}

.angle-contact{
    & form#submitnewsletter{
        width: 100%;
    & input
        {
            &#subEmail
            {
                display: block;

                width: 93%;
            margin: 0 1%;
            padding-left: 0;
        }
            &.newsletter_comune
            {
                display: block;

                width: 93%;
            margin: 0 1%;
            padding-left: 0;
        }
        }
        & select
        {
            &.newsletter_comune
            {
                font-size: 1.125rem;

                display: block;

                width: 93%;
            height: 3.175rem;
            margin: 0 1%;

                color: $color__accent--medium;
            &:hover
                {
                    color: $color__primary;
            }
            }
        }
        & #subToNewsletter
        {
            display: block;

            width: rem(150);
        margin: 1%;
    }
        & .small
        {
            font-size: .7rem;

            padding: rem(7) 1%;

            color: $color__dark;
    }
    }
}

.newsletter_cont{
    padding-top: 1rem;
}

.newsletter_container{
    & h1.for_logo{
        font-size: 1rem;

        margin: 0 0 1.5rem 0;
    padding: 0;
    & img
        {
            width: 27%;
        min-width: 15rem;
    }
    }
}

.product-accordion__content{
    display: none;
}

.product-accordion__title{
    font-size: rem(24);
    font-weight: 700;

    padding-left: 2.5rem;

    cursor: pointer;
}

.product-accordion__item:before{
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;

    position: absolute;

    padding-top: 1.1rem;
    padding-left: .5rem;

    content: '+';

    color: $color__primary;
}

.product-accordion__item--open:before{
    content: '\2013';
}

.choice_to_be_made{
    font-size: 1rem;
    line-height: 1;

    float: left;

    padding-top: 10px;
    padding-bottom: 7px;
}

.sub_team{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    & a{
        display: block;
    float: right;

        margin-top: rem(10);
    &.btn
        {
            padding-right: 0;

            border: 0;
    }
    }
    &:after{
        clear: both;

        content: '';
    }
}

.occurrence{
    float: left;

    margin-right: rem(15);
    & span.number_of_occurrences{
        font-size: 1rem;
    font-weight: 300;

        padding-right: rem(5);
    }
    & span.occurrences_word{
        & .btn .icon
        {
            padding-top: rem(3);
    }
    }
}

.extra_channels{
    position: absolute;
    z-index: 1;
    right: 0;

    margin-top: 56.25%;
    &.no_image{
        margin-top: 0;
    }
}

.teaser__content.extra_channels_space{
    margin-top: rem(23);
}

.extra_channels a{
    font-size: .875rem;
    font-weight: 300;
    padding: 0 .4rem;
}

.quelle_chart_container{
    font-weight: 300;
    display: block;
    color: $color__accent--dark;
}

small.quelle{
    font-weight: 300;
    display: block;
    color: $color__accent--dark;
}

#searchPearson.btn:focus{
    border: .0625rem solid #e40048;
    background-color: #e40048;
}

.brand-digital-icon{
    position: absolute;
    z-index: 2;
    right: rem(6);
    bottom: rem(6);

    width: 1rem;
    height: 1rem;

    color: $color__primary;
}

:not(.brand-overview--size-special) .brand-overview__brand-link:hover .brand-digital-icon,
:not(.brand-overview--size-special) .brand-overview__brand-link:focus .brand-digital-icon,
:not(.brand-overview--size-special) .brand-overview__brand-link:visited .brand-digital-icon{
    right: 0;
    bottom: 0;
}

.content__container{
    padding: 0 rem(10);
}
// .teaser-group__wrapper .content__container{
//     padding: 0 !important;
// }

.tags_group,
.teaser-group{
    padding-top: 1rem;
    // padding-right: 1rem;
    // padding-left: 1rem;
}

.meta-nav__search-mobile{
    & .input-addon{
        display: block;
    & #search
        {
            display: inline-block;

            width: rem(190);
    }
        & .btn
        {
            display: inline-block;
    }
    }
}

.newsletter_container .newsletter_button{
    // width: auto;
    // min-width: 15rem;
    // max-width: 18rem;
    & .icon{
        vertical-align: middle;
    }
}

.filter-wrapper__col.wizard_button{
    font-size: 1rem;

    width: 130px;
}
.filter-wrapper__col.wizard_button:last-child{
    float: right;
}

.new_search{
    width: 100%;
}

table.publiplan{
    & [type='radio'] + label i{
        top: 0;
    }
    & [type='radio'] + label i:before{
        content: '';

        border: 1px solid #c7c7c7;
    }
    & tbody tr:nth-child(odd){
        background: transparent;
    }
}

.mediacompass{
    margin: 0;
    padding: 0;

    list-style: none;
}

.math{
    label{
        font-weight: 700;

        width: 7.4rem;
    padding-top: .8625rem;

        color: $color__secondary;
    }
    input{
        width: calc(100% - 8.1rem);
    }
}

.spotradar{
    & .product-accordion__title{
        float: left;

        width: calc(100% - 5rem);
    padding-right: 0;
    }
    & .actions{
        float: left;

        width: 5rem;
    padding-top: 1.8rem;
    }
    & h2{
        font-size: 1rem;
    }
}

.table_wrapper_spotradar{
    overflow-x: auto;
    & .product-accordion{
        min-width: 35rem;
    }
}
.is-dragging{
    z-index: 1;
}
.update_button_vectors{
    width: auto;

    text-align: right;
}
.filter-wrapper__col.programmstruktur{
    width: 100%;
}
.filter-wrapper__col.programmstruktur.search{
    width: 100px;
    margin-right: 0;
}
.filter-wrapper__col.programmstruktur:nth-child(5n+1){
    clear: none;
}
.filter-wrapper__col.programmstruktur:nth-child(5n){
    margin-right: 1.9356%;
}
.tall__input{
    font-size: 1.125rem;
    line-height: 1.5;

    height: 2.75rem;
    padding: .3625rem 1.875rem .5625rem rem(10);

    border: 0 ;
    border-radius: 0;
    color: $color__primary;
    font-weight: 400 !important;;
}
.filter-wrapper__radio_group{
    height: 2.75rem;
    padding: .3625rem 0 .5625rem rem(10);
    background-color: $color__accent;
    border: 0;
    color: $color__primary;
}
.video-js [type='button']{
    -webkit-appearance: none;
}
.more_visible{
    & [type='checkbox'] + label{
        margin-bottom: .8rem;

        color: $color__dark;
    & i:before
        {
            border: .0625rem solid #b5b5b5;
    }
    }
}
.teaser__buttons .btn{
    vertical-align: bottom;
}
.container_campaigns_list{
    clear: both;

    padding-bottom: rem(50);
}

.self_close{
    position: absolute;
    top: .7rem;
    right: .7rem;

    float: right;

    color: #fff;
}
.self_close .btn--circle{
    line-height: 1.3;

    width: 2.5rem;
    height: 2.5rem;
}
.inline_filters_container{
    display: flex;

    flex-wrap: wrap;
}
.topliste{
    font-size: .875rem;
    & label{
        font-size: .875rem;
    }
    & .topliste__filter{
        flex: 1;
    }
    & td,
    th{
        min-width: auto;
    padding: .3rem;
    }
}

.media_lexicon{
    width: 100%;
    min-width: 47.5%;
    margin: 0;
    padding: 0;

    vertical-align: top;

    flex: 1;
}

.letters_cont{
    display: flex;

    flex-wrap: wrap;
    & h2{
        flex: 1;
    }
}
.media_lexicon_container{
    display: block;
}
.settings_container{
    & label{
        color: $color__dark;
    }
    & tbody tr:nth-child(odd){
        background-color: transparent;
    }
    & tbody tr:nth-child(odd):hover{
        background: $color__accent;
    }
    & td,
    th{
        min-width: .1rem;
    padding: 0;
    }
}
.spot--dark .spot__link:hover{
    color: inherit;
}
.chartboder{
    margin-bottom: rem(10);
    padding: rem(5);

    border: rem(1) solid $color__accent;
}
.chart_container{
    margin-bottom: 1.5rem;
    /*border-bottom: rem(1) solid $color__accent--dark;*/
    padding-bottom: rem(15);
}
.the_download_button{
    display: block;

    cursor: pointer;
    text-align: right;
}
.rad_cont_newsletter{
    float: left;

    margin: 0;
    & label{
        margin-bottom: 20px;
    padding: .5625rem .5625rem .5625rem 1rem;
    & img
        {
            max-width: 200px;
    }
    }
    & [type='radio'] + label i{
        top: 8.2625rem;
    left: 5.8625rem;

        display: block;
    }
    & [type='radio'] + label i:before{
        border: .0625rem solid $color__accent--dark;
    }
}

.kampagnen__custom{
    width: 100%;
    & .btn{
        color: $color__dark;
    border-color: transparent;
    & .icon
        {
            width: 1.425rem;
        height: 1.425rem;
        & span.kamp_text
            {
                font-size: 12px;

                display: inline-block;
            display: block;
        }
        }
    }
    & .btn--tertiary{
        color: #cdcdcd;
    }
}

.small_campaign_btn{
    &.btn--icon{
        width: 2rem;
    height: 2rem;
    }
    &.btn .icon{
        width: 1.725rem;
    height: 1.725rem;
    }
}
.toolbox_promo{
    display: none;
}

.meta-nav_dashboard{
    position: absolute;
    left: 7%;

    margin-top: 51px;

    text-align: left;
}
.meta-nav_dashboard span{
    display: inline-block;
}

.meta-nav_dashboard #cool_menu{
    left: 0;

    visibility: visible;

    width: rem(200);
    margin: 0;
    padding: 0 4%;

    list-style: none;

    opacity: 1;
}
.meta-nav_dashboard #cool_menu li{
    padding: rem(5) 0;
}
.meta-nav_dashboard #cool_menu li a{
    font-size: 1rem;

    color: $color__bright;
}
.meta-nav_dashboard #cool_menu li a:hover{
    color: $color__accent--medium;
}
.meta-nav_dashboard #cool_menu li .icon{
    width: 1.575rem;
    height: 1.575rem;
}
.account_options{
    margin-top: rem(15);
}
#teaserfeedback{
    & textarea{
        background: $color__accent--light;
    &::placeholder{
            color: $color__primary;
            text-transform: uppercase;
    }
    }
    & input#answer{
        &::placeholder{
            color: $color__primary;
            text-transform: uppercase;
    }
    }
    & input#sendLogin_box_feedback{
        margin-top: 4px;
    }
}
.specials_full{
    width: 100%;
}

.no_border{
    border: 0;
}

#corp_wording_custom{
    width: 100%;
    & th{
        width: 20%;
    & span svg
        {
            cursor: pointer;
    }
    }
}
.main-nav__item .cool_submenu{
    // display: none;
    background-color: transparent;
    margin: 0;
    padding: rem(5) 0 0 0rem;
    list-style: none;
    background-color: $color__secondary;
    & li{
        & a{
            color: $color__bright;
            font-weight: 300;
            padding: rem(5) 0 rem(5) 0.925rem;;
            display: block;
            font-size: 0.9rem;
            &:hover{
                color: $color__accent;
            }
            &.cool_sticky--active{
                color: #e40048;
                background-color: #fff;
            }
        }
    }
}

.in-out-radios{
    & label{
        font-weight: 400;

        color: $color__accent--dark;
    }
    & [type='radio'] + label{
        padding-top: 0;
    & i
        {
            top: 0;
    }
        & i:before
        {
            border: .0625rem solid $color__accent--dark;
    }
    }
    & [type='radio']:checked + label i:before,
    [type='radio']:checked:hover + label i:before{
        border: 1px solid $color__secondary;
    }
}

.tablenondefault th{
    font-weight: 700;

    text-align: left;
}
.mediaguide .tablenondefault{
    border-spacing: 2px 0;

    border-collapse: collapse;

    background: $color__bright;
}
.footer_menu{
    margin-right: .8rem;
}
.disclamer_text_area{
    overflow-y: auto;

    max-height: 500px;
}
#agreedisclamer{
    width: 100%;
}
.disclamer_angle{
    position: absolute !important;
    top: 34px;
    right: 0;
    left: auto;

    width: 100%;
    padding: 2rem !important;
}
.comeContact{
    z-index: 251 !important;
}
.close_overlay_toolbox {
    font-size: 13px;
    width: 26px;
    height: 26px;
    position: absolute;
    top: -10px;
    right: -10px;
    display: block;
    padding: 5px 6px 3px 6px;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    background: $color__secondary;
}
.survey_promo,
.newsletter_promo{
    position: fixed;
    z-index: 232;
    top: auto;
    // right: 0;
    // bottom: 0;

    display: block;

    // width: 282px;
    // min-height: 60px;
    // min-height: 50px;
    // margin: 9px;
    // padding: 10px;

    transition: 2s all;
    animation: enterFromBottom 2s;

    color: #505050;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 10px #999;
    & .close_overlay_toolbox{
        right: 0px;
    }
    & h2{
        margin-top: 0px;
        padding-top: 0;
        color: $color__primary;
        & p{
                font-weight: 700;
                color: $color__primary;
                font-size: 2rem;
        }
    }
    & .btn{
        margin-bottom: 0;
    }
    & label{
        color: $color__accent--deep;
        & i:before{
            border: 1px solid $color__accent--deep;
        }
    }

    & #short_survey{
        display: flex;

        margin: 0 0 0 0;
    padding: 0;

        list-style: none;

        align-items: flex-end;
    justify-content: flex-end;
    }
    & #short_survey li{
        font-size: 1rem;

        display: inline-block;

        text-align: left;

        flex: 1;
    }
    & #short_survey li:last-child{
        text-align: right;
    }
    & #short_survey li span .icon{
        display: block;

        width: 38px;
    height: 38px;
    margin: 10px auto;

        cursor: pointer;
    text-align: center;

        fill: #cdcdcd;
    }

    & #short_survey li span.always_red  .icon{
        fill: $color__secondary;
    }
}

.newsletter_promo{
    min-width: 320px;
    max-width: 450px;
    width: 100%;
    // top: 50%;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, 0);
    padding: 1rem 1rem;
    box-shadow: 0 0 10px #424242;
    z-index: 12345;
}
.newsletter_promo .btn{
    margin-bottom: 20px;
    margin-top: 25px;
}
/////////////////////////////

.container_backstage_rating{
    width: auto;
    display: inline-block;
    min-height: 60px;
    margin: 9px;
    padding: 10px;
    transition: 2s all;
    animation: enterFromBottom 2s;
    color: #505050;
    border-radius: 3px;
    background-color: $color__secondary;
    box-shadow: 0 0 10px #999;

    & h2{
        font-size: 1.15rem;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 400;
        font-style: italic;
        color: $color__bright;
        & p{
            font-weight: 400;
            color: $color__bright;
            font-size: 1.15rem;
            font-style: italic;
        }
    }
    .backstage_star_rating{
        width: 180px;
        margin: 0 auto;

        z-index: 232;
        display: block;

        display: flex;
        padding: 0;
        list-style: none;
        align-items: flex-end;
        justify-content: flex-end;
        & .btn{
            margin-bottom: 0;
        }
        & label{
            color: $color__accent--deep;
            & i:before{
                border: 1px solid $color__accent--deep;
            }
        }
        & li{
            font-size: 1rem;
            display: inline-block;
            text-align: left;
            flex: 1;
        }
        & li:last-child{
            text-align: right;
        }
        & li span .icon{
            display: block;

            width: 38px;
            height: 38px;
            margin: 10px auto 00px auto;

            cursor: pointer;
            text-align: center;

            fill: #ffffcc;
            &:hover{
                fill: $color__primary;
            }
        }
        & li span.always_yellow .icon{
            fill: #ffcc00;
        }
         & li span.always_petrol .icon{
            fill: $color__primary;
        }
    }
}

/////////////////////

@keyframes enterFromBottom{
    0%{
        bottom: -40%;
    }
    100%{
        bottom: 0;
    }
}
#form_container{
    flex-wrap: wrap;
}
#loginForm,
#backstageUpdatePassword{
    width: 100%;
    padding: 0 rem(15);
}
.oneweek{
    position: absolute !important;
}

.mediaguide .tablenondefault ol li,
.mediaguide .tablenondefault ul li{
    font-size: 1rem !important;
}
.mediaguide .mini-column{
    width: 1%;
}
.mediaguide{
    & .teaser-group{
        max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    &__wrapper
        {
            max-width: 100%;
        padding: 0;
    }
    }
}
.petrol_border{
    border: 5px solid $color__primary !important;
}
.petrol_border-top{
    border-top: 5px solid $color__primary !important;
}
.petrol_border-left-top-right{
    border-top: 5px solid $color__primary !important;
    border-right: 5px solid $color__primary !important;
    border-left: 5px solid $color__primary !important;
}
.petrol_border-left-right{
    border-right: 5px solid $color__primary !important;
    border-left: 5px solid $color__primary !important;
}
.petrol_border-top-bottom{
    border-top: 5px solid $color__primary !important;
    border-bottom: 5px solid $color__primary !important;
}

.petrol_border-left-top{
    border-top: 5px solid $color__primary !important;
    border-left: 5px solid $color__primary !important;
}
.petrol_border-top-right{
    border-top: 5px solid $color__primary !important;
    border-right: 5px solid $color__primary !important;
}
.petrol_border-left-bottom{
    border-bottom: 5px solid $color__primary !important;
    border-left: 5px solid $color__primary !important;
}
.petrol_border-bottom-right{
    border-right: 5px solid $color__primary !important;
    border-bottom: 5px solid $color__primary !important;
}
.petrol_border-left-bottom-right{
    border-right: 5px solid $color__primary !important;
    border-bottom: 5px solid $color__primary !important;
    border-left: 5px solid $color__primary !important;
}

.petrol_border-right{
    border-right: 5px solid $color__primary !important;
}
.petrol_border-bott{
    border-bottom: 5px solid $color__primary !important;
}
.petrol_border-left{
    border-left: 5px solid $color__primary !important;
}
.mg_border{
    border: 1px solid $color__dark !important;
}
.mg_border-top{
    border-top: 1px solid $color__dark !important;
}
.mg_border-right{
    border-right: 1px solid $color__dark !important;
}
.mg_border-bott{
    border-bottom: 1px solid $color__dark !important;
}
.mg_border-left{
    border-left: 1px solid $color__dark !important;
}
.mg_v-align--top{
    vertical-align: top;
}
.mg_v-align--midd{
    vertical-align: middle;
}
.mg_v-align--bott{
    vertical-align: bottom;
}


.vertical_input [type='checkbox'] + label i,
.vertical_input [type='radio'] + label i{
    position: relative;
    top: 0;
    left: .5rem;

    margin-top: 0;
}
.vertical_input [type='checkbox'] + label i:after{
    top: -.875rem;
}
.vertical_input [type='radio'] + label,
.vertical_input [type='checkbox'] + label{
    padding: 0 .1625rem 2.2825rem .725rem;
}
.filter-wrapper__toggle-area{
    position: relative;
}
#staff_members{
    & .or_separator{
        position: absolute;
        top: 36%;
        left: 47%;
        width: 25px;
        display: none;
    }
    .filter-wrapper__inner{
        padding-left: 0;
        padding-right: 0;
    }


    & .members-wrapper__col{
        margin-right: 1.9356%;
        &:last-child{
            margin-right: 0;
        }
        //&:nth-child(3n){
            // margin-top: 2.5rem;
        //}
    }
}
.teaser-group__wrapper{
    &.custom_brands{
        & .teaser{
            width: 100%;
            border: 0;
            background: transparent;
            & h1{
                font-size: 20px;
                font-weight: 300;
                color: $color__bright;
            }
            & img{
                width: 100%;
            }
        }
    }
}
.black_bg{
    margin-bottom: 1.5rem;

    background: $color__dark;
}

.all_languages_cont{
    float: right;

    width: 100%;

    text-align: right;
    & span{
        line-height: 32px;

        display: inline-block;

        width: 32px;
        height: 32px;
        margin-bottom: 5px;
        margin-left: 7px;

        cursor: pointer;
        text-align: center;

        color: $color__bright;
        background-color: $color__primary;
        &:hover {
            background-color: $color__secondary;
        }
    }
}
.inner_tab_visible{
    font-weight: 300;

    display: block;
}
.inner_tab_hidden{
    display: none;
}
.faqs_custom td,
.faqs_custom th{
    vertical-align: baseline;
    word-break: break-word;
}
.faqs_custom td p{
    font-size: 16px;
}
.faqs_custom td li{
    font-size: 16px;
}
.admin_edit_faq a{
    position: absolute;

    padding: 4px 6px;

    color: $color__bright;
    background: $color__primary;
}
.teaser--tpl1{
    border: 0;
    &:hover{
        transform: none;
    }
    & .teaser__content{
        padding: 0;
    & .intrinsic
        {
            margin: 1rem 0;
    }
    }
}

.digital_shows_categs{
    margin: 20px 0;
    & ul{
        margin: 0;
    padding: 0;

        list-style: none;
    & li
        {
            display: inline-block;

            margin-right: .1rem;
        margin-bottom: 1rem;

            background-color: $color__primary;
        & a
            {
                font-size: 1rem;
            font-weight: 500;

                position: relative;
            z-index: 3;

                display: block;

                padding: .4rem 2rem;

                color: $color__bright;
        }
            &.selected,
            &:hover
            {
                z-index: 31;

                display: inline-block;

                padding: 0;

                list-style: none;

                -webkit-transform: translate3d(0, -8px, 0) rotateX(-8deg);
            -webkit-transform: rotateY(5deg);
                    transform: translate3d(0, -8px, 0) rotateX(-8deg);
                    transform: rotateY(5deg);
            text-align: left;

                background: #e40048;

                -webkit-backface-visibility: visible;
                    backface-visibility: visible;
            &:before
                {
                    position: absolute;
                z-index: 1;
                top: -.025rem;
                left: 0;

                    display: block;
                overflow: hidden;

                    width: 99.9%;
                height: 1.9125rem;

                    content: '';
                -webkit-transform: skewY(2deg);
                    -ms-transform: skewY(2deg);
                        transform: skewY(2deg);
                -webkit-transform-origin: right bottom;
                    -ms-transform-origin: right bottom;
                        transform-origin: right bottom;

                    background: #e40048;

                    -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;
            }
            }
        }
    }
}
label[for=show_only_current]{
    margin-top: 2rem;

    color: $color__accent--deep;
}
select.filter-select__select{
    font-size: 1.125rem !important;
    padding-left: rem(10);
    height: 2.75rem;
}

.avatar_preview{
    max-width: 180px;
}

.cont_right{
    position: absolute;
    z-index: 999;
    z-index: 33;
    top: 50px;
    right: -270px;

    width: 304px;
    padding-top: 20px;

    transition: 1s all ease;
}
.twitter_placeholder{
    position: absolute;
    z-index: -1;
    right: 0;

    width: 270px;
    min-height: 22px;

    border-radius: 5px;
    background: #fff;
}
.hidden_twitter{
    right: 0 !important;
}
.twitter_open{
    cursor: pointer;
}
#task_asana{
    position: fixed;
    bottom: 0;
    left: 0;

    display: block;

    padding: 5px;

    cursor: pointer;
    text-align: center;
    text-transform: uppercase;

    color: $color__bright;
    background: $color__secondary;
}
#task_asana:after{
    margin-left: 4px;
    padding: 12px;

    content: '';

    background: url(../images/asana_logo.png) no-repeat center center;
    background-size: 20px;
}
#dropzone_asana{
    display: none;
}

#file-list + small{
    display: none;
}
#file-list:not(:empty) + small{
    display: block;
}
#file-list:empty:before{
    font-size: 1rem;

    display: block;

    content: 'Upload using the file input!';
}
#file-list{
    margin: 10px 0 0 0;
    padding: 0;
}
#file-list li{
    margin: 10px 0;

    list-style: none;
}
#file-list li > img{
    max-width: 30px;
    max-height: 20px;
    margin-right: 3px;
}
#file-list li .modal img{
    max-width: 400px;
    max-height: 400px;
}

#file-list li.error{
    color: red;
}
#file-list li.error a.btn{
    display: none;
}
#file-list .group{
    font-size: 1.1em;

    color: #666;
    border-bottom: dashed 1px #999;
}
#file-list pre{
    display: none;

    margin: 20px 0;

    border: solid 2px #8d8;
}
#file-list pre.done{
    border-color: #8d8;
}
#taskForm{
    position: relative;
}
#taskForm h3{
    margin-top: 0;
}
#task_title::placeholder{
    color: $color__primary;
}
.task_explication{
    font-size: .7rem;
    font-weight: 300;

    padding-bottom: 7px;
}
.just_task{
    padding: 1rem 1rem;
}
.show_running_time{
    font-size: rem(18);
    font-weight: bold;
}


.login-msn-or{
    font-size: 16px;
    font-weight: 700;

    position: relative;

    margin: 10px;

    text-align: center;
    text-transform: uppercase;
    &:before{
        position: absolute;
    top: 9px;
    right: 0;

        width: 36%;
    height: 5px;

        content: '';

        border-top: 1px solid #020202;
    }
    &:after{
        position: absolute;
    top: 9px;
    left: 0;

        width: 36%;
    height: 5px;

        content: '';

        border-top: 1px solid #020202;
    }
}

.login_msn_btn{
    display: inline-block;
    padding: 7px 0;
    & img{
        display: inline-block;

        width: 2rem;
    margin-right: .4rem;
    }
    & span{
        line-height: 1.1;

        display: inline-block;

        width: calc(100% - 2.6rem);

        vertical-align: top;
    }
}
.mediadaten_buttons{
    display: block;

    width: 100%;

    text-align: right;
    & a{
        font-weight: 300;
    }
    & .underline{
        margin-left: .8rem;
    }
    & .underline span{
        font-size: 1.025rem;
    font-weight: 300;

        padding: 0 0 0 2rem;

        text-decoration: underline;
    text-transform: none;
    }
    & .btn .icon{
        float: left;

        width: 1.825rem;
    height: 1.825rem;
    }
    & .icon--file{
        position: absolute;
    top: .5rem;
    left: 0;
    }
}
.cookie_disclamer{
    width: 75%;
    padding: .8rem;

    background-color: $color__accent--deep;
    & .cookie_disclamer_text{
        display: inline-block;

        width: calc(93% - 50px);

        color: $color__bright;
    }
    & .btn{
        display: inline-block;

        width: 50px;
    margin: 0 0 0 4%;
    padding: 0;

        vertical-align: top;
    }
}
.cookie_disclamer2{
    position: fixed;
    z-index: 10000;
    bottom: 1%;
    left: 1%;
}
.view_togglers{
    position: relative;
    z-index: 1;

    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem .7rem 0 .7rem;

    text-align: right;
    & .icon{
        width: 1.85rem;
        height: 1.85rem;
       margin: 0 .5rem;
        cursor: pointer;
        color: $color__dark;
        fill: $color__dark;
    }
    & .selected{
        color: $color__primary;
        fill: $color__primary;
    }
}





h{
    word-spacing: -.3em;
}

.list_view{
    & #account_items{
        & li.dashboard_special{
            display: inline-block;
            float: none;
            width: 100% !important;
        }
        & li.brand-overview__item-sizer{
            display: none;
        }
        & li{
            display: block;
            float: none;
            width: 100% !important;
            // height: 1.5rem !important;
            min-height: 2.2rem !important;
            padding-bottom: .1rem;
            margin-bottom: 10px !important;
            margin-left: 0.5% !important;
            margin-right: 0.5% !important;
            position: relative;
            text-align: left;
        & a {
            display: block;
            height: auto;
            padding: 0.3125rem;
            font-size: 0.9rem;;
            & .dash_items {
                    width: 100%;
                    text-align: left;
                    & br, m{
                        display: none;
                    }
                }
                & .icon{
                    width: 1.75rem;
                    height: 1.55rem;
                }
                & span{
                    font-weight: 300;
                    display: inline-block;
                    width: calc(100% - 3rem);
                    padding-top: 0;
                    padding-left: .8rem;

                    text-align: left;
                }
                &:hover{
                    transform: none;
                }
            }
        }
    }

    & #account_items2{
        margin-bottom: rem(40);
        & li.dashboard_special{
            display: inline-block;
            float: none;

            width: 100% !important;
        }
        & li.brand-overview__item-sizer{
            display: none;
        }
        & li{
            display: block;
            float: none;
            width: 100% !important;
            height: auto !important;
            min-height: .1rem !important;
            padding-bottom: .1rem;
            margin: 0.5% !important;
            text-align: left;
            & a{
                display: block;
                height: auto;
                & .dash_items {
                    width: 100%;
                    text-align: left;
                    & br, m{
                        display: none;
                    }
                }
                & .icon{
                    width: 1.75rem;
                    height: 1.55rem;
                }
                & span{
                    font-weight: 300;
                    display: inline-block;
                    width: calc(100% - 3rem);
                    padding-top: 0;
                    padding-left: .8rem;
                    text-align: left;
                }
                &:hover{
                    transform: none;
                }
            }
        }
    }
}
td.page_align_right,
th.page_align_right{
    text-align: right;
}
td.page_align_center,
th.page_align_center{
    text-align: center;
}
.intern_container{
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    & .non_intern{
        width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: .5rem;
    padding: 0 0 .3rem 0;

        flex: 1;
    }
    & .intern{
        width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: .5rem;
    padding: 0 0 .3rem 0;
    flex: 1;
    }
}
.channel_uploading_files{
    // padding-left: 17%;
    // margin-bottom: 40px;
    flex: 0 0 auto;
}


.adquality_icon{
    float: left;
    & .icon--smartphone{
        margin: 0 0px 0 -15px;
    }
    & .icon--digital {
        margin: 0 15px 0 0px;
    }
}
.adquality_label{
    float: left;
    padding-top: 5px;
    & h2{
        margin: 0;
    }
}
.adquality_label.mobi_le{
    padding-top: 12px;
}
.container_promo{
    & .toolbox_promo{
        position: fixed;
        z-index: 232;
        top: 50%;
        left: 50%;
        right: auto;
        transform: translate(-50%,-50%);
        display: block;
        width: 90%;
        max-width: 640px;
        min-height: 60px;
        min-height: 120px;
        margin: 9px;
        padding: 24px;
        color: #ffffff;
        background-color: #e40048;
        font-size: 23px;
        line-height: 32px;
        border-radius: 3px;
        box-shadow: 0 0 16px #000;

        & a{
            color: $color__primary;
            text-decoration: none;
            display: block;
            &:hover{
                color: $color__primary;
            }
        }
        & .close_overlay_toolbox {
            font-size: 13px;
            width: 26px;
            height: 26px;
            position: absolute;
            top: -10px;
            left: -10px;
            display: block;
            padding: 1px 6px 3px 5px;
            cursor: pointer;
            color: #fff;
            border-radius: 50%;
            text-align: center;
            background: $color__secondary;
       }
    }

}
.digital_icons{
    min-width: 4.25rem;
    display: inline-block;
    vertical-align: middle;
}

table.readership_structures{
    & th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(6),
      td:nth-child(2),
      td:nth-child(3),
      td:nth-child(4),
      td:nth-child(6){
        text-align: right !important;
      }
}
.left_menu{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
        color: $color__bright;
    display: inline-block;
    width: auto;
    margin: 10px 5px 10px 0;
    &:hover{
            background: $color__accent--medium;
    }
        &:active{
            background: $color__dark;
    }
        a{
            background: $color__secondary;
        padding: 5px 10px;
        color: $color__bright;
        font-size: .9rem;
        font-weight: 400;
    }

    }
}
#Fullscreen{
    text-align: center;
    margin: 20px auto;
    width: 150px;
    display: block;
}
iframe {
    .backgroundContainer{
        background-color: #fff !important;
    background: #fff !important;
    }
    .displayArea {
        top: 0 !important;
    }
}

.teaser__content{
    .table_cont_new{
        overflow: auto;
    table{
            min-width: 234px;
    }
    }
    table{
        td, th{
            min-width: 0.001rem;
    }
    }
}

.right_container{
    margin-top: 30px;
}
.special_menu_adm{
    display: none;
}
.special_menu_adm{
    order: 2;
    background: transparent;
    display: inline-block;
    margin: 0;
    padding: 4px 0;
    list-style: none;
    top: 20%;
    right: 0;
    z-index: 212;
    li {
        display: inline-block;
        margin-right: 0 !important;
    }a{
        color: #fff;

        &:active{
            background-color: transparent !important;;
        }
        & .icon{
            width: 1.25rem;
            height: 1.25rem;
            margin-left: 0;
        }
        &:hover .icon{
            color: $color__tertiary;
        }
    }
}

// .special_menu{
//     display: none;
// }

.header__meta{
    order: 3;
}

.accordion_custom{
    background: $color__accent--light !important;
    padding: .5rem .75rem;
    margin-bottom: .75rem;
    position: relative;
    & .description_content p{
        padding: 0.75rem 0 !important;
        margin: 0;
    }
    & .accordion_title{
        padding-bottom: .5rem;
        padding-top: .5rem;
        cursor: pointer;
        font-size: 1rem;
    }
    & .description_content p{
        font-size: 1rem;
    }
    & .expanded_accord_plus:after{
        content: '+';
        border: 1px solid $color__dark;
        font-size: 16px;
        line-height: 1;
        position: absolute;
        right: .75rem;
        padding: 0 3px;
        cursor: pointer;
    }
    & .expanded_accord_minus:after{
        content: '-';
        border: 1px solid $color__dark;
        font-size: 16px;
        line-height: 1;
        position: absolute;
        right: .75rem;
        padding: 0 5px;
        cursor: pointer;
    }
    & .content__container {
        padding: 0 0;
    }
}

.hero{
    margin-left: 0 !important;
}
.header__meta select.meta-select__select:focus,
.header__meta select.meta-select__select:hover{
    background-color: $color__primary;
}
.meta-nav__item{
    order: 3;;
}
.meta-nav__search-desktop{
    order: 1;
}
.meta-nav_login{
    order: 2;
}






.special_menu_adm{
    background: $color__secondary;
    display: inline-block;
    margin: 0;
    padding: 4px 0;
    list-style: none;
    position: absolute;
    top: 190px;
    right: 0;
    z-index: 212;
    li {
        display: block;
    }a{
        color: $color__bright;
        padding: 5px 10px 4px 10px;

        &:active{
            background-color: $color__bright !important;;
            color:  $color__secondary;
        }
        &:hover .icon{
            color: $color__tertiary;
        }
    }
}

.ganze_portofolio{
    width: 100%;
    text-align: center;
}
.caption h2{
    padding-bottom: 20px;;
}
.footer__bar .footer__inner{
    display: flex;
    flex-wrap: wrap;
}
.left_col_footer{
    min-width: 100%;
    max-width: 100%;
    order: 2;
    & .for_logo{
        & img{
            width: 100%;
            max-width: 205px;;
        }
    }
    & .footer_moto{
        color: $color__bright;
        font-size: 16px;
        line-height: rem(30);
        font-family: 'ATC Overlook', sans-serif;
        font-weight: 300;
        margin-top: rem(30);
    }
    & .footer__nav {
        margin-top: 40px;
        & a:not(:hover):not(:active) {
            color: #fff;
            & .icon{
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                &:hover{
                    color: $color__secondary;
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
        }
        & a:hover{
            & .icon{
                color: $color__secondary;
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }
}
.right_col_footer{
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0rem;
    order: 1;
    & .footer_menu_navi{
        min-width: 100%;
        max-width: 100%;
        padding: 0;
        margin: 0;
        order: 1;
        margin-bottom: rem(40);
        & li {
            & a{
                color: $color__bright;
                font-size: rem(14);
                line-height: rem(30);
                font-family: 'ATC Overlook', sans-serif;
                &:hover{
                    color: $color__secondary
                }
            }
        }
    }
    & .contact_info_footer{
        color: $color__bright;
        min-width: 100%;
        max-width: 100%;
        font-weight: 300;
        font-size: rem(14);
        line-height: rem(30);
        font-family: 'ATC Overlook', sans-serif;
        order: 2;
        margin-bottom: rem(40);
        & a{
            font-weight: 300;
            font-size: rem(14);
            line-height: rem(30);
            font-family: 'ATC Overlook', sans-serif;
            padding: 0;
            &:hover{
                color: $color__secondary;
            }
        }
    }
    & .footer__nav.footer__nav--right{
        float: none;
        min-width: 100%;
        max-width: 100%;
        order: 3;
        margin-bottom: rem(40);
        & li {
            font-weight: 300;
            font-size: rem(14);
            line-height: rem(30);
            font-family: 'ATC Overlook', sans-serif;
            color: $color__bright;
            display: block;
            text-align: left;
        }
        & a{
            font-weight: 300;
            font-size: rem(14);
            line-height: rem(30);
            color: $color__bright;
            font-family: 'ATC Overlook', sans-serif;
            &:hover{
                color: $color__secondary;
            }
        }
    }

}


.footer_copy_row{
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(35);
    order: 3;
    & .copyright{
        font-weight: 300;
        font-size: rem(12);
        line-height: rem(30);
        color: $color__bright;
        font-family: 'ATC Overlook', sans-serif;
        margin-right: rem(0);
    }
    & .footer__nav.footer__nav--right{
        float: none;
        width: 100%;
        text-align: left !important;
        &:nth-child(2){
            text-align: left !important;
        }
        & li{
            margin: rem(7.5) rem(5);
            & a{
                font-weight: 300;
                font-size: rem(12);
                line-height: rem(30);
                color: $color__bright;
                font-family: 'ATC Overlook', sans-serif;
                &:hover{
                    color: $color__secondary;
                }
            }
        }
    }
}

.section_dark_bg{
    background-color: #f8f8f8;
    padding-top: 1.5rem;
    position: relative;
    padding-bottom: 1rem
    // &:last-child{
    //     margin-bottom: rem(20);
    // }
}
.section_light_bg{
    padding-top: 1.5rem;
    background-color: #fff;
    position: relative;
    padding-bottom: 1rem
    // &:last-child{
    //     margin-bottom: rem(20);
    // }
}

._kw_programmstruktur{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-left: grid-padding(medium);
    padding-right: grid-padding(medium);
}
//.left_progranstruktur {
    // display: flex;
    // flex-wrap: wrap;
    // min-width: 58%;
    // max-width: 58%;
    // justify-content: center;
    // align-content: center;
    // margin-right: 1.9356%;
//}
& .progr_strukt_left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1.9356%;
    color: $color__primary;
    & a{
        margin: 0;
        padding: 0;
        & .icon{
            margin-top: 5px;
            color: $color__primary;
        }
        &#forward_week{
            background-color: $color__accent;
            border-color: $color__accent;
        }
        &#back_week{
            background-color: $color__accent;
            border-color: $color__accent;
        }
    }
}
& a.pdf_downloader {
    padding-left: 0px !important;
    background-color: $color__accent;
    margin-right: 1.9356%;
    line-height: 42px;
    text-align: center;
    // &:last-child{
    //     margin-right: 0;;
    // }
}
.middle_programmstruktur {
    float: right;
    margin-right: 1.9356%;
    & .programmStrukturLegend {
        padding: 0 !important;
        & li{
            display: inline-block;
            width: 100%;
        }
    }
}
.right_programmstruktur {
    float: right;
    & .programmStrukturLegend {
        padding: 0 !important;
        & li{
            display: inline-block;
            width: 100%;
        }
    }
}


.progr_strukt_left,
a.pdf_downloader,
.middle_programmstruktur,
.right_programmstruktur{
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;;
}
.filter-wrapper.js-filterwrapper.bg--accent:nth-child(1){
    background: $color__bright;
}
.hideall{
    display: none;
}
.agb_pdf_title{
    display: flex;
    margin-bottom: 20px;
    color: $color__primary;
}
.agb_content{
    color: #1D1D1A;
}
.agb_item{
    height: auto;
    flex: 0 0 98%;
    margin: 1% !important;
    background: #fff;
    padding: 0 20px 20px 20px;
}
.kontakt_form_custom{
    & .filter-wrapper__row{
        display: flex;
        flex-wrap: wrap;
        & .filter-wrapper__col {
            min-width: 100%;
            max-width: 100%;
            border: 0;
            margin-left: auto;
            margin-right: auto;
            // &:nth-child(2n){
            //     margin-right: 0;
            // }

        }
        & .message_box textarea{
            min-height: 158px;
            max-height: 158px;;
            margin-bottom: 20px;
        }
        & #sendContact{
            background: transparent;
            color: $color__primary;
            border: 1px solid $color__primary;
            margin-top: 1.2rem;
            line-height: 1.86;
        }
    }

    & .filter-wrapper__row:nth-child(3){
       & .filter-wrapper__col{
          min-width: 100%;
          max-width: 100%;
        }
       & .filter-wrapper__col:nth-child(2){
          text-align: left;
          margin-right: auto;
          margin-left: 0;
        }
    }
    & .g-recaptcha{
        transform: scale(0.9);
        transform-origin: top left;
    }
}
.custom_flex{
    display: flex;
    align-items: center;
}
.filter-wrapper__col.programmstruktur{
    & [type="radio"] + label,
      [type="checkbox"] + label{
        font-size: 1.125rem;
        font-weight: 400;
        color: $color__primary;
        padding: 0.5625rem 0.5625rem 0.5625rem 1.725rem;
        & i{
            top: 0.7875rem;
            left: 0;
        }
    }
    & [type="checkbox"]:checked + label i:before,
      [type="checkbox"]:checked:hover + label i:before {
        color: #EBEBEB;
        border-color: #EBEBEB;
        background-color: #EBEBEB;
        border: 0;
      }
    & [type="radio"]:not(:checked) + label:hover i:before,
      [type="checkbox"]:not(:checked) + label:hover i:before{
          border-color: #EBEBEB;

      }
    & [type="checkbox"]:checked + label i:after,
      [type="checkbox"]:checked:hover + label i:after {
        display: inline-block;
        background-color: #707070;
      }
    & [type="checkbox"] + label i:before{
        background-color: #f9f9f9;
        border-radius: 3px;
    }
    & [type="checkbox"] + label i:after {
        width: 0.925rem;
        height: 0.925rem;
        left: rem(2.5);
        top: rem(2.5);
        border-radius: 3px;
    }
}
.kontakt_class{
    & .teaser--tpl1:nth-child(2){
        & .teaser__content {
            padding-left: rem(0);
        }
    }
    & .teaser__content {
        p{
            margin-bottom: 0;
        }
    }
    & h2{
        margin-top: 0;;
    }
}
.caption p{
    font-size: 1rem;
    padding: 0 0rem;
}
.caption h1 {
    font-size: 2.3rem;
    padding: 0 0rem;
}
.show_more_teaser {
    cursor: pointer;
    text-align: center;
    // &:hover{
    //     background-color: $color__accent--medium;
    // }
}

.invisible{
    visibility: hidden;
    opacity: 0;
    display: none;
}


.only_files{
    padding-left: 19%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    /* justify-content: space-between; */
    // gap: 1%;
}
.light_bg{
    background: transparent;
}

// .js-offcanvas {
//     & .offcanvas__content {
//         display: none !important;
//     }
//     &.offcanvas--open{
//         & .offcanvas__content {
//             display: flex !important;
//         }
//     }
// }


.my_account_title{
    text-transform: uppercase;;
    font-size: 0.8rem;;
    font-weight: 400;;
    padding-left: 12px;
}
#searchPearson{
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2247%22%20height%3D%2264%22%20viewBox%3D%220%200%2047%2064%22%20fill%3D%22%23fff%22%3E%0A%3Cpath%20d%3D%22M18.75%2042.273l-2.591-2.568%2013.75-13.75-13.75-13.75%202.591-2.568%2016.318%2016.318z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E);
    // background-image: none;
    background-repeat: no-repeat;
    text-align: left;
    background-position: right 11px;
    background-size: 28px 29px;
    border-color: $color__primary;
    background-color: $color__primary;
}
.hide_mehr.teaser__content {
    padding-bottom: 0rem;
}
.tab_title_cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // gap: 1.9365%;
    & .one_tab_title{
        max-width: 32%;
        flex: 1 0 auto;
        background-color: $color__bright;
        border-color: $color__primary;
        // width: 32%;
        border-color: #009ba7;
        border-width: 1px;
        border-style: solid;
        padding: 7px 0;
        text-align: center;
        font-weight: 300;
        cursor: pointer;
        margin-right: 1.9325%;;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child{
            margin-right: 0;
        }
        &.selected{
            background-color: $color__primary;
            color: $color__bright;
        }
        &:hover{
            background-color: $color__primary;
            color: $color__bright;
        }
    }
}
.accordion_custom{
    .panel.panel-default{
        overflow: auto;
        padding: rem(10) 0;
        .force-overflow{
            min-width: 840px;
        }
    }
}

.one_page_tab {
    .section_light_bg:not(:first-child){
        padding-top:   0;
    }
    .section_dark_bg:not(:first-child){
        padding-top: 0;
    }
    // .content__container{
    //     padding: 0;;
    // }
}

.teaser-group.tags_group{
    h1{
        padding-left: 0.625rem;
    }
}
td.whitebg {
    background: #fff;
    border: 0;
}

.mediaguide {

    overflow-x: auto;
    width: 99.9%;
    border-collapse: collapse;
    background: #fff;
    tbody {
        tr {
            -webkit-transition: all 0.1s linear;
            transition: all 0.1s linear;
            background-color: #fff;
            color: #282828;
            font-weight: 400;
        }
        tr:nth-child(odd) {
            background: rgba(0, 155, 167, 0.15);
        }
    }

}

.content__container.tab_dark_mf{
    max-width: 100%;
    background-color: #f8f8f8;
    &.tab_title_cont{
        justify-content: center;
        gap: 0;
    }
    & .one_tab_title {
        max-width: 49%;
        margin-right: 1.0325rem;

        &:nth-child(2){
            margin-right: 0px;
        }
    }
}


footer{
    margin-top: 5rem;;
}



.member-dashboard{
    padding-top: 100px;
    & .brand-overview__brands{
        display: flex;
        flex-wrap: wrap;
        & .aktuelle_termine{
            flex: 1 auto;
        }
        & .brand-overview__inner{
            flex: 1 auto;
        }

        & .aktuelle_termine{
            order: 1;
            min-width: 65%;
            max-width: 65%;
            & p{
                max-width: 207px;
                margin: 0 ;
                padding-left: 9px;;
            }
        }
        & .view_togglers{
            flex: 1 auto;
            padding: 0 0;
            order: 2;
            min-width: 35%;
            max-width: 35%;
        }
        & .brand-overview__inner{
            order: 3;
            min-width: 100%;
            max-width: 100%;

            & a.brand-overview__brand-link {
                background: #fff;
                border: 1px solid #C7C7C7;
                align-items: flex-end;
                padding-bottom: 10px;
                padding-top: 20px;
                & .dash_items img{
                    max-height: 65px;
                    max-width: 62px;
                    width: 100%;;
                }
                &:hover::after{
                    top: 0;
                }
            }
            &.list_view{
                & .digital_class{
                    height: auto !important;
                    min-height: 10px;;

                    & .dash_items {
                        display: flex !important;
                        align-items: center;
                        height: 60px;
                        img{
                            position: absolute;
                            max-width: 35px;
                            max-height: 35px;;
                            left: 18px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                & #account_items li a,
                #account_items2 li a {
                    padding: 0
                }
                & #account_items li a span,
                #account_items2 li a span{
                    text-align: left !important;
                    line-height: 1.3 !important;;
                    min-height: 10px;
                    width: 100% !important;
                    padding: 0 10px 0 75px;;
                    font-size: 1rem;
                }
            }
        }
    }
    .brand-overview__item-wrapper{
        margin-top: 0;
    }
    & #account_items .brand-overview__item.digital_class,
    #account_items2 .brand-overview__item.digital_class{
        width: 32%;
        height: auto;
        min-height: 9.3rem;
        margin-bottom: 25px !important;
        &:nth-child(3n+3){
            margin-right: 0;;
        }
        & span{
            font-size: 0.8rem;
            min-height: 45px;;
        }
    }
}



.teaser .read_more_cont.link_to_btn  {
    color: $color__bright;
    letter-spacing: normal;
    bottom: 0;
}
.teaser__content{
  padding-bottom: 4.3rem;
}


#flipdown{
    margin: 0 auto;
}

.intrinsic--2x1 {
    padding-top: 50% !important;
    height: auto;
}
.intrinsic--2x1 img {
    max-height: none !important;
    min-height: auto !important;
}


//######################################################################################################################################################################################################################
//**********************************************************************************************************************************************************************************************************************
//######################################################################################################################################################################################################################
//----------media query ------------------------------------------------------------------------------------------------------
@include mq(medium){

    .newsletter_promo{
        & .close_overlay_toolbox{
            right: -10px;
        }
    }

    .special_menu_adm{
        background: $color__secondary;
        display: inline-block;
        margin: 0;
        padding: 4px 0;
        list-style: none;
        position: fixed;
        top: 20%;
        right: 0;
        z-index: 212;
    }
    // .my_account_title{
    //     display: none;
    // }
    #staff_members h3 {
        font-size: 1.4rem;

    }
    .only_files{
        padding-left: 20%;
    }

    .left_col_footer{
        order: 1;
    }

    .right_col_footer{
        min-width: 100%;
        max-width: 100%;
        margin-top: 0;
        order: 2;
        & .footer_menu_navi{
            min-width: 46%;
            max-width: 46%;
            order: 1;
        }
        & .contact_info_footer{
            min-width: 33%;
            max-width: 33%;
            order: 2;
        }
        & .footer__nav.footer__nav--right{
            min-width: 20%;
            max-width: 20%;
            order: 3;
        }

    }

    .footer_copy_row{
        min-width: 100%;
        max-width: 100%;
        order: 3;
        & .copyright{
            margin-right: rem(20);
        }
        & .footer__nav.footer__nav--right{
            float: none;
            width: 50%;
            text-align: left !important;
            &:nth-child(2){
                text-align: right !important;
            }
            & li{
                margin: 0 rem(5);
                & a{
                    font-weight: 300;
                    font-size: rem(12);
                    line-height: rem(30);
                    color: $color__bright;
                    font-family: 'ATC Overlook', sans-serif;
                    &:hover{
                        color: $color__secondary;
                    }
                }
            }
        }
    }

    .agb_item{
        height: auto;
        flex: 0 0 48%;
        margin: 1% !important;
        background: #fff;
        padding: 0 20px 20px 20px;
    }

    .kontakt_class{
        & .teaser--tpl1{
            width: 49%;
            &:nth-child(2){
                margin-right: 0;;
                & .teaser__content {
                    padding-left: rem(40);
                }
            }

        }
    }

    .list_view{
        & #account_items{
            & li.dashboard_special{
                display: inline-block;
                float: none;
                width: 100% !important;
            }
            & li.brand-overview__item-sizer{
                display: none;
            }
            & li{
                display: block;
                float: left;
                width: 49% !important;

            }
        }

        & #account_items2{
            & li.dashboard_special{
                display: inline-block;
                float: none;
                width: 100% !important;
            }
            & li.brand-overview__item-sizer{
                display: none;
            }
            & li{
                display: block;
                float: left;
                width: 49% !important;
            }
        }
    }
    .login-msn-or{
        &:before {
            width: 37%;
            content: '';
        }
        &:after{
            width: 37%;
            content: '';
        }
    }
    // VARIANTS
    .disclamer_angle{
        right: 0;
        left: auto;
        width: 70%;
        padding: 2rem !important;
    }
    #agreedisclamer{
        width: 80%;
    }
    .brand-overview{
        &--size-special{
            min-height: 14.7rem;
        }
    }

    .media_lexicon{
        width: 47.5%;
        min-width: 47.5%;
        margin: 0 1%;
        padding: 0;
        vertical-align: top;
        flex: 1;
    }
    .header__logo{
        right: 0;
    }
    .intrinsic__item_auto{
        width: 100%;
    }
    .hero_btn .icon{
        margin: .8rem auto;
    }
    .new_search{
        width: auto;
    }
    .search-portfolio{
        &__custom
        {
            & a
            {
                width: 20rem;
        }
        }
    }
    .caption h1{
        font-size: 2.3rem;
        padding: 0 0.625rem;
    }
    .caption h2{
        font-size: 1.25rem;
        padding: 0 0rem;
    }
    .caption p{
        font-size: 1rem;
        padding: 0 0rem;
    }
    .filter-wrapper__col:nth-child(3n){
        margin-right: 0;
    }
    .filter-wrapper__col{
        width: 32.04294%;
    }
    .filter-wrapper__col.extended{
        width: 49%;
        margin-right: 1.9356%;
    }
    .threefilters{
        & .filter-wrapper__col.extended{
            width: 32%;
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
    // .filter-wrapper__col.extended:nth-child(2n),
    // .filter-wrapper__col.extended:nth-child(4n){
    //     margin-right: 0;
    // }
    .filter-wrapper__col.extended:nth-child(3n+1){
        clear: none;
    }
    .filter-wrapper__col.extended:last-child{
        margin-right: 0;
    }
    .filter-wrapper__col.wizard_button{
        width: 130px;
    }
    .sc-content.tabs_description{
        width: 96%;
    margin-right: 2%;
    margin-left: 2%;
    }
    .angle-search{
        padding: 4rem 4rem;
    }
    .angle-contact{
        padding: 7rem 4rem;
    }
    .just_task{
        padding: 3rem 4rem;
    }
    // product header
    .show_description{
        margin-top: rem(20);
        &__actions{
            // width: 82%;
            width: 74%;
            margin-left: 18%;
        }
        &__image{
            float: left;
            width: 15%;
            margin-right: 1.5%;
            margin-left: 1.5%;
        }
        &__content{
            float: left;
            width: 74%;
            margin-left: 0;
            padding-left: 2%;
            &.template2 {
                display: inline-block;
                float: none;
                width: 61%;
            }
        }
    }
    .detailpage{
        .show_description{
            margin-top: rem(20);
            &__actions{
                width: 74%;
                margin-left: 18%;
            }
            &__image{
                margin-left: 5%;
            }
            &__content{
                width: 78%;
            }
        }
    }
    .teaser-group__wrapper.sidebar{
        display: inline-block;

        width: 39%;
    padding-top: 5rem;

        vertical-align: top;
    }
    h1.show_description__title{
        margin-top: 0;

        // color: $color__secondary;
    }
    .multiple_buttons{
        & .btn .icon{
            width: 2.125rem;
            height: 2.125rem;
        }
        & .btn{
            line-height: 1.36;
            text-align: left;
        }
        & span{
            font-size: 1.025rem;
        }
    }

    .multiple_buttons_agb{
        & .btn .icon{
            width: 2.125rem;
            height: 2.125rem;
        }
        & .btn{
            line-height: 1.36;
        }
        & .underline{
            width: auto;
        }
        & span{
            font-size: 1.025rem;
            margin-bottom: rem(10);
            padding-bottom: rem(2);
            border-bottom: rem(1) solid $color__primary;
            &:hover{
                border-bottom: rem(1) solid $anchor--color-hover;
            }
        }
    }
    // breadcrumb
    .custom_btn_circle{
        padding: .5875rem 0;
    }

    .main-nav__link{
        font-size: .9rem;
    }
    .filter-wrapper__col.publispot{
        width: 49%;
    }
    // .filter-wrapper__col.publispot:nth-child(2n),
    // .filter-wrapper__col.publispot:nth-child(4n){
    //     margin-right: 0;
    // }
    .filter-wrapper__col.publispot:nth-child(2n+1){
        clear: none;

        margin-right: 1.9356%;
    }
    .filter-wrapper__col.publispot:nth-child(3n+1){
        clear: none;
    }
    .filter-wrapper__col.publispot_button:nth-child(3n+1){
        clear: none;
    }
    .go4media{
        & .column{
            float: left;
            width: 50%;
        }
    }
    .gomedia_title{
        font-size: .9rem;
        width: 100%;
    }
    .gomedia_detail{
        font-size: .9rem;
        width: 100%;
        p{
                font-size: .9rem;
        }
    }
    .go4media{
        & .gomedia_detail p{
            font-size: .9rem;;
            padding-top: 0;
        }
    }
    .container_filters{
        float: left;
    }
    .container_filters{
        float: left;
        width: 84%;
        margin-right: 1.9356%;
    }
    .awards_container{
        float: left;
        width: 84%;
        margin-right: 1.9356%;
        & .award{
            width: 21.5%;
            margin: 0 1%;
        }
        & .dates{
            width: 23%;
        }
    }
    .publispot_button.button_ontop,
    .filter-wrapper__col.publispot_button{
        width: 14%;
        margin-top: rem(12);
        margin-right: 0;
        margin-bottom: rem(35);
    }
    .awards_container{
        min-height: 145px;
    }
    .filter-wrapper__col.publispot_button > .btn{
        text-align: center;
    }
    .filter-wrapper__col.publispot_button > .btn span{
        display: inline-block;

        padding-top: rem(0);
    }
    .multiple_buttons_agb{
        & .underline{
            width: auto;
        }
        & span{
            width: auto;
        }
    }
    .awards_container{
        min-height: 160px;
    }
    .awards_container .dates{
        margin: 0 1%;
    }
    .programatic_gray_icon_cont{
        width: 47.2%;
    }
    .website_logos{
        width: 17.3%;
    }
    .filter-wrapper__col.tv_filters{
        width: 32%;
    }
    .filter-wrapper__col.tv_filters:nth-child(3n){
        margin-right: 0;
    }
    .contact_container{
        float: left;

        width: 48%;
    margin: 0 1%;
    }
    #close_contact{
        left: rem(-39);
    }
    #close_search{
        left: rem(-39);
    }
    #close_contact .btn--circle{
        padding: .5875rem 0;
    }
    #account_items{
        & .icon{
            width: 5.75rem;
            height: 5.75rem;
        }
        & span{
            font-size: 1rem;
            font-weight: 700;
            display: block;
            padding-top: 1.5rem;
        }
        & .brand-overview__item.digital_class{
            width: 31.8%;
            height: auto;
            margin-bottom: 20px;
            // margin-left: auto;
            // margin-right: auto;
            // &:nth-child(2n){
            //     margin-right: 0;
            // }
            // &:nth-child(3n){
            //     margin-right: 0;;
            // }

        }
    }

     #account_items2{
        & .icon{
            width: 5.75rem;
            height: 5.75rem;
        }
        & span{
            font-size: 1rem;
            font-weight: 700;
            display: block;
            padding-top: 1.5rem;
        }
        & .brand-overview__item.digital_class{
            width: 31%;
            height: auto;
            // margin-left: auto;
            // margin-right: auto;
            // &:nth-child(2n){
            //     margin-right: 0;
            // }
            // &:nth-child(3n){
            //     margin-right: 0;;
            // }
        }
    }
    .input-addon2{
        float: left;
        width: 47%;
    }
    #staff_members, .staff_members{
        & .inside_info{
            font-size: 0;
            line-height: 0;
            display: inline-block;
            float: none;
            width: 32%;
            margin: 0 0 rem(20) 0;
            vertical-align: top;
        }
        // & h4{
        //     margin-bottom: 0;
        // }
        & .or_separator{
            top: 35%;
            left: 47%;
            display: none;
        }
        & .members-wrapper__col{
            float: left;
            width: 48.04294%;
            margin-right: 1.9356%;
            &:last-child{
                margin-right: 0;
            }
            // &:nth-child(3n){
                // margin-top: 2.5rem;
            // }
            // &:nth-child(4n){
                // margin-top: 2.5rem;
            // }
            & input:-ms-input-placeholder{
                color: $color__accent--medium !important;
            }
        }
    }
    .angle-contact{
        & form#submitnewsletter{
            & input{
                &#subEmail{
                    display: block;
                    width: 47%;
                }
                &.newsletter_comune{
                    display: inline-block;
                    width: 47%;
                }
            }
            & select{
                &.newsletter_comune{
                    display: inline-block;
                    width: 47%;
                }
            }
        }
    }
    .search-portfolio__custom .input-addon{
        display: flex;
    }
    .inside_info_half{
        width: 50%;
    }
    .shows_menu_in_program_content li{
        display: inline-block;
    }
    .shows_menu_in_program_content li:after{
        display: inline-block;

        content: '|';
    }
    .shows_menu_in_program_content li:last-child:after{
        display: none;

        content: '';
    }
    .newsletter_cont{
        padding-top: 2rem;
    }

    .table_wrapper_spotradar{
        overflow: auto;

        min-width: auto;
    }
    .filter-wrapper__col.programmstruktur{
        width: 32%;
    }
   .progr_strukt_left,
   a.pdf_downloader,
   .middle_programmstruktur,
   .right_programmstruktur{
       min-width: 32%;
       max-width: 32%;
       margin-bottom: 20px;;
   }
   a.pdf_downloader:nth-child(3n){
       margin-right: 0;;
   }
    .task_sm_contailer{
        float: left;

        width: 49%;
    }
    #file-list{
        float: right;

        width: 49%;
    }
    .kontakt_form_custom{
        & .filter-wrapper__row{
            display: flex;
            flex-wrap: wrap;
            & .filter-wrapper__col {
                min-width: 100%;
                max-width: 100%;
                border: 0;
                margin-left: auto;
                margin-right: auto;
                // &:nth-child(2n){
                //     margin-right: 0;
                // }
            }
        }
    }
    .member-dashboard{
        & .brand-overview__brands{
            display: flex;
            flex-wrap: wrap;
            & .aktuelle_termine{
                flex: 1 auto;
            }
            & .brand-overview__inner{
                flex: 1 auto;
            }

            & .aktuelle_termine{
                order: 1;
                min-width: 65%;
                max-width: 65%;
                & p{
                    max-width: 207px;
                    margin: 0 ;
                    padding-left: 9px;;
                }
            }
            & .view_togglers{
                flex: 1 auto;
                padding: 0 0;
                order: 2;
                min-width: 35%;
                max-width: 35%;
            }
            & .brand-overview__inner{
                order: 3;
                min-width: 100%;
                max-width: 100%;

                & a.brand-overview__brand-link {
                    background: #fff;
                    border: 1px solid #C7C7C7;
                    align-items: flex-end;
                    padding-bottom: 10px;
                    padding-top: 20px;
                    & .dash_items img{
                        max-height: 65px;
                        max-width: 62px;
                        width: 100%;;
                    }
                }
            }
        }
        .brand-overview__item-wrapper{
            margin-top: 0;
        }
        & #account_items .brand-overview__item.digital_class,
        #account_items2 .brand-overview__item.digital_class{
            width: 32%;
            height: auto;
            min-height: 9.3rem;
            &:nth-child(3n+3){
                margin-right: 0;;
            }
            & span{
                font-size: 1rem;
                min-height: 45px;;
            }
        }
    }
}

@include mq(large){

    form {
        position: relative;
        margin: 0 0rem;
    }
    .content__container.tab_dark_mf{
        max-width: 100%;
        background-color: #f8f8f8;
        &.tab_title_cont{
            justify-content: center;
            gap: 1.9325%;
        }
        & .one_tab_title {
            max-width: 49%;

            &:nth-child(2){
                margin-right: 374px;
            }
        }
    }

    select.meta-select__select {
        background-color: transparent;
        background-image: none;
        padding-left: 0;
        background-repeat: no-repeat;
        background-position-x: auto;
    }
    .header__meta select.meta-select__select:focus,
    .header__meta select.meta-select__select:hover{
        background-color: transparent;
        background-image: none;
    }
    #staff_members h3 {
        font-size: rem(24);

    }
    .left_col_footer{
        min-width: 40%;
        max-width: 40%;
        & .for_logo{
            & img{
                width: 100%;
                max-width: 205px;;
            }
        }
        & .footer_moto{
            color: $color__bright;
            font-size: 16px;
            margin-top: rem(30);
        }
        & .footer__nav {
            margin-top: 40px;

        }
    }
    .right_col_footer{
        min-width: 60%;
        max-width: 60%;
        margin-top: 0;
        & .footer_menu_navi{
            min-width: 46%;
            max-width: 46%;
        }
        & .contact_info_footer{
            min-width: 33%;
            max-width: 33%;
        }
        & .footer__nav.footer__nav--right{
            min-width: 20%;
            max-width: 20%;
        }

    }

    .invisible{
        visibility: hidden;
        opacity: 0;
        display: block;
    }
    .kontakt_class{
        & .teaser--tpl1{
            width: 49%;
            &:nth-child(2){
                margin-right: 0;;
                & .teaser__content {
                    padding-left: rem(100);
                }
            }
        }
    }
    .agb_item{
        height: auto;
        flex: 0 0 31%;
        margin: 1% !important;
        background: #fff;
        padding: 0 20px 20px 20px;
    }
    .filter-wrapper__col.programmstruktur {
        width: 32%;
    }
    // .filter-wrapper__col:nth-child(3n) {
    //     margin-right: 0;
    // }
    .progr_strukt_left,
    a.pdf_downloader,
    .middle_programmstruktur,
    .right_programmstruktur{
        width: 32%;
    }
    a.pdf_downloader:nth-child(3n) {
        margin-right: 0;
    }

    #account_items{
        & .icon{
            width: 5.75rem;
            height: 5.75rem;
        }
        & span{
            font-size: 1rem;
            font-weight: 700;
            display: block;
            padding-top: 1.5rem;
        }
        & .brand-overview__item.digital_class{
            width: 18%;
            height: auto;
            // margin-right: 1.9536%;
            // margin-bottom: 20px;;

            // margin: 0 .9812% 1rem 0.981%;
            // margin-left: auto;
            // margin-right: auto;
            // &:nth-child(2n){
            //     margin-right: 0;
            // }
            // &:nth-child(3n){
            //     margin-right: 1.9356%;
            // }

            // &:nth-child(5n){
            //     margin-right: 0;
            // }
        }
    }

    #account_items2{
        & .icon{
            width: 5.75rem;
            height: 5.75rem;
        }
        & span{
            font-size: 1rem;
            font-weight: 700;
            display: block;
            padding-top: 1.5rem;
        }
        & .brand-overview__item.digital_class{
            width: 18%;
            margin-right: 1.9356%;
            height: auto;
            // margin: 0 .9812% 1rem 0.981%;
            // margin-left: auto;
            // margin-right: auto;
            // &:nth-child(2n){
            //     margin-right: 0;
            // }
            // &:nth-child(3n){
            //     margin-right:  1.9356%;
            // }
            // &:nth-child(5n){
            //     margin-right: 0;
            // }
        }
    }



    ._kw_programmstruktur{
        padding-left: 0;
        padding-right: 0;
    }
    // .progr_strukt_left{
    //     min-width: 18%;
    //     max-width: 18%;
    // }
    // a.pdf_downloader {
    //     min-width: 18%;
    //     max-width: 18%;
    //     &:nth-child(3n) {
    //         margin-right: 1.9356%;
    //     }
    // }
    // .middle_programmstruktur {
    //     min-width: 18%;
    //     max-width: 18%;
    // }
    // .right_programmstruktur {
    //     min-width: 18%;
    //     max-width: 18%;
    // }



    .special_menu{
        display: block;
    }
    .header_content .special_menu_adm{
        display: none;
    }

    .container_promo{
        & .toolbox_promo{
            width: 50%;
            max-width: 640px;
        }
    }

    .twocols{
        .twothirds{
            float: left;
        width: 65%;
    }
        .onethird{
            float: right;
        width: 33%;
        h1{
                margin-top: 0;
        }
        }

    }
    .intern_container{
        & .non_intern{
            width: 49%;
            min-width: 49%;
            max-width: 49%;
        }
        & .intern{
            width: 49%;
            min-width: 49%;
            max-width: 49%;
        }
    }

    .login-msn-or{
        &:before{
            width: 40%;
            content: '';
        }
        &:after{
            width: 40%;
            content: '';
        }
    }
    #dropzone_asana{
        display: block;
        margin-top: 20px;
        padding: 60px;
        text-align: center;
        border: dashed  1px #000;
    }
    #dropzone_asana.drag{
        border: dashed  1px #000;
    }
    #file-list:empty:before{
        font-size: 1rem;
        display: block;
        content: 'Upload using the file input, drag and drop zone or printscreen and CTRL+V(not supported in safari)';
    }
    .teaser-group__wrapper{
        &.custom_brands
        {
            & .teaser{
                width: 33%;
                border: 0;
                background: transparent;
                & h1
                    {
                    font-size: 20px;
                    font-weight: 300;
                    color: $color__bright;
                }
            }
        }
    }
    #staff_members{
        & .or_separator{
            position: absolute;
            top: 45%;
            left: 38%;
            display: none;
        }
    }
    .mediaguide .flex_container{
        display: flex;
        flex-direction: row;
    }
    #loginForm,
    #backstageUpdatePassword{
        width: 50%;
        padding: 0 rem(15);
    }
    .extra_menu{
        display: none;
        visibility: hidden;
    }
    .meta-nav_dashboard{
        position: relative;
        left: auto;
        margin-top: 0;
    }
    .meta-nav_dashboard #cool_menu{
        position: absolute;
        z-index: 31;
        z-index: 232;
        right: 0;
        left: auto;
        display: block;
        visibility: hidden; /* hides sub-menu */
        width: rem(200);
        margin: 0;
        padding: 8px;
        list-style: none;
        transition: all .3s ease-in-out 0s, visibility 0s linear .3s, z-index 0s linear .01s;
        transform: translateY(-1em);
        text-align: left;
        opacity: 0;
        background: $color__secondary;
    }

    .meta-nav_dashboard #cool_menu:before,
    .meta-nav_dashboard #cool_menu:after{
        position: absolute;
        z-index: 1;
        left: 0;
        display: block;
        overflow: hidden;
        width: 100%;
        height: .6125rem;
        content: '';
        background: $color__secondary;
        backface-visibility: hidden;
    }

    // .meta-nav_dashboard #cool_menu:before{
    //     top: -.025rem;
    //     transform: skewY(2deg);
    //     transform-origin: right bottom;
    // }

    // .meta-nav_dashboard #cool_menu:after{
    //     bottom: -.125rem;
    //     transform: skewY(-2deg);
    //     transform-origin: right top;
    // }


    .meta-nav_dashboard:hover #cool_menu{
        visibility: visible; /* shows sub-menu */
        transition-delay: 0s, 0s, .3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
        transform: translateY(0%);
        opacity: 1;
    }
    .meta-nav_dashboard #cool_menu li a{
        font-size: 1rem;
        color: $color__bright;
    }
    .meta-nav_dashboard #cool_menu li a:hover{
        color: $color__accent--medium;
    }
    .meta-nav_dashboard #cool_menu li .icon{
        width: 1.575rem;
        height: 1.575rem;
    }
    //
    .header__nav{
        position: relative;
        z-index: 200;
        left: auto;
        margin-top: 0;

    }


    .main-nav__item .cool_submenu{
        position: absolute;
        z-index: 31;
        right: auto;
        left: 0;
        display: block;
        visibility: hidden; /* hides sub-menu */
        width: rem(220);
        margin: -14px 0 0 0;
        padding: 0;
        list-style: none;
        transition: all .3s ease-in-out 0s, visibility 0s linear .3s, z-index 0s linear .01s;
        // transform: translate3d(0, -8px, 0) rotateX(-8deg);
        // transform: rotateY(5deg);
        text-align: left;
        opacity: 0;
        background:#eae7e5;
        backface-visibility: visible;
    }
    .main-nav__item .cool_submenu li{
        margin: 0;
        padding: rem(8) rem(0);
    }
    .main-nav__item:hover .cool_submenu{
        visibility: visible; /* shows sub-menu */
        transition-delay: 0s, 0s, .3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
        opacity: 1;
        backface-visibility: visible;
    }

    .main-nav__item .cool_submenu li a{
        font-size: 1rem;
        display: block;
        transition: color .15s linear;
        color: $color__dark;
        font-weight: 600;;
    }
    .main-nav__item .cool_submenu li a:hover,
    .main-nav__item .cool_submenu li a.cool_sticky--active{
        color: $color__dark;
    }
    .main-nav__item .cool_submenu li .icon{
        width: 1.575rem;
        height: 1.575rem;
        margin-right: 10px;
    }

    .show_description{
        &__content {
            &.template2{
                display: inline-block;
                float: none;

                width: 72%;
            }
        }
    }
    .teaser-group__wrapper.sidebar{
        display: inline-block;
        width: 28%;
        padding-top: 2rem;
        vertical-align: top;
    }
    #staff_members .members-wrapper__col:nth-child(3n),
    #staff_members .members-wrapper__col:nth-child(4n){
        margin-top: 0;
    }
    .sub-nav__link{
        font-size: 1.1rem;
    }
    .header__logo{
        width: 148px;
        display: block;
        margin-left: 50px;
    }
    .caption{
        width: 100%;
        transform: translate(0%, 0%);
        // animation: fadein 1.5s;
        text-align: left;
        background: $color__bright;
        margin-bottom: rem(50);
    }
    @keyframes fadein{
        from{transform: translate(0%, 10%);opacity: 0;}
        to{transform: translate(0%, -10%); opacity: 1;}
    }
    .angle-contact{
        & form{
            width: 50%;
        }
        & form#taskForm{
                width: 80%;
        }
    }
    #close_contact{
        .btn--circle{
            padding: .5875rem 0;
        }
    }
    .angle-search{
        padding: 14rem 8rem;
    }
    .angle-contact{
        padding: 7rem 8rem;
    }
    .just_task{
        padding: 3rem 8rem;
    }
    // .meta-nav__link span{
    //     display: none;
    // }
    .meta_select-languages{
        width: 2.2rem;
    }
    .main-nav__link.fr{
        font-size: .75em;
    }
    // .main-nav__link{
    //     padding: 1.725rem 0.5625rem;
    // }

    .button__container{
        margin-right: 2%;
        & .btn {
            line-height: 1.36;
            margin-bottom: 0rem;
        }
    }
    .breadcrumb{
        margin-top: 0 !important;
    }

    .filter-wrapper__col:nth-child(3n){
        margin-right: 0%;
    }
    .breadcrumb{
        float: left;
        margin: rem(6) 0 !important;
    }
    .button__container{
        float: right;
        margin-right: 2%;
    }
    .footer__bar .footer__inner{
        padding: 3.75rem .625rem .625rem;
    }
    .filter-wrapper__col{
        display: block;
        float: left;
        width: 18.45152%;
        margin-right: 1.9356%;
    }
    .filter-wrapper__col.extended{
        width: 23.54294%;
    }
    .filter-wrapper__col.extended{
        width: 23.54294%;
    }
    .filter-wrapper__col.extended:nth-child(4n){
        margin-right: 0;
    }
    .filter-wrapper__col.publispot:nth-child(4n){
        margin-right: 0;
    }
    .filter-wrapper__col.publispot_button > .btn{
        text-align: center;
    }
    .filter-wrapper__col.publispot_button > .btn span{
        display: inline-block;

        padding-top: rem(0);
    }
    .content_page_left{
        float: left;

        width: 24%;
    margin-right: 1%;
    }
    .content_page_right{
        float: left;

        width: 74%;
    margin-left: 1%;
    }
    .other_media--small{
        width: 47.5%;
    margin-right: 0 1%;
    }
    .filter-wrapper__col:nth-child(5n){
        margin-right: 0;
    }
    .awards_container{
        min-height: 160px;
    }
    .awards_container .dates .form--horizontal{
        margin: 0;
    }
    .footer__nav.footer__nav--right{
        float: right;
        text-align: right;
    }
    .tab__nav{
        max-width: 94.7%;
    }
    #staff_members{
        & .members-wrapper__col{
            float: left;
            width: 18.45152%;
            margin-right: 1.9356%;
            &:last-child{
                margin-right: 0;
            }
            &:nth-child(3n){
                margin-right: 1.9356%;
                // margin-left: 4%;
            }
            &:nth-child(3n+1) {
                clear: none;
            }
        }
    }
    .fivefilters{
        & .filter-wrapper__col{
            float: left;
            width: 18.45152% !important;
            margin-right: 1.9356% !important;
            &:last-child {
                margin-right: 0 !important;
            }
            &:nth-child(4n){
                margin-right: 1.9356%;
                margin-left: 0%;
            }
            &:nth-child(3n+1) {
                clear: none;
            }
        }
    }
    .threefilters{
        display: flex;
        /* gap: 1%; */
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        & .filter-wrapper__col{
            flex: 1 00 auto;
            width: 18.45152% !important;
            margin-right: 1.9356% !important;
            &:last-child {
                margin-right: 0 !important;
            }
            &:nth-child(3n){
                margin-right: 1.9356%;
                margin-left: 0%;
            }
            &:nth-child(3n+1) {
                clear: none;
            }
        }
    }
    .twofilters{
        display: flex;
        /* gap: 1%; */
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        & .filter-wrapper__col{
            flex: 1 00 auto;
            max-width: 32.25%;;
            width: 32.25% !important;
            margin-right: 1.9356% !important;
            &:last-child {
                margin-right: 0 !important;
            }
            &:nth-child(3n){
                margin-right: 1.9356%;
                margin-left: 0%;
            }
            &:nth-child(3n+1) {
                clear: none;
            }
        }
    }
    .filter-wrapper__col.wizard{
        width: calc(30% - 65px);
        &:nth-child(3n+1){
            width: calc(64% - 195px);
        }
    }
    .filter-wrapper__col.wizard_button{
        font-size: 1rem;

        display: block;
        float: left;
        clear: none;

        width: auto;
        width: 130px;
        margin-top: rem(11);
        margin-right: 0;
        & .btn {
            display: block;
        }
        &.resetBtn{
            margin-right: 1.9356%;
        }
    }
    .portfolio-intro__first.custom_search_info{
        width: rem(300);
        padding-left: 0.635rem;;
    }
    .resultats .portfolio-intro__content{
        width: calc(98% - 18.8rem);
        padding-right: 0.6325rem;
    }
    // .custom_search_header{
    //     width: calc(98% - 18.8rem);
    //     margin-left: calc(2% + 18.8rem);
    // }

    .search_result_custom{
        font-size: 3rem;
    }
    .angle-search.resultats{
        padding: 0rem;
    }
    .intrinsic--16x5{
        .caption{
            bottom: 5%;
        }
    }
    .angle-contact{
        & form#submitnewsletter {
            width: 100%;
            & .small{
                font-size: 1rem;
            }
        }
    }
    .table_wrapper_spotradar{
        overflow: auto;
        min-width: auto;
    }

    .filter-wrapper__radio_group{
        & [type='radio'] + label{
            padding: .5625rem .2rem .5625rem 1.625rem;
            & i{
                left: 0;
            }
        }
    }

    .filter-wrapper__col{
        position: relative;
    &.six_cols
        {
            width: 15.05152%;
    }
        &.six_cols:nth-child(6n)
        {
            clear: none;

            margin-right: 0;
    }
        &.six_cols:nth-child(5n)
        {
            clear: none;

            margin-right: 1.9356%;
    }
    }



    .toolbox_promo{
        position: absolute;
        z-index: 123123232;
        top: 44px;
        left: 20px;
        display: block;
        width: 260px;
        min-height: 60px;
        min-height: 50px;
        margin: 9px;
        padding: 10px;

        color: $color__accent--deep;
        border-radius: 3px;
        background-color: $color__bright;
        box-shadow: 0 0 10px #999;
    }

    .toolbox_promo::before{
        position: absolute;
        width: 0;
        height: 0;
        content: '';
        border: .8em solid transparent;
    }


    .toolbox_promo.bottom::before{
        top: -20px;
        right: 76%;

        border-bottom: 10px solid #fff;
    }

    @-moz-keyframes bounce{
        0%,
        20%,
        50%,
        80%,
        100%
        {
            -moz-transform: translateY(0);
             transform: translateY(0);
    }
        40%
        {
            -moz-transform: translateY(30px);
             transform: translateY(30px);
    }
        60%
        {
            -moz-transform: translateY(15px);
             transform: translateY(15px);
    }
    }
    @-webkit-keyframes bounce{
        0%,
        20%,
        50%,
        80%,
        100%
        {
            -webkit-transform: translateY(0);
                transform: translateY(0);
    }
        40%
        {
            -webkit-transform: translateY(30px);
                transform: translateY(30px);
    }
        60%
        {
            -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
    }
    @keyframes bounce{
        0%,
        20%,
        50%,
        80%,
        100%
        {
            -webkit-transform: translateY(0);
           -moz-transform: translateY(0);
            -ms-transform: translateY(0);
                transform: translateY(0);
    }
        40%
        {
            -webkit-transform: translateY(30px);
           -moz-transform: translateY(30px);
            -ms-transform: translateY(30px);
                transform: translateY(30px);
    }
        60%
        {
            -webkit-transform: translateY(15px);
           -moz-transform: translateY(15px);
            -ms-transform: translateY(15px);
                transform: translateY(15px);
    }
    }
    .bounce{
        -webkit-animation: enterFromTop 1s, bounce 2s 2s 2;
        -moz-animation: enterFromTop 1s, bounce 2s 2s 2;
        animation: enterFromTop 1s, bounce 2s 2s 2;

        animation-fill-mode: forwards;
    }
    @keyframes enterFromTop{
        0%{
            top: -115px;
        }
        100%{
            top: 44px;
        }
    }
    .kontakt_form_custom{
        & .filter-wrapper__row{
            display: flex;
            flex-wrap: wrap;
            & .filter-wrapper__col {
                min-width: 49%;
                max-width: 49%;
                border: 0;
                &.message_box{
                    min-width: 100%;
                    max-width: 100%;;
                    margin: 0;;
                }
                &:nth-child(2n){
                    margin-right: 0;
                }
            }
        }
    }
    .threefilters{
        display: flex;
        /* gap: 1%; */
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        & .filter-wrapper__col{
            min-width: 32%;
            max-width: 32%;
            flex: 1 00 auto;
            width: 32% !important;
            margin-right: 1.9356% !important;
            &:last-child {
                margin-right: 0 !important;
            }
            &:nth-child(3n){
                margin-right: 1.9356%;
                margin-left: 0%;
            }
            &:nth-child(3n+1) {
                clear: none;
            }
        }
    }
    .member-dashboard{
        & .brand-overview__brands{
            display: flex;
            flex-wrap: wrap;
            & .aktuelle_termine{
                flex: 1 auto;
            }
            & .brand-overview__inner{
                flex: 1 auto;
            }
            & .aktuelle_termine{
                order: 1;
                min-width: 65%;
                max-width: 65%;
                & p{
                    max-width: 207px;
                    margin: 0 ;
                    padding-left: 0px;;
                }
            }
            & .view_togglers{
                flex: 1 auto;
                padding: 0 0;
                order: 2;
                min-width: 35%;
                max-width: 35%;
            }
            & .brand-overview__inner{
                order: 3;
                min-width: 100%;
                max-width: 100%;
                // & .brand-overview__item.digital_class {
                //     width: 48%;
                //     height: auto;

                // }
                & a.brand-overview__brand-link {

                    padding-bottom: 10px;
                    padding-top: 30px;
                    & .dash_items img{
                        max-height: 65px;
                        max-width: 62px;
                        width: 100%;;
                    }
                }
            }
        }
        & #account_items .brand-overview__item.digital_class{
            width: 32%;
            height: auto;
            min-height: 12rem;
            &:nth-child(3n+3){
                margin-right: 0;;
            }
            & span{
                font-size: 1rem;
                min-height: 45px;;
            }
        }
    &.list_view{
            & .digital_class{
                height: auto !important;
                min-height: 10px;;

                & .dash_items {
                    display: flex !important;
                    align-items: center;
                    height: 88px;
                    & img{
                        position: absolute;
                        max-width: 55px;
                        max-height: 55px;;
                        left: 18px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    & span{
                        text-align: center !important;
                        line-height: 1.3 !important;;
                        min-height: 10px;
                        width: 100% !important;
                        padding: 0 10px 0 75px;;
                    }
                }
                & a {
                    padding: 0
                }
            }
            & #account_items li a,
            #account_items2 li a{
                padding: 0
            }
            & #account_items li a span,
            #account_items2 li a span{
                text-align: center !important;
                line-height: 1.3 !important;;
                min-height: 10px;
                width: 100% !important;
                padding: 0 10px 0 75px;;
            }
            & #account_items .brand-overview__item.digital_class span,
            #account_items2 .brand-overview__item.digital_class span{
                text-align: center !important;
                line-height: 1.3 !important;;
                min-height: 10px;
                width: 100% !important;
                padding: 0 10px 0 75px;;
            }
            .list_view #account_items li a .dash_items,
            .list_view #account_items2 li a .dash_items{
                display: flex !important;
                align-items: center;
                height: 88px;
            }
        }
    }
    .member-dashboard .brand-overview__brands .list_view .dash_items img{
        position: absolute;
        max-width: 55px;
        max-height: 55px;;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@include mq(inter){


    select.meta-select__select {
        font-size: 1rem;
    }
    .my_account_title{
        text-transform: uppercase;;
        font-size: 1rem;;
        font-weight: 400;;
        padding-left: 0;
    }
    .filter-wrapper__col.programmstruktur {
        min-width: 32%;
        max-width: 32%;
    }
    .filter-wrapper__col:nth-child(3n) {
        margin-right: 0;
    }
    .progr_strukt_left, a.pdf_downloader, .middle_programmstruktur, .right_programmstruktur{
        min-width: 32%;
        max-width: 32%;
    }
    a.pdf_downloader:nth-child(3n) {
        margin-right: 0;
    }
    .login-msn-or{
        &:before{
            width: 42.5%;
            content: '';
        }
        &:after{
            width: 42.5%;
            content: '';
        }
    }
    .main-nav__link{
        font-size: 1rem;
    }
    .main-nav__link.fr{
        font-size: .9em;
    }
    h1.show_description__title{
        font-size: 1.875rem;
    }
    .container_filters,
    .awards_container{
        width: 81%;
    }
    .publispot_button.button_ontop,
    .filter-wrapper__col.publispot_button{
        width: 17%;
    }
    .filter-wrapper__col.publispot_button > .btn{
        text-align: center;
    }
    .filter-wrapper__col.publispot_button > .btn span{
        display: inline-block;

        padding-top: rem(15);
    }
    .other_media--small{
        width: 22.6%;
    margin-right: 0 1%;
    }
    .programatic_gray_icon_cont{
        width: 30.5%;
    }
    .angle-contact{
        & form#submitnewsletter{
            & input  {
                &#subEmail{
                    display: block;
                    width: 34%;
                }
                &.newsletter_comune{
                    display: inline-block;
                    width: 34%;
                }
            }
            & select{
                &.newsletter_comune{
                    display: inline-block;
                    width: 34%;
                }
            }
        }
    }
    .member-dashboard{
        & .brand-overview__brands{
            display: flex;
            flex-wrap: nowrap;
            & .aktuelle_termine{
                flex: 1 auto;
                order: 1;
            }
            & .brand-overview__inner{
                flex: 1 auto;
                order: 2;
            }
            & .view_togglers{
                flex: 1 auto;
                padding: 0 3%;;
                order: 3;
                margin-top: -60px;;
            }
            & .aktuelle_termine, .view_togglers{

                min-width: 220px;
                max-width: 13%;
                & p{
                    max-width: 207px;
                    margin: 0 auto;
                }
            }
            & .brand-overview__inner{
                min-width: calc(100% - 440px);
                max-width: 74%;
                & .brand-overview__item.digital_class {
                    width: 23%;
                    height: auto;
                }
                & a.brand-overview__brand-link {
                    padding-bottom: 10px;
                    padding-top: 30px;
                    & .dash_items img{
                        max-height: 65px;
                        max-width: 62px;
                        width: 100%;;
                    }
                }
            }
        }
        & #account_items .brand-overview__item.digital_class{
            width: 31%;
            height: auto;
            & span{
                min-height: 68px;;
            }
        }
        &.list_view{
            & .digital_class{
                height: auto !important;
                min-height: 10px;;

                & .dash_items {
                    display: flex !important;
                    align-items: center;
                    height: 88px;
                    & img{
                        position: absolute;
                        max-width: 55px;
                        max-height: 55px;;
                        left: 18px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    & span{
                        text-align: center !important;
                        line-height: 1.3 !important;;
                        min-height: 10px;
                        width: 100% !important;
                        padding: 0 10px 0 75px;;
                    }
                }
                & a {
                    padding: 0
                }
            }
            & #account_items li a,
            #account_items2 li a {
                padding: 0
            }
            & #account_items li a span,
            #account_items2 li a span{
                text-align: center !important;
                line-height: 1.3 !important;;
                min-height: 10px;
                width: 100% !important;
                padding: 0 10px 0 75px;;
            }
            & #account_items .brand-overview__item.digital_class span ,
            #account_items2 .brand-overview__item.digital_class span {
                text-align: center !important;
                line-height: 1.3 !important;;
                min-height: 10px;
                width: 100% !important;
                padding: 0 10px 0 75px;;
            }
            .list_view #account_items li a .dash_items,
            .list_view #account_items2 li a .dash_items {
                display: flex !important;
                align-items: center;
                height: 88px;
            }
        }
    }
    .member-dashboard .brand-overview__brands .list_view .dash_items img{
        position: absolute;
        max-width: 55px;
        max-height: 55px;;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@include mq(xlarge){

    .kontakt_form_custom{

        & .filter-wrapper__row:nth-child(3){
           & .filter-wrapper__col:nth-child(1){
              min-width: 49%;
              max-width: 49%;
            }
           & .filter-wrapper__col:nth-child(2){
              text-align: left;
              margin-right: auto;
              margin-left: 0;
              min-width: 49%;
              max-width: 49%;
            }
        }
        & .g-recaptcha{
            transform: scale(0.9);
            transform-origin: top left;
        }
    }
    .portfolio-intro__content.onHero{
        h1{
            padding-left: 0rem;
        }
        p{
            padding-left: 0rem;
            padding-right: 0rem;
        }

    }


    .agb_item{
        height: auto;
        flex: 0 0 23%;
        margin: 1% !important;
        background: #fff;
        padding: 0 20px 20px 20px;
    }
    .filter-wrapper__col.programmstruktur {
        min-width: 18%;
        max-width: 18%;
    }
    .filter-wrapper__col:nth-child(3n) {
        margin-right: 1.9356%;
    }
    .filter-wrapper__col:nth-child(5n) {
        margin-right: 0%;
    }
    .progr_strukt_left, a.pdf_downloader, .middle_programmstruktur, .right_programmstruktur{
        min-width: 18%;
        max-width: 18%;
    }
    a.pdf_downloader:nth-child(3n) {
        margin-right: 1.9356%;
    }
    .right_programmstruktur{
        margin-right: 0%;
    }
    .caption h1 {
        font-size: 2.3rem;
        padding: 0 0.0rem;
    }
    .caption p{
        font-size: 1rem;
        padding: 0 0rem;
    }
    .content__container{
        padding: 0 rem(10);
    }
    .twocols .onethird h1{
        font-size: 2.4rem;
    }
    .sc-content.tabs_description{
        width: 76.5%;
        margin-right: 1.5%;
        margin-left: 18%;
    }
    .header__logo{
        margin-left: 50px;
        margin-right: 0;
    }
    .main-nav__link{
        font-size: 1rem;;

        padding: 1.25rem rem(31);
    }
    .main-nav__link.fr{
        font-size: 1em;
    }
    .search-portfolio{
        &__container{
            width: 90%;
        }
        &__custom{
            & .input-addon{
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display:         flex;
            }
            & input[type=search]{
                display: inline-block;
            }
            & a{
                font-size: inherit;
                display: inline-block;
                width: auto;
                margin: 0;
            }
        }
    }
    .gomedia_title{
        font-size: 1.1rem;
    }
    .gomedia_detail{
        font-size: 1rem;
        & p{
            font-size: 1rem;
        }
    }
    .go4media{
        & .gomedia_detail p {
            font-size: 1rem;
        }
    }
    .meta-nav__link span{
        display: inline-block;
    }
    .meta_select-languages{
        width: 2.5rem;
    }
    .detailpage__inner{
        margin-left: 0;
    }
    .caption h1{
        font-size: 2.3rem;
    }
    .caption h2{
        font-size: 1.75rem;
    }
    .caption p{
        font-size: 1.25rem;
    }
    // VARIANTS
    .brand-overview{
        &--size-special{
            min-height: 12.3rem;
        }
        & .dashboard_special{
            min-height: 14.7rem;
        }
    }

    .filter-wrapper__col.extended{
        width: 23.45152%;
    }
    .filter-wrapper__col.publispot{
        width: 23.45152%;
    }
    // .filter-wrapper__col.publispot:nth-child(2n){
    //     margin-right: 1.9356%;
    // }
    // .filter-wrapper__col.publispot:nth-child(4n){
    //     margin-right: 0;
    // }
    // .filter-wrapper__col.extended:nth-child(2n){
    //     margin-right: 1.9356%;
    // }
    // .filter-wrapper__col.extended:nth-child(4n){
    //     margin-right: 0;
    // }
    .filter-wrapper__col.publispot_button > .btn{
        text-align: left;
    }
    .filter-wrapper__col.publispot_button > .btn span{
        display: inline-block;

        padding-top: rem(15);
    }
    .brand-overview__brand-link{
        border-width: rem(6);
    }
    #account_items{
        & .icon {
            width: 5.75rem;
            height: 5.75rem;
        }
        & span{
            font-size: 1rem;
            font-weight: 700;
            padding-top: 1.5rem;
        }
    }

    #account_items2{
        & .icon{
            width: 5.75rem;
            height: 5.75rem;
        }
        & span{
            font-size: 1rem;
            font-weight: 700;
            padding-top: 1.5rem;
        }
    }
    .teaser__top-buttons{
        & .btn{
            font-size: 1rem;
            line-height: 1.5;
            width: 1.8rem;
            height: 1.8rem;
        }
        & .btn .icon{
            width: 1.3rem;
            height: 1.3rem;
        }
    }
    .filter-wrapper__radio_group{
        & [type='radio'] + label{
            padding: .5625rem .5625rem .5625rem 2.625rem;
            & i{
                left: .5625rem;
            }
        }
    }
    .detailpage{
        .show_description{
            margin-top: rem(20);
            &__actions{
                width: calc(83.5% - 175px);
                margin-left: calc(6.5% + 175px);
            }
            &__image{
                float: left;
                width: 175px;
                margin-right: 1.5%;
                margin-left: 5%;
                padding: 23px;
            }
            &__content{
                float: left;
                width: calc(83.5% - 175px);
                margin-left: 0;
                padding-right: 2%;
                &.template2{
                    display: inline-block;
                    float: none;
                    width: 60%;
                    padding-left: 2%;
                }
            }
        }
    }

    .menu_left_cont{
        position: relative;
        & .left_menu{
            float: left;
            // width: 310px;
            & li{
                & a{
                    display: block;
                }
            }
        }
    }
    #dashboardContainer{
        float: left;
        width: calc(100% - 310px);
    }

    .member-dashboard{
        & .brand-overview__brands{
            display: flex;
            & .aktuelle_termine{
                flex: 1 auto;
            }
            & .brand-overview__inner{
                flex: 1 auto;
            }
            & .view_togglers{
                flex: 1 auto;
                padding: 0 3%;;
                margin-top: -60px;;
            }
            & .aktuelle_termine, .view_togglers{

                min-width: 18%;
                max-width: 18%;
                & p{
                    max-width: 207px;
                    margin: 0 auto;
                }
            }
            & .brand-overview__inner{
                min-width: 64%;
                max-width: 64%;
                & .brand-overview__item.digital_class {
                    width: 23%;
                    height: auto;
                }
                & a.brand-overview__brand-link {
                    padding-bottom: 20px;
                    padding-top: 40px;
                    & .dash_items img{
                        max-height: 100px;
                        max-width: 102px;
                        width: 100%;;
                    }
                }
                &.list_view{
                    & .digital_class{
                        height: auto !important;
                        min-height: 10px;;

                        & .dash_items {
                            display: flex !important;
                            align-items: center;
                            height: 88px;
                            img{
                                position: absolute;
                                max-width: 49px;
                                max-height: 49px;;
                                left: 18px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    & #account_items li a,
                    #account_items2 li a  {
                        padding: 0
                    }
                    & #account_items li a span,
                    #account_items2 li a span{
                        text-align: center !important;
                        line-height: 1.3 !important;;
                        min-height: 10px;
                        width: 100% !important;
                        padding: 0 10px 0 75px;;
                    }
                }
            }
        }
        & #account_items .brand-overview__item.digital_class,
        #account_items2 .brand-overview__item.digital_class{
            width: 23.5%;
            height: auto;
            &:nth-child(3n+3){
                margin-right: 1.9356%;
            }
            &:nth-child(4n+4){
                margin-right: 0%;
            }
            & span{
                min-height: 68px;;
            }
        }
    }
}
