// Disable Bourbon Prefixes
$prefix-for-webkit:    false;
$prefix-for-mozilla:   false;
$prefix-for-microsoft: false;
$prefix-for-opera:     false;
$prefix-for-spec:      false;

// This is the the base for the em/rem mixins and the grid calculation.
// If your $body__font-size is 100%, leave it at 16px.
$em-base: 16px;

// GRID
// ----------------------------------------
// Since the typical default browser font-size is 16px,
// that makes the calculation for grid size.
//
// THIS VARIABLES ARE FOR NEAT SETTING! DON'T RENAME!
$gutter:              em(20);
$grid-columns:        12;
$max-width:           rem(1200);
$border-box-sizing:   true;

// Debug Grid
$visual-grid:         false;
$visual-grid-color:   #f00;
$visual-grid-index:   back;
$visual-grid-opacity: 0.1;

// MEDIAQUERIES
// ----------------------------------------
// 640px, 799.9999px, 800px, 900px, 1200px, 1540
$breakpoints: (
  medium:   40em,
  formenu:  49.9375em,
  large:    50em,
  inter:    56em,
  xlarge:   75em,
  detail:   96.25em
);

/*
  $breakpoints: (
    medium:   40em -------------> 640px
    formenu:  49.9375em, -------> 799px
    large:    50em, ------------> 800px
    inter:    56em, ------------> 896px
    xlarge:   75em, ------------> 1200px
    detail:   96.25em ----------> 1540px
  );
*/

// COLORS
// --------------------------

// DON'T USE THIS NAME SPECIFIC VARIABLES IN OTHER FILES
$color__black:              #282828;
$color__white:              #fff;

$color__grey--light:        #f0f0f0;
$color__grey:               #e6e6e6;
$color__grey--medium:       #c8c8c8;
$color__grey--dark:         #aaa;
$color__grey--deep:         #505050;

$color__brand--one:         #009ba7;
$color__brand--two:         #e40048;

$color__red:                #f00;
$color__orange:             #f08a24;
$color__blue--light:        #afe0ed;
$color__green:              #43ac6a;

// COLOR NAMING
// USE THIS VARIABLES IN OTHER FILES
$color__primary:            $color__brand--one;
$color__primary--light:     $color__blue--light;
$color__secondary:          $color__brand--two;
$color__tertiary:           $color__black;

$color__dark:               $color__black;
$color__bright:             $color__white;

$color__accent--light:      $color__grey--light;
$color__accent:             $color__grey;
$color__accent--medium:     $color__grey--medium;
$color__accent--dark:       $color__grey--dark;
$color__accent--deep:       $color__grey--deep;

$color__alert:              $color__red;
$color__success:            $color__green;
$color__warning:            $color__orange;
$color__info:               $color__blue--light;

// SPACES
// ----------------------------------------
$space__default-value: 10;  // e.g. used for paddings

$grid__paddings: (
  small: rem(10),
  medium: rem(10),
  large: rem(10)
);

// TYPOGRAPHY
// ----------------------------------------
$font__weight--light:         300;
$font__weight--medium:        400;
$font__weight--bold:          700;
$font__weight--heavy:         800;


$body__font-family:           "ATC Overlook", "Helvetica Neue", "Helvetica", sans-serif;
$body__font-size:             100%;
$body__font-weight:           normal;
$body__font-style:            normal;
$body__font-color:            $color__black;
$body__line-height:           1.2;
$body__background-color:      $color__white;

// HEADINGS font styles
$heading__font-family:        $body__font-family;
$heading__font-weight:        $font__weight--heavy;
$heading__font-style:         normal;
$heading__font-color:         $body__font-color;
$heading__text-rendering:     auto;

// PARAGRAPHS
$paragraph__font-family:      inherit;
$paragraph__font-weight:      $font__weight--light;
$paragraph__font-size:        rem(16);
$paragraph__line-height:      1.375;
$paragraph__margin-bottom:    rem(11);
$paragraph__text-rendering:   auto;

// ANCHORS
$anchor--text-decoration:     none;
$anchor--color:               $color__primary;
$anchor--color-hover:         $color__accent--dark;
$anchor--color-active:        $color__secondary;

// <HR>
$hr__margin-top:              rem(18);
$hr__margin-bottom:           $hr__margin-top;
$hr__border-width:            rem(1);
$hr__border-style:            solid;
$hr__border-color:            $body__font-color;

// LISTS
$list__style-position:        outside;
$list__side-margin:           0;
$list__nested-margin:         0;
$list__side-padding:          rem(18);
$list__ol-side-padding:       rem(23);
$list__font-size:             $paragraph__font-size;

// BLOCKQUOTE
$blockquote__font-style:      normal;
$blockquote__margin:          0 0 $paragraph__margin-bottom 0;

// SMALL
$small__font-size:            rem(14);

// RADIUS
$border-radius:               0;
$border-radius--round:        100%;
