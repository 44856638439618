.header {

  background-color: $color__dark;
  color: $color__bright;
  min-height: rem(64);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    outline-color: $color__dark;
  }
}

.header__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 rem(30);
}

.header__meta {
  order: 2;
}

.header__nav {
  order: 1;
  width: 100%;
}

.header__logo {
  margin-left: rem(10);
  margin-right: rem(20);
  float: left;
  align-self: center;
  flex-shrink: 0;

  a,
  img {
    display: inline-block;
  }

  a {
    padding: rem(10) rem(10) rem(5);
  }
}

// Flexbox Fallback
.no-flexbox.no-flexboxtweener {
  .header {
    @include clearfix;
  }

  .header__logo {
    width: rem(90);
    margin-top: rem(5);
  }
}
.header__nav.second_navi,
.second_navi .main-nav,
.second_navi .main-nav__link{
  background: $color__primary;
}

// .header__nav.second_navi {
//   width: auto;
//   min-width: 100%;
//   max-width: 100%;
//   display: flex;
//   justify-content: center;
//   position: sticky;
//   z-index: 1212;
//   top: rem(75);
// }

@include mq(large) {
  .header {
    justify-content: flex-start;
    position: sticky;
    z-index: 123123;
    top: 0;;
  }

  .header__logo {
    display: flex;
    height: rem(65);
    margin-left: rem(25);
    align-self: flex-end;
    align-items: center;
    min-width: 148px;
    max-width: 148px;

  }

  .header__content {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    flex-grow: 2;
    padding: 0;
    width: rem(600);
    min-width: calc(100% - 218px);
    max-width: calc(100% - 218px);
  }

  .header__nav.second_navi {
    width: auto;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    top: rem(64);
  }
  
  .header__meta {
    float: right;
    min-width: 191px;
    max-width: 191px;
    margin-top: 0px;
    padding-right: 20px;;
  }
  .header__nav {
    width: auto;
    min-width: calc(100% - 191px);
    max-width: calc(100% - 191px);
    display: flex;
    justify-content: center;
  }
 
  .no-flexbox.no-flexboxtweener {
    .header__nav {
      float: left;
    }

    .header__meta {
      float: right;
    }
  }
}
