// .spot_group_bg{
//       background: $color__accent;
// }
.spot-group {

    padding-top: rem(60);
    position: relative;
    z-index: 13;
}

.spot-group__header {
    @include outer-container();
    border-bottom: 1px solid $color__accent--medium;
    padding: 0 0 rem(20) rem(10);
    margin-bottom: rem(40);
    h1 {
        font-size: 1.8rem;
        color: $color__dark;
        line-height: 1;
        margin: 0;
    }
    h4 {
        font-size: 1.2rem;
        font-weight: 300;
        color: $color__dark;
        line-height: 1.5;
        margin: rem(11) 0;
    }
}

.spot-group__wrapper {
    @include outer-container();
    padding: 0 grid-padding(small);
    text-align: justify;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
    }
}
.pag_number{
    & .btn.btn--quaternary {
        color: $color__primary;
        color: #009ba7;
        border: 0;
        background: #c8c8c8;
        width: 1.8rem;
    }
}



.spot-group__footer{
  text-align: center;
  padding: rem(20) 0;
  background-color: transparent;
  & h3{
      color: $color__primary;
  }
}
.spot-group__wrapper p{
  color: $color__dark;
}
@include mq(medium) {
    .spot-group__wrapper {
        padding: 0 grid-padding(medium);
    }
    .spot-group {
        .spot {
            width: 49%;
        }
    }

}

@include mq(large) {
    .spot-group__wrapper {
        padding: 0 grid-padding(large);
    }
    .spot-group {
        .spot {
            width: 32%;
        }
        // VARIANTS
        .spot--small {
            width: 23.5%;
        }
        .spot--large {
            width: 49%;
        }
    }
    .spot-group__header {
        h1 {
            font-size: 2.2rem;
        }
    }
}
