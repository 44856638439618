$sub-nav__item-border-width: 5px;

.sub-nav {
  border-bottom: 1px solid $color__accent;
}

.sub-nav__inner {
  @include outer-container();
  padding: 0 grid-padding(small);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .no-flexbox.no-flexboxtweener & {
    text-align: center;
  }
}

.sub-nav__link {
  display: block;
  padding: rem(10) rem(5);
  text-align: center;
  font-size: rem(14);
  border-bottom: $sub-nav__item-border-width solid transparent;
  transition: color 0.15s linear;

  .icon {
    display: block;
    margin: 0 auto;
  }

  &:hover {
    color: $anchor--color-hover;
    border-bottom-color: $anchor--color-hover;
  }

  .no-flexbox.no-flexboxtweener & {
    display: inline-block;
  }
}

.sub-nav--active {
  color: $anchor--color-active;
  border-bottom-color: $anchor--color-active;
}

@include mq(medium) {
  .sub-nav__inner {
    justify-content: center;
  }

  .sub-nav__link {
    padding: rem(20);
  }

}
