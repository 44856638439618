$breadcrumb__color--default:            $color__accent--medium;
$breadcrumb__color--default-alternate:  $color__primary--light;
$breadcrumb__color--hover:              $anchor--color-hover;
$breadcrumb__color--active:             $color__secondary;

.breadcrumb {
  @include clearfix;
  display: block;
  list-style: none;
  padding: 0;
  margin: 0 0 rem(12);
}

.breadcrumb__item {
  display: inline-block;
  padding: rem(8) 0;
  font-size: rem(15);
  font-weight: $font__weight--bold;

  &:last-child {
    a {
      color: $breadcrumb__color--active;
    }
  }

  a {
    display: inline-block;
    color: $breadcrumb__color--default;

    &:hover {
      color: $breadcrumb__color--hover;
    }
  }
}

.breadcrumb__spacer {
  display: inline-block;
  color: $breadcrumb__color--default;
}

// STATES
.breadcrumb__item--active {
  color: $breadcrumb__color--active;
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .breadcrumb {
    margin-bottom: rem(24);
  }
}

// ALTERNATE COLOR
// ----------------------------------------
.breadcrumb--alternate {
  .breadcrumb__item {
    a {
      color: $breadcrumb__color--default-alternate;
    }

    a:hover {
      color: $breadcrumb__color--hover;
    }
  }

  .breadcrumb__spacer {
    color: $breadcrumb__color--default-alternate;
  }
}
