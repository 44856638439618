.main-nav {
  background-color: $color__dark;
}

.main-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: rem(18);
  font-weight: $font__weight--bold;
  line-height: rem(24);
}

.main-nav__item {
  display: block;
  // background-color: $color__bright;
}

.main-nav__link {
  display: block;
  padding: rem(12);
  background-color: $color__secondary;
  color: $color__bright;
  transition: all 0.15s ease;
  white-space: nowrap;
}

// STATES
.main-nav__link:hover {
  color: $color__accent--medium;
}

.main-nav__link:active,
.main-nav__item--active .main-nav__link {
  color: $anchor--color-active;
  background-color: $color__bright;
}
.second_navi .main-nav__list{
  display: flex;
  overflow: auto;
}
.second_navi .main-nav__link:active,
.second_navi .main-nav__item--active .main-nav__link {
  color: $color__dark;
  background-color: $color__primary;
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .main-nav__list {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .second_navi .main-nav__list {
    justify-content: space-evenly;
  }
  .main-nav__item {
    display: inline-block;
    position: relative;
    // perspective: 100px;
  }

  .main-nav__link {
    padding: rem(21) rem(15) rem(20);
    transform-origin: left center;
    background-color: $color__dark;
  }

  // STATES
  .main-nav__link:hover,
  .main-nav__item--active .main-nav__link {
    // transform: rotateY(5deg);
    background-color: $color__dark;
  }
  .second_navi .main-nav__link:hover,
  .second_navi .main-nav__item--active .main-nav__link {
    // transform: rotateY(5deg);
    background-color: transparent;
    color: $color__dark;
  }


  .second_navi .main-nav__item{
    background: transparent;
  }
  .second_navi .main-nav {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  .second_navi  .main-nav__link {
    font-size: 1rem;
    padding: 1.25rem 0.5625rem;
  }
}
