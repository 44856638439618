.portfolio-intro {
  @include outer-container();
  padding: 0 grid-padding(small);
}

.portfolio-intro__first,
.portfolio-intro__content {
  @include row;
}

.portfolio-intro__header {
  h1 {
    color: $color__secondary;
  }
}

.portfolio-intro__data {
  list-style-type: none;
  border: 1px solid $color__accent;
  padding: 0;
  margin: rem(30) 0 rem(40);
}

.portfolio-intro__data-item {
  display: block;
  border-bottom: 1px solid $color__accent;
  padding: rem(20) rem(20) rem(5);

  &:last-child {
    border-bottom: 0;
  }
}

.portfolio-intro__data-big {
  display: block;
  margin: 0;
  line-height: 1;
}

.portfolio-intro__data-link {
  width: 100%;
  text-align: left;
  margin-bottom: rem(20);

  &,
  &:hover,
  &:active {
    border-bottom: 1px solid $body__font-color;
  }
}
.portfolio-intro__content{
  width: 100%;
  max-width: 1200px;
}

@include mq(medium) {
  .portfolio-intro__data-item {
    display: inline-block;
    width: 49%;
    border-right: 1px solid $color__accent;
    border-bottom: 0;

    &:last-child {
      border-right: 0;
    }
  }
}

@include mq(large) {
  .portfolio-intro {
    padding: 0 grid-padding(large);
  }

  .portfolio-intro__first {
    @include span-columns(4);
  }

  .portfolio-intro__header {
    padding-left: rem(10);
  }

  .portfolio-intro__data {
    margin-top: rem(60);
  }

  .portfolio-intro__data-item {
    display: block;
    width: 100%;
    border-bottom: 1px solid $color__accent;
    border-right: 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  .portfolio-intro__content {
    @include span-columns(8);
    @include omega();
    padding-left: rem(0);
    padding-top: rem(25);
  }

}
