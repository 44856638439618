.teaser {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: rem(20);
  vertical-align: top;
  text-align: left;
  background-color: $color__bright;
  border: 1px solid $color__accent--light;
  flex: 0 1 auto;
  // backface-visibility: hidden;
  // transition: transform 600ms ease;
  // transform: perspective(1000px);
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  &:focus {
    border-color: $color__secondary;
  }

  p {
    @include word-break;
    font-size: rem(16);
    margin-bottom: rem(20);
  }

  // &:hover {
  //   // transform: perspective(1000px) rotateY(-5deg);
  // }
  & .read_more_cont{
    color: $color__primary; 
    font-size: rem(16);
    letter-spacing: -0.8px;
    font-weight: normal;
    display: block;
    position: absolute;
    bottom: rem(20);
    left: rem(20);
  }
  // & .teaser__link.read_more_cont{
  // }
}

.teaser__link {
  display: block;
  color: inherit;
  transition: all 300ms ease;

  &:hover .teaser__img {
    opacity: 0.8;
  }
}

.teaser__img {
  background-color: $color__accent;
  transition: opacity 300ms ease;
}

.teaser__content {
  padding: rem(15) rem(20) rem(40) rem(20) ;
}

.teaser__functions {
  background-color: $color__bright;
  padding: 0 rem(15) rem(45);
}

.teaser__title {
  color: $color__dark;
  margin-top: rem(5);
  margin-bottom: rem(14);
}

.teaser__label {
  text-transform: uppercase;
  color: $color__primary;
  font-weight: $font__weight--light;
  padding-bottom: rem(10)
}

.teaser__buttons {
  position: absolute;
  right: 0;
  bottom: 0;

  .btn {
    margin-bottom: 0;
    margin-left: 1px;
  }
}

// VARIANTS
.teaser--dark {
  .teaser__link {
    background-color: $color__accent--deep;
  }

  .teaser__label {
    color: $color__accent--medium;
  }

  .teaser__content {
    color: $color__accent;
  }
}
