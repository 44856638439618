.product-accordion {
  display: block;
  margin: 0;
  padding: 0;
}

.product-accordion__item {
  display: block;
}

.product-accordion__title {
  display: block;
  margin: 0;
  padding: rem(15) rem(50) rem(15) 0;
  position: relative;
  font-weight: $heading__font-weight;
  cursor: pointer;
  color: $color__primary;

}

.product-accordion__content {
  display: none;
}

// STATES
.product-accordion__item--open {
  .product-accordion__content {
    display: block;
  }
}

// NO-JS Fallback
.no-js {
  .product-accordion__content {
    display: block;
  }
}

@include mq(medium) {
  .product-accordion {
    @include outer-container();
  }
  .product-accordion__title {
    padding-top: rem(24);
    cursor: text;
  }

  .product-accordion__content {
    display: block;
  }

}
