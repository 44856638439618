@function space($factor) {
  @return rem($space__default-value) * $factor;
}

@function grid-padding($key) {
  @if map-has-key($grid__paddings, $key) {
    @return map-get($grid__paddings, $key);
  }
  @warn "Unknown '#{$key}' in $grid__paddings.";
  @return null;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
