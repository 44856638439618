body {
  font-family: $body__font-family;
  font-weight: $body__font-weight;
  font-style: $body__font-style;
  font-size: $body__font-size;
  line-height: $body__line-height;
  background-color: $body__background-color;
  color: $body__font-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $heading__font-family;
  font-weight: $heading__font-weight;
  font-style: $heading__font-style;
  text-rendering: $heading__text-rendering;
}

h1,
.h1 {
  font-size: rem(30);
  line-height: 1.2;
  margin: rem(60) 0 rem(11);
}

h2,
.h2 {
  font-size: rem(22);
  line-height: 1.2;
  margin: rem(30) 0 rem(11);
}

h3,
.h3 {
  font-size: rem(18);
  font-weight: $font__weight--bold;
  line-height: 1.3;
  margin: rem(24) 0 rem(2);
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-size: $paragraph__font-size;
  font-weight: $font__weight--bold;
  line-height: $paragraph__line-height;
  margin: rem(21) 0 rem(2);
}

p,
ul,
ol,
blockquote {
  font-family: $paragraph__font-family;
  font-weight: $paragraph__font-weight;
  font-size: $paragraph__font-size;
  line-height: $paragraph__line-height;
}

p {
  margin-top: 0;
  margin-bottom: $paragraph__margin-bottom;
  font-weight: $paragraph__font-weight;
  text-rendering: $paragraph__text-rendering;
}

.text--lead {
  font-size: rem(18);
  font-weight: $font__weight--bold;
}

b,
strong {
  font-weight: $font__weight--medium;
}

a {
  text-decoration: $anchor--text-decoration;
  color: $anchor--color;

  &:hover {
    color: $anchor--color-hover;
  }

  &:active {
    color: $anchor--color-active;
  }
}

ul,
ol {
  font-weight: $paragraph__font-weight;
  list-style-position: $list__style-position;
  margin-left: $list__side-margin;
  padding-left: $list__side-padding;
}

ol {
  padding-left: $list__ol-side-padding;
}

ul ul,
ol ol {
  margin-left: $list__nested-margin;
}

hr {
  border-width: $hr__border-width;
  border-style: $hr__border-style;
  border-color: $hr__border-color;
  margin-top: $hr__margin-top;
  margin-bottom: $hr__margin-bottom;
}

small {
  color: $color__accent--medium;
  font-size: $small__font-size;
}

blockquote {
  font-weight: $font__weight--medium;
  font-style: $blockquote__font-style;
  margin: $blockquote__margin;
  text-transform: uppercase;
}

// MEDIUM
// ----------------------------------------
@include mq(medium) {
  h1,
  .h1 {
    font-size: rem(38);
    margin-bottom: rem(12);
  }

  h2,
  .h2 {
    font-size: rem(24);
    margin-bottom: rem(12);
  }

  h3,
  .h3 {
    font-size: rem(21);
    margin-bottom: rem(2);
  }

  h4,
  h5,
  h6,
  .h4,
  .h5,
  .h6 {
    font-size: rem(18);
    line-height: 1.33;
    margin-bottom: rem(2);
  }

  p,
  ul,
  ol,
  blockquote {
    font-size: rem(18);
    line-height: 1.33;
    margin-bottom: rem(12);
  }

  .text--lead {
    font-size: rem(21);
  }

}


// LARGE
// ----------------------------------------
@include mq(large) {
  h1,
  .h1 {
    font-size: rem(44);
    margin-bottom: rem(13);
  }

  h2,
  .h2 {
    font-size: rem(28);
    margin-bottom: rem(13);
  }

  h3,
  .h3 {
    font-size: rem(24);
  }

  h4,
  h5,
  h6,
  .h4,
  .h5,
  .h6 {
    font-size: rem(19);
    line-height: 1.375;
    margin-bottom: 0;
  }

  p,
  ul,
  ol,
  blockquote {
    font-size: rem(19);
    line-height: 1.375;
    margin-bottom: rem(13);
  }

  .text--lead {
    font-size: rem(24);
  }

  hr {
    margin-top: rem(24);
    margin-bottom: rem(34);
  }
}
