.flyout {
  @include clearfix();
  position: relative;
  background-color: $color__dark;
  transform: translateZ(0);
}

.flyout__shift {
  width: 100%;
  position: relative;
  transition: transform 1s ease;
  transform: perspective(3000px) rotateY(-45deg);
  transform-origin: center;
  backface-visibility: hidden;
  border: 1px solid transparent;

  .no-js &,
  &.is-onscreen {
    transform: perspective(3000px) rotateY(-10deg);
  }
}

.flyout__inner {
  @include row;
  background-color: $color__secondary;
  color: $color__bright;
}

.flyout__content {
  @include outer-container();
  position: relative;
  z-index: 10;
  padding: rem(50) grid-padding(small);
}

@include mq(large) {
  .flyout {
    padding-left: 3%;
    padding-right: 3%;
    transform: none;
  }

  .flyout__content {
    padding: rem(100) grid-padding(large);
  }
}

@include mq(detail) {
  .flyout {
    padding-left: 10%;
    padding-right: 10%;
  }
}
