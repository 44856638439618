.hero__image {
  display: block;
  max-width: none;
  width: 98%;
  right: 0;
  left: auto;
}

.hero__content {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  height: 100%;
  align-items: center;
}

.hero__content-inner {
  max-width: rem(660);
  margin: 0 auto;
  text-align: center;
  color: $color__bright;
}

// FULL WIDTH VARIANT
.hero--full {
  .hero__image {
    width: 100%;
    left: 0;
  }
}
