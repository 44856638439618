.content {
  @include clearfix;
}

.content__container {
  @include outer-container();
}

.content__inner {
  padding: 0 grid-padding(small);
}

// MEDIUM
// ----------------------------------------
@include mq(medium) {
  .content__inner {
    padding: 0 grid-padding(medium);
  }

  .content,
  .content--one {
    .content__inner {
      @include span-columns(10);
      @include shift(2);
      @include omega();
    }
  }

  .content--two {
    .content__inner {
      @include span-columns(6);
      @include omega(2n);
      @include shift(0);
      padding: 0 rem(20);
    }
  }
  .content--three {
    .content__inner {
      @include span-columns(4);
      @include omega(3n);
      @include shift(0);
      padding: 0;
    }
  }
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .content__inner {
    padding: 0 grid-padding(large);
  }

  .content--one {
    .content__inner {
      @include span-columns(8);
      @include omega();
    }
  }
}
