$offcanvas__width: rem(300);
$offcanvas__shift-width: rem(300);
$offcanvas__transition-time: 0.4s;

.js-offcanvas {
  overflow: hidden;
  position: relative;
  width: 100%;
  // background-color: $color__dark;
  perspective-origin: 0% 50%;
}

.offcanvas__pusher {
  background-color: $color__bright;
}

.offcanvas__content {
  transform: translate3d(100%, 0, 0) perspective(10000px) rotateY(70deg);
}

// STATES
.offcanvas--open {
  .offcanvas__content {
    transform: translate3d(100%, 0, 0) perspective(10000px) rotateY(0deg);
  }
}

@include mq(medium) {

  .offcanvas__content {
    transform: translate3d(100%, 0, 0) perspective(5000px) rotateY(70deg);
  }

  // STATES
  .offcanvas--open {
    .offcanvas__content {
      transform: translate3d(100%, 0, 0) perspective(5000px) rotateY(0deg);
    }
  }
}

@include mq(large) {
  .offcanvas__content {
    transform: none;
  }

  // STATES
  .offcanvas--open {
    .offcanvas__content {
      transform: none;
    }
  }
}

@include mq(formenu, $breakpoints, 'max-width') {
  .offcanvas__content {
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      bottom: 0;
      width: 18.75rem;
      height: 100%;
      z-index: 1001;
      background-color: #e40048;
      color: #fff;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      -webkit-transform-origin: 0% 50%;
      -ms-transform-origin: 0% 50%;
      transform-origin: 0% 50%;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
  }
  .js-offcanvas {
    overflow: hidden;
    padding: rez mica;
  }
}
@include mq(large, $breakpoints, 'max-width') {
  // .js-offcanvas {
  //   overflow: visible;
  //   padding: rez large;
  // }

  .offcanvas__pusher {
    z-index: 99;
    height: 100%;
    position: relative;
    left: 0;
    transition: transform $offcanvas__transition-time ease;
    backface-visibility: hidden;
  }

  .offcanvas__overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background: rgba($color__primary, 0.5);
    opacity: 0;
    z-index: 1000;
    transition: opacity 0.2s, width 0.1s 0.2s, height 0.1s 0.2s;
  }

  // .offcanvas__content {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: auto;
  //   bottom: 0;
  //   width: $offcanvas__width;
  //   height: 100%;
  //   z-index: 1001;
  //   background-color: $color__secondary;
  //   color: $color__bright;
  //   backface-visibility: hidden;
  //   transition: all $offcanvas__transition-time ease;
  //   transform-origin: 0% 50%;
  //   transform-style: preserve-3d;
  // }

  .offcanvas__opener,
  .offcanvas__close {
    display: inline-block;
    background-color: transparent;
    border: 0;
    outline: 0;
    line-height: 2;
    padding: rem(5) 0;
    color: $color__bright;
    text-transform: uppercase;

    .icon {
      vertical-align: top;
    }

    &:hover {
      color: $anchor--color-hover;
    }

    &:active {
      color: $anchor--color-active;
    }

  }

  .offcanvas__opener {
    align-self: center;
    margin-right: rem(10);
  }

  .offcanvas__close {
    width: 100%;
    padding: rem(15) rem(10);
    text-align: right;
  }

  // STATES
  .offcanvas--open {

    & {
      overflow: hidden;
    }

    .offcanvas__pusher {
      transform: translate3d(-$offcanvas__shift-width, 0, 0);
    }

    .offcanvas__overlay {
      transition: width 0.1s, height 0.1s, opacity 0.5s 0.2s;
      width: 100%;
      height: 100%;
      opacity: 1;
    }

    .offcanvas__content {
      transition: all $offcanvas__transition-time ease;
    }

    .offcanvas__opener {
      transition: opacity $offcanvas__transition-time ease;
      opacity: 0;
      pointer-events: none;
    }
  }

  // NO CSS TRANSFORM Fallback
  .no-csstransforms3d {
    .offcanvas__content {
      display: none;
    }

    .offcanvas--open {
      .offcanvas__content {
        display: block;
        position: relative;
        width: 100%;
      }

      .offcanvas__overlay {
        display: none;
      }
    }
  }

}

@include mq(large) {
  .js-offcanvas {
    overflow: visible;
    padding: rez large no menu;
  }
  .offcanvas__close,
  .offcanvas__opener,
  .offcanvas__overlay {
    display: none;
  }
}
