.category-header {
  @include row;
  background-color: $color__dark;
}

.category-header__blue {
  @include row;
  background-color: $color__primary;
  width: 99%;
  margin-left: 1%;
  color: $color__bright;
}

.category-header__inner {
  @include outer-container();
  padding: rem(40) grid-padding(small);
}

// MEDIUM
// ----------------------------------------
@include mq(medium) {
  .category-header__inner {
    padding: rem(50) grid-padding(medium);
  }
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .category-header__inner {
    padding: rem(100) rem(40);
  }
}
