// FORM
fieldset {
  border: 0;
  padding: 0;
  margin: 0 0 rem(18);
}

legend {
  font-weight: $heading__font-weight;
  color: $color__accent--medium;
  margin-bottom: rem(6);
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: rem(6);
  cursor: pointer;
  color: $color__accent--medium;
  font-size: rem(16);
  line-height: 1.5;
}

input::placeholder {
  color: $color__primary;
}

input,
textarea {
  display: block;
  width: 100%;
  background-color: $color__accent;
  border-radius: 0;
  padding: rem(12) rem(12) rem(9);
  font-size: rem(16);
  line-height: 1.5;
  border: 0;

  &:focus {
    outline: 0;
    border-color: $color__accent--medium;
    background-color: $color__accent--light;
  }
}

// scss-lint:disable QualifyingElement
input[type="search"] {
  box-sizing: border-box;
  -webkit-appearance: none;
}
// scss-lint:enable QualifyingElement


// Define colors for select arrow and convert it to a URL encoded value.
$form__select-arrow-color: str-replace(inspect($color__accent--medium), '#', '%23');
$form__select-arrow-color--focus: str-replace(inspect($color__primary), '#', '%23');

select {
  appearance: none;
  width: 100%;
  height: rem(40);
  padding: rem(9) rem(30) rem(9) rem(12);
  background-color: transparent;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23009ba7%22%0A%09%20viewBox%3D%22-282%20365%2047%2064%22%20style%3D%22enable-background%3Anew%20-282%20365%2047%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-258.5%2C412l-13.5-13.5l3.4-3.4l10.1%2C10.1l10.1-10.1l3.4%2C3.4L-258.5%2C412z%22%2F%3E%0A%3C%2Fsvg%3E%0A);
  
  background-size: 27px;
  background-position: right rem(5) center;
  background-repeat: no-repeat;
  background-color: $color__accent;
  border-radius: 0;
  border: 0;
  font-size: rem(18);
  line-height: 1.5;
  color: $color__primary;
  font-weight: 400 !important;

  option {
    color: $color__primary;
  }

  .lt-ie10 & {
    background-image: none;
    padding-right: rem(12);
  }

  &::-ms-expand {
    display: none;
  }

  &:focus::-ms-value {
    color: $color__primary;
    background-color: transparent;
  }

  &:hover {
    // color: $color__dark;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
    border-color: $color__primary;
    color: $color__primary;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%22-282%20365%2047%2064%22%20style%3D%22enable-background%3Anew%20-282%20365%2047%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-258.5%2C412l-13.5-13.5l3.4-3.4l10.1%2C10.1l10.1-10.1l3.4%2C3.4L-258.5%2C412z%22%2F%3E%0A%3C%2Fsvg%3E);
  }
}

// INPUT WITH ICON BUTTON INSIDE
.input-addon {
  display: flex;
}

.input-addon__input {
  flex: 1;
}

.input-addon__item {
  margin-bottom: 0;
  color: $color__accent--medium;
  align-self: center;

  &:focus {
    color: $color__primary;
  }

  &:hover {
    color: $color__dark;
  }

  &:active {
    color: $color__secondary;
  }

  &,
  &:hover,
  &:active {
    background-color: transparent;
    border-color: transparent;
  }

  .icon {
    color: inherit;
    vertical-align: middle;
  }
}

// Flexbox Fallback
.no-flexbox.no-flexboxtweener {

  .input-addon {
    display: table;
  }

  .input-addon__input,
  .input-addon__item {
    display: table-cell;
  }
}

// STATES
.form--field-error {
  label {
    color: $color__alert;
  }

  input {
    border-color: $color__alert;
  }
}

// MEDIUM
// ----------------------------------------
@include mq(medium) {
  // STATES
  .form--horizontal {
    @include outer-container;
    margin-bottom: rem(18);

    label {
      @include span-columns(3);
      padding-top: rem(9);
    }

    input,
    select {
      @include span-columns(9);
    }
  }
}
