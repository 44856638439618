.contact-group {
  padding: 0 grid-padding(small);

  .contact {
    padding: rem(20) grid-padding(small);
  }
}

.contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: rem(20);
  padding: 0 grid-padding(small);
}

.contact__image {
  display: block;
  width: rem(100);
  height: rem(100);
  margin-bottom: rem(20);
  margin-right: rem(20);

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

.contact__content {
  flex: 1;
}

.contact__name {
  margin-bottom: rem(10);
  color: inherit;

  strong,
  small {
    display: block;
    width: 100%;
    color: inherit;
    margin-top: 0;
  }

  small {
    text-transform: uppercase;
    font-weight: $font__weight--light;
  }
}

.contact__actions {
  &,
  a {
    color: inherit;
  }

  p {
    margin-bottom: 0;
    font-weight: $font__weight--medium;
  }

  a,
  span {
    border-bottom: 1px solid currentColor;
  }

  span {
    display: inline-block;
    padding-bottom: rem(5);
  }

  a {
    &:hover {
      color: $anchor--color-hover;
    }

    &:active {
      color: $color__dark;
    }
  }
}

@include mq(medium) {
  .contact {
    display: block;
  }

  .contact__image {
    width: rem(150);
    height: rem(150);
  }

  .contact-group {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;

    .contact {
      display: block;
      flex: 0 1 auto;
      padding: rem(20);
    }
  }
}

// Flexbox Fallback
.no-flexbox.no-flexboxtweener {
  .contact-group {
    @include clearfix;

    .contact {
      display: inline-block;
    }
  }
}
