.tags {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
  }
}

.tags__item {
  display: block;
  background-color: $color__accent--medium;
  color: $color__bright;
  font-size: rem(15);
  line-height: 1.26;
  transition: all 0.1s ease-in-out;
  padding: rem(4) rem(5) rem(3);
  margin-bottom: rem(4);

  &:hover {
    background-color: $color__dark;
    color: $color__bright;
  }

  &:active {
    background-color: $color__secondary;
    color: $color__bright;
  }
}
