.teaser-group {
  @include outer-container();
}

.teaser-group__header {
  text-align: center;
  margin-bottom: rem(20);
}

.teaser-group__wrapper {
  @include outer-container();
  padding: 0 grid-padding(small);
  text-align: justify;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:after {
    content: '';
    flex: auto;
    width: 100%;
  }
}

.teaser-group {
    .teaser--xlarge {
      width: 100%;
    }
  }

@include mq(medium) {
  .teaser-group__wrapper {
    padding: 0 grid-padding(medium);
  }

  .teaser-group {
    .teaser {
      width: 49%;
      // margin-left: auto;
      // margin-right: auto;
      // &:nth-child(2n){
      //     margin-right: 0;
      // }
    }
    .teaser--xlarge {
      width: 100%;
    }
  }

}

@include mq(large) {
  .teaser-group__wrapper {
    // padding: 0 grid-padding(large);
    // padding: 0;
  }

  .teaser-group {
    .teaser {
      width: 32%;
      // margin-right: auto;
      // margin-left: auto;
      // margin-right: 1.9356%;
      // &:nth-child(2n){
      //   margin-right: 1.9356%;
      // }
      // &:nth-child(3n){
      //   margin-right: 0;
      // }
    }

    // VARIANTS
    .teaser--xsmall {
      width: 49%;
    }

    .teaser--small {
      width: 23.5%;
    }

    .teaser--medium {
      width: 66%;
    }

    .teaser--large {
      width: 49%;
    }
    .teaser--xlarge {
      width: 100%;
    }
  }
}
@include mq(inter) {
  .teaser-group {
    .teaser--xsmall {
      width: 19%;
    }

    .teaser--medium {
      width: 66%;
    }
    .teaser--xlarge {
      width: 100%;
    }

  }
}
