.intrinsic {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 100%; // Default to square

  &--16x5 {
    padding-top: 31.25%;
  }

  &--16x9 {
    padding-top: 56.25%;
  }

  &--4x3 {
    padding-top: 75%;
  }
  &--19x9{
    padding-top: 0;
    height: 484px;
    & img{
      max-height: 484px;
      min-height: 484px;
      object-fit: cover;
      object-position: center;
    }
  }
  &--19x7{
    padding-top: 0;
    height: 484px;
    & img{
      max-height: 484px;
      min-height: 484px;
      object-fit: cover;
      object-position: center;
    }
  }
  &--19x6{
    padding-top: 0%;
    height: 432px;
    & img{
      max-height: 432px;
      min-height: 432px;
      object-fit: cover;
      object-position: center;
    }
  }

}

// Force the item to fill the intrinsic box
.intrinsic__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@include mq(xlarge){
  .intrinsic {
      display: block;
      position: relative;
      height: 0;
      width: 100%;
      // padding-top: 100%; // Default to square
    
      &--19x9{
        padding-top: 47.92%;
        & img{
          max-height: 100%;
          min-height: auto;
          object-fit: auto;
          object-position: auto;
        }
      }
      &--19x7{
        padding-top: 40%;
        & img{
          max-height: 100%;
          min-height: auto;
          object-fit: auto;
          object-position: auto;
        }
      }
      &--19x6{
        padding-top: 33.86%;
        & img{
          max-height: inherit;
          min-height: auto;
          object-fit: auto;
          object-position: auto;
        }
      }
    }
}