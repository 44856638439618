.spot {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: rem(20);
  vertical-align: top;
  text-align: left;
  background-color: $color__bright;
  flex: 0 1 auto;
  // backface-visibility: hidden;
  // transition: transform 600ms ease;
  // transform: perspective(1000px);
  border: 1px solid #f0f0f0;

  &:focus {
    border-color: $color__secondary;
  }

  p {
    @include word-break;
    font-size: rem(16);
    margin-bottom: 0;
  }

  // &:hover {
  //   transform: perspective(1000px) rotateY(-5deg);
  // }
}

.spot__link {
  display: block;
  color: inherit;
  transition: all 300ms ease;

  &:hover .spot__img {
    opacity: 0.8;
  }
}

.spot__img {
  background-color: $color__accent;
  transition: opacity 300ms ease;
}

.spot__content {
  padding: rem(15);
}

.spot__functions {
  padding: 0 rem(15) rem(45);
  display: none;
}

.spot__title {
  color: $color__dark;
  margin-top: rem(5);
}

.spot__label {
  text-transform: uppercase;
  color: $color__accent--medium;
  font-weight: $font__weight--light;
}

.spot__buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;

  .btn {
    margin-bottom: 0;
    margin-left: 1px;
  }
}

// VARIANTS
.spot--dark {
  // .spot__link {
  //   background-color: $color__bright;
  // }

  .spot__label {
    color: $color__accent--medium;
  }

  .spot__content {
    color: $color__accent;
  }
}
