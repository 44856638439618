.empty_container{
    height: 10rem;
    background: $color__bright;
}
.angled_box {
    position: relative;
    overflow: hidden;
    margin-left: rem(30);
    margin-top: -10rem;
    /*background: $color__dark;*/
    background-color: $color__secondary;
    &:before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: -100%;
        overflow: hidden;
        background: $color__bright;
        transform: skewY(-2deg);
        transform-origin: right bottom;
        backface-visibility: hidden;
        z-index: 1;
    }
    &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -98%;
        overflow: hidden;
        background: $color__dark;
        transform: skewY(2deg);
        transform-origin: right bottom;
        backface-visibility: hidden;
        z-index: 1;
    }
}

.contacts-box {

    padding: 2rem rem(20);
    &:after {
        content: '';
        display: block;
        width: 100%;
        clear: both;
    }
}
.inside-info_container{
    @include outer-container;
    h3{
        color: #fff;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 2rem;
    }
}
.inside_info:nth-child(3n+5){
  clear: both;
}
.inside_info {
    display: block;
    padding-bottom: 2rem;
    // width: 18rem;
    margin: 0 auto;
    max-width: 400px;
    & .people_container{
        background-color: $color__bright;
    }
    &__image {
        display: block;
        // width: rem(170);
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-bottom: rem(20);
        margin-left: 0;
        margin-right: auto;

        position: relative;
        img {
            // border-radius: 100%;
            // border: 1px solid $color__accent;
            width: 100%;
        }
    }
    &__name {
        display: block;
        color: $color__dark;
        width: 100%;
        max-width: 100%;
        text-align: left;
        margin: 0 0;
        padding: 0 0.6325rem;
        & h3 {

            br{
                display: none;
            }
        }
        & p{
            color: $color__primary;
        }
    }
    &__actions{
        color: $color__dark;
        margin: 0 0;
        width: 100%;
        max-width: 100%;
        text-align: left;
        padding: 0 0.6325rem;
        a{
            color: $color__dark;
            border-bottom: 1px solid $color__bright;
            text-transform: none;
            font-weight: 300;
            &:active{
                border-bottom: 1px solid $color__primary;
                color: $color__primary;
            }
            &:hover{
                border-bottom: 1px solid $color__dark;
                color: $color__dark;
            }
        }
        p{
            padding-bottom: 0;
            margin-bottom: 0;
            display: block;
            text-align: left;
            span{
                border-bottom: 1px solid $color__bright;
                &:active{
                    border-bottom: 1px solid $color__primary;
                    color: $color__primary;
                }
                &:hover{
                    border-bottom: 1px solid $color__dark;
                    color: $color__dark;
                }
            }
            .icon{
                vertical-align: middle;
            }
        }
        .sub_team {
            & a{
                color: $color__bright;
            }
            &::after{
                clear: both;
                content: '';;
            }
        }
    }
}
@include mq(medium) {
    .contacts-box {
        padding: 5rem;
    }
    .inside_info {
        float: left;
        width: 33.3%;
        p{
            font-size: 0.8rem
        }
        a{
            font-size: 0.8rem
        }
        &__image {
            // width: rem(140);
            height: auto;
            margin: rem(20) 0;
            width: 100%;
        }
        &__name {
            margin: 0 0;
            display: block;
            width: 100%;
        }
        &__actions{
            margin: 0 0;
            width: 100%;
            display: block;
        }
    }
}
@include mq(large) {
    .inside_info {
        &__image {
            margin: rem(20) 0;
            // width: rem(234);
            height: auto;
        }
        &__name {
            margin: 0 0;
            display: block;
        }
        &__actions{
            margin: 0 0;
            display: block;
            color: $color__bright;
            a{
                font-size: 1rem;
            }
            p{
                font-size: 1rem;
                span{
                    border-bottom: 1px solid $color__bright;
                }
                .icon{
                    vertical-align: middle;
                }
            }
        }
    }
}
