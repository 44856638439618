@mixin hide-text {
  font: 0 / 0 a;
  text-shadow: none;
  color: transparent;
}

// Mixin to reset the neat omega mixin
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) {
    margin-right: +flex-gutter();
  }

  &:nth-child(#{$nth}+1) {
    clear: none;
  }
}

// Mixin for Media Queries
@mixin mq($mq__breakpoint, $mq__breakpoints: $breakpoints, $mq__direction: 'min-width') {
  $mq__media-query: 'screen and';
  @if $mq__breakpoint == landscape or $mq__breakpoint == portrait {
    $mq__media-query: "#{$mq-media-query} (orientation: #{$mq__breakpoint})";
  } @else {
    // If $mq__breakpoint is a key that exists in
    // $mq__breakpoints, get and use the value
    @if map-has-key($mq__breakpoints, $mq__breakpoint) {
      $mq__breakpoint: map-get($mq__breakpoints, $mq__breakpoint);
    }
  }

  @media screen and (#{$mq__direction}: #{$mq__breakpoint}) {
    @content;
  }
}

// Mixin for breaking long words
@mixin word-break() {
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
