$tab__space: rem(5);

.tab {
  @include clearfix;
}

.tab__nav {
  @include outer-container();
  display: block;
  list-style: none;
  padding: 0 $tab__space $tab__space;
  margin-top: 0;
  margin-bottom: 0;
}

.tab__nav-item {
  display: block;
  float: left;
  margin: $tab__space $tab__space 0 0;

  &:last-child {
    margin-right: 0;
  }

  a {
    display: block;
    background-color: $color__accent;
    font-weight: $font__weight--bold;
    padding: ($tab__space * 2);
  }
}

.tab__panel {
  display: none;
  background-color: $color__accent--light;
  padding: rem(20) 0;
}

// STATES
.tab__nav-item--active {
  a {
    color: $color__secondary;
    background-color: $color__accent--light;
  }
}

.tab__panel--active {
  display: block;
}

// MEDIUM
// ----------------------------------------
@include mq(medium) {
  .tab__nav {
    padding: 0 $tab__space * 2;
  }

  .tab__nav-item {
    a {
      border-bottom: $tab__space solid $color__bright;
    }
  }

  .tab__nav-item--active {
    a {
      border-bottom-color: $color__accent--light;
    }
  }

  .tab__panel {
    padding: rem(40) 0;
  }
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .tab__nav-item {
    margin: 0 $tab__space 0 0;
    font-size: rem(17);

    a {
      padding: ($tab__space * 3.2) ($tab__space * 4) ($tab__space * 3);
    }
  }
}
