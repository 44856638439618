$key-data__text-color:  $color__accent--medium;

.key-data {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    min-width: rem(70);
    margin-right: rem(5);
  }

}

.key-data__field,
.key-data__value {
  height: rem(35);
  font-size: rem(25);
  font-weight: $font__weight--light;
  line-height: normal;
  color: $key-data__text-color;
  padding: 0 rem(5);
}

.key-data__field {
  width: rem(100);
  margin: 0;
}

.key-data__value {
  display: block;
  border-bottom: 2px solid $key-data__text-color;
}

.key-data__label {
  display: block;
  font-size: rem(15);
  line-height: 2.33;
  color: $key-data__text-color;
}

@include mq(medium) {
  .key-data__field,
  .key-data__value {
    height: rem(45);
    font-size: rem(35);
  }
}
