// LIGHT
@font-face{
  font-family: 'ATC Overlook';
  src: url('../fonts/ATCOverlook-Light.eot?#iefix') format('eot');
}
@font-face{
  font-family: 'ATC Overlook';
  src: url('../fonts/ATCOverlook-Light.eot?#iefix');
  src: url('../fonts/ATCOverlook-Light.eot?#iefix') format('eot'), url('../fonts/ATCOverlook-Light.woff2') format('woff2'), url('../fonts/ATCOverlook-Light.woff') format('woff'), url('../fonts/ATCOverlook-Light.ttf') format('truetype'), url('../fonts/ATCOverlook-Light.svg#8922024f2cab9ef34d7176b180438efb') format('svg');
  font-weight: 300;
  font-style: normal;
}

// MEDIUM
@font-face{
  font-family: 'ATC Overlook';
  src: url('../fonts/ATCOverlook-Medium.eot?#iefix') format('eot');
}
@font-face{
  font-family: 'ATC Overlook';
  src: url('../fonts/ATCOverlook-Medium.eot?#iefix');
  src: url('../fonts/ATCOverlook-Medium.eot?#iefix') format('eot'), url('../fonts/ATCOverlook-Medium.woff2') format('woff2'), url('../fonts/ATCOverlook-Medium.woff') format('woff'), url('../fonts/ATCOverlook-Medium.ttf') format('truetype'), url('../fonts/ATCOverlook-Medium.svg#104a44b6fd222484a91ea2c0e3731c27') format('svg');
  font-weight: 400;
  font-style: normal;
}

// BOLD
@font-face{
  font-family: 'ATC Overlook';
  src: url('../fonts/ATCOverlook-Bold.eot?#iefix') format('eot');
}
@font-face{
  font-family: 'ATC Overlook';
  src: url('../fonts/ATCOverlook-Bold.eot?#iefix');
  src: url('../fonts/ATCOverlook-Bold.eot?#iefix') format('eot'), url('../fonts/ATCOverlook-Bold.woff2') format('woff2'), url('../fonts/ATCOverlook-Bold.woff') format('woff'), url('../fonts/ATCOverlook-Bold.ttf') format('truetype'), url('../fonts/ATCOverlook-Bold.svg#423868989f09b0dfeaca00b1df9b89fc') format('svg');
  font-weight: 700;
  font-style: normal;
}

// HEAVY
@font-face{
  font-family: 'ATC Overlook';
  src: url('../fonts/ATCOverlook-Heavy.eot?#iefix') format('eot');
}
@font-face{
  font-family: 'ATC Overlook';
  src: url('../fonts/ATCOverlook-Heavy.eot?#iefix');
  src: url('../fonts/ATCOverlook-Heavy.eot?#iefix') format('eot'), url('../fonts/ATCOverlook-Heavy.woff2') format('woff2'), url('../fonts/ATCOverlook-Heavy.woff') format('woff'), url('../fonts/ATCOverlook-Heavy.ttf') format('truetype'), url('../fonts/ATCOverlook-Heavy.svg#e66f84803eb699012366a69da3d2a854') format('svg');
  font-weight: 800;
  font-style: normal;
}
