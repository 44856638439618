.vector {
    padding-bottom: rem(100);
    background-color: $color__bright;
    @include outer-container;
    &:after {
        content: '';
        clear: both;
        display: inline-block;
        width: 100%;
    }
    &__link {
        display: block;
        color: inherit;
    }
    &__image {
        background-color: $color__accent;
    }
    &__content {
        padding: rem(15);
    }
    .border--blue {
        border-bottom: 1px solid $color__primary;
    }
    p {
        @include word-break;
    }
    h2 {
        margin-top: 0;
    }
}

@include mq(medium) {
    .vector {
        &__image {
            width: 50%;
            float: left;
        }
        &__content {
            width: 50%;
            float: right;
        }
        &:nth-child(odd) {
            .vector__image {
                float: right;
            }
            .vector__content {
                float: left;
            }
        }
    }
}
