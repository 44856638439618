.footer .newsletter_container{
  text-align: center;
}
.newsletter_container{
    @include outer-container;
    background: $color__primary;

    h1{
        color: #fff;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 2rem;
        font-size: 2.5rem;
        font-weight: 600;
    }
    h2{
        font-size: 1.88rem;
        color: $color__bright;
        text-align: center;
        max-width: 840px;
        margin: 0 auto;
        line-height: 1.5;
    }
    h3{
        color: #fff;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 2rem;
    }
    input{
        text-align: center;
        display: block;
        width: 15rem;
        margin: 0 auto;
        color: $color__bright;
        &:focus{
            background-color: transparent;
        }
    }
    .newsletter_button{
        min-width: 15rem;
        display: inline-block;
        margin: rem(15) auto;
        padding: 0.5rem 0.75rem 0.3125rem;
        & .btn{
            background-color: $color__secondary;
            border: 0.0625rem solid $color__secondary;
            color: $color__bright;

        }
    }
    .btn{
        margin: 0 auto;
        display: block;
    }
    &.newsletter_containeren h2{
        max-width: 100%;
    }
}
@include mq(medium) {

    .newsletter_container{
        background: $color__primary;
        h1{

            font-size: 5rem;
        }
        h2{
            font-size: 2.75rem;
            & span.small{
              font-size: 1.8rem;
            }
        }
        input{
            width: 20rem;
        }
    }
}

