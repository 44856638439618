.product-header {
  @include outer-container;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: rem(20) grid-padding(small) rem(40);
}

.product-header__image {
  order: 1;
  width: 50%;
  max-width: rem(180);
  float: left;
  padding-right: rem(10);

  img {
    background-color: $color__accent;
  }
}

.product-header__actions {
  order: 2;
  position: relative;
  text-align: right;
  width: 50%;
  margin-top: 0;

  .btn {
    margin-right: rem(2);
    margin-bottom: rem(2);
  }
}

.product-header__content {
  order: 3;
  width: 100%;
}

.product-header__contact {
  order: 4;
  width: 100%;
}

.product-header__title {
  margin-top: 0;
}

@include mq(medium) {
  .product-header {
    @include row;
  }

  .product-header__actions {
    @include row;
    width: 100%;
  }

  .product-header__image {
    @include span-columns(2);
  }

  .product-header__content {
    @include span-columns(6);
  }

  .product-header__contact {
    @include span-columns(4);
  }

  .product-header__actions {
    @include row;
    width: 100%;
    margin-bottom: rem(30);
  }
}

@include mq(large) {
  .product-header__content {
    @include span-columns(6);
  }

  .product-header__contact {
    @include span-columns(3);
    @include shift(1);
  }
}
