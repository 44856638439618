.detailpage {
  background-color: $color__dark;
  // padding-left: 5%;
}

.detailpage__container {
  @include row;
  position: relative;
  background-color: $color__bright;
}

.detailpage__inner {
  @include row;
}

.detailpage__nav {
  position: absolute;
  top: rem(20);
  left: rem(-20); // half of btn--circle
  z-index: 1000;
}

@include mq(medium) {
  .detailpage {
    //padding-left: 5%;
  }

  .detailpage__nav {
    top: rem(100);
    left: rem(-38); // half of btn--circle
  }
}

@include mq(xlarge) {
  .detailpage__nav {
    top: rem(300);
  }
}

@include mq(detail) {
  // The detailpage has a padding of 10%, so the detailpage__container is 90% ( the 9 in the formula) of vw. We move the detailpage__inner negative to left, so the centered content is in "center" of vw (with content outside detailpage)
  .detailpage__inner {
    margin-left: -(((100% / (9 / 10)) * (1 / 10)));

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: (((100% / (9 / 10)) * (1 / 10)));
      z-index: 100;
      background-color: $color__dark;
    }
  }
}
