.brand-overview {
  @include clearfix;
  background-color: $color__bright;
}

.brand-overview__inner {
  @include outer-container();
  overflow: hidden;
  padding: 0 rem(4);
  & h2{
    padding: 0 0.6325rem;
  }
  & h3{
      font-size: 2.125rem;
  }
}
.brand-overview__header {
  background-color: $color__accent--light;
}

.brand-overview__nav {
  border-bottom: 0;
}

.brand-overview__brands {
  background-color: $color__bright;
}

// BRAND TILES
.brand-overview__item-wrapper {
  position: relative;
  padding: rem(4) 0;
  list-style-type: none;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ease;
  padding-left: 0rem;

  &.masonrized {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
   }
}

.brand-overview__item-sizer,
.brand-overview__item {
  display: block;
  width: 48%;
  margin: 0 1.9356% 1rem 0%;

}

.brand-overview__item {
  float: left;
  height: 0;
  padding-bottom: 25%;
  backface-visibility: hidden;
  perspective: 400px;
}

.brand-overview__brand-link {
  position: absolute;
  display: block;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: rem(10);
  transition: all 0.3s ease-out;
  background-color: $color__accent--light  ;
  // border: rem(4) solid $color__dark;
  transform-origin: right center;

  :not(.brand-overview--size-special) &:hover {
    // transform: translate3d(0, 0, 0) rotateY(-20deg);
    background-color: lighten($color__accent--deep, 10%);
  }
  :not(.brand-overview--size-special) &:hover::after{
    content: '+';
    color: #fff;
    font-size: 48px;
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    background: rgba(0, 0, 0, 0.4);
    /* transform: translate(-50%,-50%); */
    z-index: 12315;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.list_view .brand-overview__brand-link {
  position: relative;
  :not(.brand-overview--size-special) &:hover {
    // transform: translate3d(0, 0, 0) rotateY(-20deg);
    background-color: lighten($color__accent--deep, 10%);
  }
  :not(.brand-overview--size-special) &:hover::after{
    content: '';
    background: rgba(0, 0, 0, 0.4);
    /* transform: translate(-50%,-50%); */
    z-index: 12315;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1px;
    line-height: 1;
  }
}

.brand-overview__brand-image {
  width: rem(110);
  height: auto;
  object-fit: contain;
}

// VARIANTS
.brand-overview {
  &--size-special {
    width: 100%;
    height: auto;
    padding: 0 rem(20);
    background-color: $color__dark;
    color: $color__bright;
  }
}

.brand-overview__inner {
  & h2{
    padding: 0 0;
  }
  & p{
    padding: 0 0.0rem;
  }
  & h3{
      font-size: 1.5rem;
      margin-bottom: 25px;;
      font-weight: 800;;
      margin-left: 0rem;;
  }
}

@include mq(medium) {
  .brand-overview__inner {
    padding: 0;
      & h2{
        padding-left: 0.625rem;
      }
      & h3{
        padding-left: 0.625rem;
      }
  }
    // BRAND TILES
    .brand-overview__item-sizer,
    .brand-overview__item {
      width: 23%;
      // margin: 0 .9812% 1rem 0.981%;
    }

  .brand-overview__item-wrapper {
    padding: rem(8);
  }

  // BRAND TILES


  .brand-overview__item {
    height: rem(78);
    padding-bottom: 0;
    perspective: 100px;
  }

  .brand-overview__brand-link {
    position: static;
    border-width: rem(8);
  }

  // VARIANTS
  .brand-overview {
    &--size-size2 {
      width: rem(156);
      height: rem(156);
      perspective: 400px;
    }

    &--size-size3 {
      width: rem(234);
      height: rem(234);
      perspective: 500px;
    }

    &--size-special {
      width: rem(570);
      min-height: rem(240);
      height: auto;
    }
  }
}

@include mq(large) {
  .brand-overview__inner {
    padding: 0;
      & h2{
        padding-left: 0.0rem;
      }
      & h3{
        padding-left: 0.0rem;
      }
  }
  & h2{
    padding: 0 0;
  }
  & p{
    padding: 0 0;
  }
  & h3{
      font-size: 1.625rem;
      margin-bottom: 25px;;
      font-weight: 800;;
      margin-left: 0;;
  }

  .brand-overview__item-wrapper {
    padding: rem(5);
  }

  // BRAND TILES
  .brand-overview__item-sizer,
  .brand-overview__item {
    width: 18%;
    // margin: 0 .9812% 1rem 0.981%;

  }



  .brand-overview__item {
    height: rem(79);
  }

  .brand-overview__brand-link {
    border-width: rem(6);
  }

  // VARIANTS
  .brand-overview {
    &--size-size2 {
      width: rem(158);
      height: rem(158);
    }

    &--size-size3 {
      width: rem(237);
      height: rem(237);
    }

    &--size-special {
      width: rem(474);
      min-height: rem(237);
      height: auto;
    }
  }
}
@include mq(inter){
  .brand-overview__inner {

    & p{
      padding: 0 0rem;
    }
    & h3{
      padding: 0;
    }
  }
}
@include mq(xlarge) {

  .brand-overview__item-wrapper {
    padding: rem(0);
  }

  // BRAND TILES
  .brand-overview__item-sizer,
  .brand-overview__item {
    // width: rem(145);
    // margin-right: auto;
    // margin-left: auto;
    margin-bottom: 30px;;
  }
  .brand-overview__item.brands_hp{
    width: 14.7%;
  }

  .brand-overview__item {
    height: rem(145);
  }

  .brand-overview__brand-link {
    border-width: rem(12);
  }

  // VARIANTS
  .brand-overview {
    &--size-size2 {
      width: rem(196);
      height: rem(196);
    }

    &--size-size3 {
      width: rem(294);
      height: rem(294);
    }

    &--size-special {
      width: rem(588);
      min-height: rem(294);
      height: auto;
    }
  }
}
