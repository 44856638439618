html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
}

.bg--accent {
  background-color: $color__accent;
}

.bg--accent-light {
  background-color: $color__accent--light;
}
