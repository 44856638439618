.spot-teaser-container {
  @include outer-container;
}

.spot-teaser {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: rem(20) grid-padding(small);
  border-bottom: 2px solid $color__accent;

  &:first-child {
    border-top: 2px solid $color__accent;
  }

}

.spot-teaser__image {
  order: 1;
  width: 50%;
  max-width: rem(180);
  float: left;
  padding-right: rem(10);

  img {
    background-color: $color__accent;
  }
}

.spot-teaser__actions {
  order: 2;
  width: 50%;
  margin-top: 0;

}

.spot-teaser__content {
  order: 3;
  width: 100%;
}

.spot-teaser__data {
  width: 100%;
  clear: left;
}

.spot-teaser__title {
  color: $color__primary;
  margin-top: 0;
}
.spot-teaser .btn--tertiary:hover{
  color: $color__bright;
}
.spot-teaser .btn--tertiary:active{
  color: $color__secondary;
}
@include mq(medium) {
  .spot-teaser {
    @include row;
  }

  .spot-teaser__image {
    @include span-columns(2);
  }

  .spot-teaser__content {
    @include span-columns(8);
  }

  .spot-teaser__actions {
    @include span-columns(2);
    @include omega();
    float: right;
    width: auto;
  }

}

@include mq(large) {
  .spot-teaser {
    @include span-columns(10);
    @include shift(1);
    padding: rem(20) 0;
  }
}
