.filter-select__select {
  padding-left: 0;
  font-size: rem(22);
  font-weight: $font__weight--bold;
  height: rem(54);
}

.filter-select__label {
  font-size: rem(14);
  font-weight: $font__weight--light;
  color: $color__accent--dark;
  margin-top: rem(5);
  text-transform: uppercase;

  .filter-select__select:focus + &,
  .filter-select:hover & {
    color: $body__font-color;
  }
}
