.filter-wrapper {
  @include row();
}

.filter-wrapper__inner {
  @include outer-container();
  padding: rem(10) grid-padding(small);
  margin-bottom: rem(10);
  // border-bottom: 1px solid $color__accent--medium;
}

.filter-wrapper__row {
  @include clearfix;
}

.filter-wrapper__col {
  @include span-columns(6);
  @include omega(2n);
}

.filter-wrapper__toggle-btn {
  display: block;
  margin: 0 auto;
}

.filter-wrapper__toggle-area {
  display: none;
}

.filter-wrapper__toggle-area--open {
  display: block;
}

@include mq(medium) {
  .filter-wrapper__inner {
    padding-left: grid-padding(medium);
    padding-right: grid-padding(medium);
  }

  .filter-wrapper__col {
    @include omega-reset(2n);
    @include span-columns(4);
    @include omega(3n);
  }

  .filter-wrapper__toggle-btn {
    display: none;
  }

  .filter-wrapper__toggle-area {
    display: block !important;
  }
}

@include mq(large) {
  .filter-wrapper__inner {
    // padding-left: grid-padding(large);
    // padding-right: grid-padding(large);
    padding-left: 0;
    padding-right: 0;
  }

  .filter-wrapper__col {
    @include omega-reset(3n);
    @include span-columns(2.4);
    @include omega(5n);
  }
}
