$meta-nav__line-height: rem(30);

.meta-nav {
  background-color: $color__secondary;
  margin-top: rem(50);
}

.meta-nav__search-mobile {
  margin-bottom: rem(20);
}

.meta-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: rem(14);
  font-weight: $font__weight--light;
  line-height: $meta-nav__line-height;
  text-align: left;
}

.meta-nav__item {
  display: inline-block;
}

.meta-nav__search-desktop {
  display: none;
}

.meta-nav__link {
  display: block;
  padding: rem(12);
  color: $color__bright;
  transition: all 0.15s ease;
  white-space: nowrap;

  span {
    display: none;
	}
}

// STATES
.meta-nav__link:hover {
  color: $color__dark;
}

.meta-nav__link:active,
.meta-nav__item--active .meta-nav__link {
  color: $body__font-color;
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .meta-nav {
    background-color: $color__dark;
    margin-top: 0;
  }

  .meta-nav__search-mobile {
    display: none;
  }

  .meta-nav__list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
  }

  .meta-nav__item {
    display: inline-block;
  }

  .meta-nav__link {
    padding: rem(18) rem(10) rem(17);

    span {
      display: inline;
    }

    .icon {
      width: $meta-nav__line-height;
      height: $meta-nav__line-height;
      vertical-align: top;
      margin-top: 6px;
    }

  }

  .meta-nav__link:hover {
    color: $anchor--color-hover;
  }

  .meta-nav__link:active,
  .meta-nav__item--active .meta-nav__link {
    background-color: $color__dark;
    color: $anchor--color-active;
  }
}
